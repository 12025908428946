import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { MPCampaignStatus } from "app/model/marketing-portal/MPCampaignStatus";
import { environment } from "environments/environment";

@Component({
  selector: "marketing-portal-smcampaign-overview",
  templateUrl: "./marketing-portal-smcampaign-overview.component.html",
  styleUrls: ["./marketing-portal-smcampaign-overview.component.scss"],
})
export class MarketingPortalSMCampaignOverviewComponent implements OnInit {
  @Input() socialMediaCampaign;
  @Input() isFree;
  public isPost: boolean;
  public readonly environment = environment;
  public readonly CHANNEL_ID_FACEBOOK_INSTAGRAM_POST = 8;
  public readonly MP_CAMPAIGN_STATUS_ID_DRAFT =
    MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_DRAFT;
  public previewMediaFiles: any;

  constructor(private router: Router, private domSanitizer: DomSanitizer) {}

  ngOnInit() {
    this.buildMediaFilesArr();
    this.checkIfIsPost();
  }

  checkIfIsPost() {
    this.isPost =
      this.socialMediaCampaign.channelId ===
      this.CHANNEL_ID_FACEBOOK_INSTAGRAM_POST;
  }

  buildMediaFilesArr() {
    this.previewMediaFiles = [];

    if (this.socialMediaCampaign.mediaAssets.length === 0) {
      return;
    }

    let mediaAsset = this.socialMediaCampaign.mediaAssets[0];
    this.previewMediaFiles.push({
      safeData: this.domSanitizer.bypassSecurityTrustUrl(
        environment.imageServerUrl +
          "api/Public/GetMediaAsset?id=" +
          mediaAsset.mediaAssetId
      ),
      isVideo: mediaAsset.isVideo,
    });
  }

  goToPayment() {
    this.router.navigateByUrl(
      "/mapAds/marketing-portal/checkout/campaign/" +
        this.socialMediaCampaign.channelId +
        "/" +
        this.socialMediaCampaign.socialMediaCampaignId +
        "?checkoutNeeded=true"
    );
  }
}
