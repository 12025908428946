import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPMediaAsset } from "app/model/marketing-portal/MPMediaAsset";

@Component({
  selector: "campaign-preview",
  templateUrl: "./campaign-preview.component.html",
  styleUrls: ["./campaign-preview.component.scss"],
})
export class CampaignPreviewComponent implements OnInit {
  @Input() channelId: number;
  @Input() campaign: any;
  public MP_CHANNEL = MPChannel;
  public previewMediaFiles: any = [];
  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit() {
    this.genPreviewImages();
  }

  genPreviewImages() {
    this.previewMediaFiles = [];

    this.campaign.mediaAssets.forEach((mediaAsset: MPMediaAsset) => {
      this.previewMediaFiles.push({
        safeData: mediaAsset.mediaAssetData
          ? this.domSanitizer.bypassSecurityTrustUrl(
              mediaAsset.mediaAssetData.toString()
            )
          : this.domSanitizer.bypassSecurityTrustUrl(
              mediaAsset.mediaAssetThumbnailUrl
            ),
        isVideo: mediaAsset.isVideo,
        width: null,
      });
    });
  }
}
