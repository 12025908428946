import { Component, EventEmitter, OnInit, Output, Input } from "@angular/core";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { MPMediaAsset } from "app/model/marketing-portal/MPMediaAsset";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "marketing-portal-editor",
  templateUrl: "./marketing-portal-editor.component.html",
  styleUrls: ["./marketing-portal-editor.component.scss"],
})
export class MarketingPortalEditorComponent implements OnInit {
  @Output() onApply: EventEmitter<any> = new EventEmitter();
  @Output() onCloseWindow: EventEmitter<any> = new EventEmitter();
  @Input() customerId: string;
  @Input() customerLogo: string;
  @Input() channelId: number;
  @Input() mediaAsset: MPMediaAsset;
  @Input() formatMediaAssets: any;
  public templates: any;
  public isSet: boolean;
  public selectedTemplate: any;
  public isOpenMenu: boolean = false;
  public page: number = 0;
  public itemsPerPage: number = 100;
  public isInitLoading: boolean = true;
  constructor(
    private appLoader: AppLoaderService,
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  async ngOnInit() {
    this.setIsSet();

    setTimeout(() => this.appLoader.open());

    const request = this.isSet
      ? this.crudService.getAllMPSetTemplates(
          this.channelId,
          this.page,
          this.itemsPerPage,
          this.customerId
        )
      : this.crudService.getAllMPTemplates(
          this.channelId,
          this.page,
          this.itemsPerPage,
          this.customerId
        );

    await request
      .toPromise()
      .then((res: any) => {
        this.templates = this.getTemplates(res);
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
        setTimeout(() => this.appLoader.close());
        this.isInitLoading = false;
        return;
      });

    if (this.mediaAsset) {
      if (!this.isDefaultTemplate(this.mediaAsset.mediaAssetId)) {
        await this.restoreHistory()
          .then((res) => {
            this.mediaAsset = null;
            this.selectedTemplate = res;
            setTimeout(() => this.appLoader.close());
            this.isInitLoading = false;
          })
          .catch((error) => {
            this.snackbarService.show(error, "danger");
            setTimeout(() => this.appLoader.close());
            this.isInitLoading = false;
          });
      } else {
        this.selectedTemplate = this.getDefaultTemplate(
          this.mediaAsset.mediaAssetId
        );
        this.mediaAsset = null;
        setTimeout(() => this.appLoader.close());
        this.isInitLoading = false;
      }
    } else {
      setTimeout(() => this.appLoader.close());
      this.isInitLoading = false;
    }
  }

  isDefaultTemplate(mediaAssetId: number): boolean {
    if (this.isSet) {
      return this.isDefaultSetTemplate(mediaAssetId);
    }

    return this.isDefaultSingleTemplate(mediaAssetId);
  }

  isDefaultSingleTemplate(imageId: number): boolean {
    const index = this.templates.findIndex(
      (template) => template.templateImageId === imageId
    );
    return index != -1;
  }

  isDefaultSetTemplate(imageId: number): boolean {
    let found: boolean = false;

    this.templates.forEach((template) => {
      template.singleTemplates.forEach((singleTemplate) => {
        if (singleTemplate.imageId === imageId) {
          found = true;
        }
      });
    });

    return found;
  }

  getDefaultTemplate(imageId: number) {
    if (this.isSet) {
      return this.getDefaultSetTemplate(imageId);
    }

    return this.getDefaultSingleTemplate(imageId);
  }

  getDefaultSingleTemplate(imageId: number) {
    const index = this.templates.findIndex(
      (template) => template.templateImageId === imageId
    );
    return this.templates[index];
  }

  getDefaultSetTemplate(imageId: number) {
    let setTemplate = null;

    this.templates.forEach((template) => {
      template.singleTemplates.forEach((singleTemplate) => {
        if (singleTemplate.imageId === imageId) {
          setTemplate = template;
        }
      });
    });

    return setTemplate;
  }

  restoreHistory() {
    return new Promise(async (resolve, reject) => {
      const promise = this.mediaAsset.isPartOfSet
        ? this.crudService
            .getHistoricTemplateSetConfig(this.mediaAsset.mediaAssetId)
            .toPromise()
        : this.crudService
            .getHistoricTemplateConfig(this.mediaAsset.mediaAssetId)
            .toPromise();

      await promise
        .then((res: any) => {
          const { template, globalColorCode, modifications } = res;

          let selectedTemplate = {
            templateExternalId: template,
            templateImageId: this.mediaAsset.mediaAssetId,
            hasGlobalColor: false,
            templateConfigModel: {
              globalColorCode: globalColorCode,
              modifications: modifications,
            },
          };

          if (this.mediaAsset.isPartOfSet) {
            selectedTemplate["templateSetId"] = this.mediaAsset.templateId;
          } else {
            selectedTemplate["templateId"] = this.mediaAsset.templateId;
          }

          resolve(selectedTemplate);
        })
        .catch((e) => {
          reject(e.error);
        });
    });
  }

  getTemplates(templates) {
    const templatesKey = this.isSet ? "templateSets" : "templates";

    return templates[templatesKey];
  }

  setIsSet() {
    this.isSet = this.channelId === MPChannel.CHANNEL_ID_GOOGLE_GDN;
  }

  toggleMenu() {
    this.isOpenMenu = !this.isOpenMenu;
  }

  onSelectTemplate(res) {
    this.selectedTemplate = res.template;
  }

  onDeselectTemplate() {
    this.selectedTemplate = null;
  }

  onClose() {
    this.onCloseWindow.emit();
  }
}
