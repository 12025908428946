// SUPPORT
// export const SUPPORT_PHONE_NUMBER = "+49 271 303 818 200";
// export const SUPPORT_EMAIL_ADDRESS = "support@mapads.net";

// SERVICE
export const SERVICE_ID_MARKEING_PORTAL: number = 3;
export const SERVICE_ID_SPARKASSE: number = 2;
export const SERVICE_ID_MAPADS: number = 1;

// ACCOUNT
export const ACCOUNT_STATUS_INACTIVE: number = 0;
export const ACCOUNT_STATUS_INCORRECT: number = 1;
export const ACCOUNT_STATUS_PARTIALLY_ACTIVE: number = 2;
export const ACCOUNT_STATUS_ACTIVE: number = 3;
export const ACCOUNT_STATUS_ACTIVE_BY_DEFAULT: number = 10;

export const USER_TYPE_ID_MERCHANT = 1;
export const USER_TYPE_ID_SERVICE_PROVIDER = 2;
export const USER_TYPE_ID_GASTRO = 3;

// OTHERS
export const AGE_GROUP_ID: number = 79;
export const COLOR_ID: number = 65;
export const GENDER_ID: number = 74;
export const SUB_CAT_ID: number = 1;
export const UNIT_ID: number = 5;
export const CATE_ID: number = 1;
export const CLIENT_ID: string = "mapAdsBrowser";

// DATE FORMAT
export const DATE_FORMAT_ENGLISH = "YYYY-MM-DD";
export const DATE_FORMAT_GERMAN = "DD.MM.YYYY";
export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: { month: "short", year: "numeric", day: "numeric" },
  },
  display: {
    dateInput: "input",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

// UPLOAD
export const IMAGE_UPLOAD_ACCEPT_TYPES: string = "image/png, image/jpeg";

export const MAX_UPLOAD_IMAGE_SIZE: number = 5; // MB
export const MAX_UPLOAD_VIDEO_SIZE: number = 100; // MB

export const YT_UPLOAD_FORMAT: string = "16:9";
export const YT_UPLOAD_FORMAT_MIN_DURATION: number = 5; // Seconds
export const YT_UPLOAD_FORMAT_MAX_DURATION: number = 120; // Seconds
export const YT_UPLOAD_FORMAT_DURATION_SHOW_WARNING: number = 30; // Seconds
export const YT_UPLOAD_FORMAT_MIN_WIDTH: number = 426;
export const YT_UPLOAD_FORMAT_MIN_HEIGHT: number = 240;
export const YT_UPLOAD_FORMAT_MAX_WIDTH: number = 3840;
export const YT_UPLOAD_FORMAT_MAX_HEIGHT: number = 2160;
export const YT_UPLOAD_ACCEPT_TYPES: string = ".mp4,.mov";

export const FB_VIDEO_UPLOAD_FORMAT: string = "16:9,9:16,1:1";
export const FB_VIDEO_UPLOAD_FORMAT_MIN_DURATION: number = 1; // Seconds
export const FB_VIDEO_UPLOAD_FORMAT_DURATION_SHOW_WARNING: number = 30; // Seconds
export const FB_VIDEO_UPLOAD_FORMAT_MIN_WIDTH: number = 600;
export const FB_VIDEO_UPLOAD_FORMAT_MIN_HEIGHT: number = 600;
export const FB_VIDEO_UPLOAD_ACCEPT_TYPES: string = ".mp4,.mov";

// GOOGLE
export const MIN_PRODUCTS_WITH_GTIN: number = 15; // Min amount of products with gtin to request inventory check
export const MIN_PRODUCTS: number = 15; // Min amount of products to activate google account
