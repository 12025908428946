import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  NgForm,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { AppDateAdapter } from "app/shared/adapters/app-date-adapter";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { configuration } from "configurations/configuration";
import { CUSTOM_DATE_FORMATS } from "../../../../../../constants";

@Component({
  selector: "create-tv-form",
  templateUrl: "./create-tv-form.component.html",
  styleUrls: ["./create-tv-form.component.scss"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "de-De" },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class CreateTvFormComponent {
  @ViewChild("form") form: NgForm;
  @Input() data?: any;
  @Input() duplicate?: number;
  @Input() readonly?: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() campaignChannelId?: number = null;
  @Input() customer?: any;
  @Input() channel: any;
  readonly configuration = configuration;

  public customerId: number = null;
  public isLoading: boolean = false;
  public campaignForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private translate: TranslateService,
    private adapter: DateAdapter<any>,
    private crudService: CrudService,
    private appLoader: AppLoaderService,
    private snackbarService: SnackbarService,
    private domSanitizer: DomSanitizer,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.adapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.adapter.setLocale(event.lang);
    });

    this.campaignChannelId = +this.route.snapshot.paramMap.get("id") || null;
    this.customerId = +this.route.snapshot.queryParams.customer || null;

    if (!this.campaignChannelId) {
      this.snackbarService.show("Die Verknüpfung ist ungültig", "danger");
      this.router.navigateByUrl("/mapAds/marketing-portal/campaigns");
    }

    if (this.customerId) {
      this.isLoading = true;
      setTimeout(() => this.appLoader.open());

      await this.crudService
        .getSingleCustomer(this.customerId)
        .toPromise()
        .then((res) => {
          this.customer = res;
        })
        .catch((e) => {
          this.snackbarService.show(e.error, "danger");
          this.router.navigateByUrl("/mapAds/marketing-portal/campaigns");
        });

      setTimeout(() => this.appLoader.close());
      this.isLoading = false;
    }
  }

  async onSubmit() {}
}
