import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { ImportMarketingPortalEditorFileDialogComponent } from "app/shared/components/dialogs/import-marketing-portal-editor-file-dialog/import-marketing-portal-editor-file-dialog.component";
import { MediaAssetSearchDialogComponent } from "app/shared/components/dialogs/media-asset-search-dialog/media-asset-search-dialog.component";
import { UnsplashImageSearchDialogComponent } from "app/shared/components/dialogs/unsplash-image-search-dialog/unsplash-image-search-dialog.component";
import { UploadImageDialogComponent } from "app/shared/components/dialogs/upload-image-dialog/upload-image-dialog.component";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { LayoutService } from "app/shared/services/layout.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { forkJoin, Subscription } from "rxjs";
import { MAX_UPLOAD_IMAGE_SIZE } from "../../../../../../constants";

@Component({
  selector: "marketing-portal-editor-configurator-toolset",
  templateUrl: "./marketing-portal-editor-configurator-toolset.component.html",
  styleUrls: ["./marketing-portal-editor-configurator-toolset.component.scss"],
})
export class MarketingPortalEditorConfiguratorToolsetComponent
  implements OnInit
{
  @ViewChild("form") form;
  @ViewChild("fileInput") fileInput: ElementRef;
  @Output() generateImage: EventEmitter<any> = new EventEmitter();
  @Output() setUpdatedTemplateConfig: EventEmitter<any> = new EventEmitter();
  @Output() setInputChanged: EventEmitter<any> = new EventEmitter();
  @Output() onApply: EventEmitter<any> = new EventEmitter();
  @Input() templateConfigModel;
  @Input() customerId;
  @Input() customerLogo;
  @Input() channelId?: number;
  @Input() inputChanged: boolean;
  @Input() showActionButtons?: boolean = true;
  public showGlobalColor?: boolean;
  public itemForm: FormGroup;
  public errors: object = {};
  public uploadedImageIds: object = {};
  public onFocusImageElement: string = "";
  public onFocusImageWidth: number = 0;
  public onFocusImageHeight: number = 0;
  public globalColor: any;
  readonly MAX_UPLOAD_IMAGE_SIZE = MAX_UPLOAD_IMAGE_SIZE;
  public formSubscription: Subscription = null;
  constructor(
    private appLoader: AppLoaderService,
    private crudService: CrudService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    public layout: LayoutService
  ) {}

  ngOnInit() {
    this.buildFormGroup();
    this.globalColor = this.templateConfigModel.globalColorCode;
    this.setShowGlobalColor();
    this.setUploadedImages();

    this.updateTemplateCongfig();
  }

  ngAfterViewInit() {
    this.formSubscription = this.itemForm.valueChanges.subscribe((result) => {
      this.updateTemplateCongfig();

      this.setInputChanged.emit(true);
    });
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
  }

  setUploadedImages() {
    this.templateConfigModel.modifications.forEach((modification) => {
      if (this.hasChildren(modification)) {
        modification.children.forEach((child) => {
          child.elements.forEach((element) => {
            this.addImageToElement(element, child);
          });
        });
      } else {
        modification.elements.forEach((element) => {
          this.addImageToElement(element, modification);
        });
      }
    });
  }

  hasChildren(modification) {
    return modification.hasOwnProperty("children") && modification.children;
  }

  addImageToElement(element, modification) {
    if (element.type === "image_id" && element.value) {
      const name = modification.name + "_" + element.type;
      this.addImageId(name, element.value);
    }
  }

  setShowGlobalColor() {
    this.showGlobalColor =
      this.templateConfigModel.hasGlobalColor && this.hasColorInput();
  }

  hasColorInput() {
    let hasColorInput = false;
    this.templateConfigModel.modifications.forEach((modification) => {
      if (modification.hasOwnProperty("elements")) {
        modification.elements.forEach((element) => {
          if (element.type === "color") {
            hasColorInput = true;
          }
        });
      }
    });

    return hasColorInput;
  }

  buildFormGroup() {
    this.itemForm = new FormGroup({});

    this.templateConfigModel.modifications.forEach((modification) => {
      if (this.hasChildren(modification)) {
        modification.children.forEach((child) => {
          child.elements.forEach((element) => {
            this.addElementItemToForm(child, element);
          });
        });
      } else {
        modification.elements.forEach((element) => {
          this.addElementItemToForm(modification, element);
        });
      }
    });
  }

  addElementItemToForm(modification, element) {
    let isRequired = element.type != "image_id";
    let label = modification.name + "_" + element.type;

    if (isRequired) {
      this.itemForm.addControl(
        label,
        new FormControl(element.value, Validators.required)
      );
    } else {
      this.itemForm.addControl(label, new FormControl(element.value));
    }
  }

  setAllColors(value) {
    this.templateConfigModel.modifications.forEach((modification) => {
      if (modification.hasOwnProperty("elements")) {
        modification.elements.forEach((element) => {
          if (element.type === "color") {
            let label = modification.name + "_" + element.type;
            this.itemForm.get(label).setValue(value);
          }
        });
      }
    });

    this.updateTemplateCongfig();
  }

  onChangeGlobalColor($event) {
    const value = $event.target.value;
    this.setAllColors(value);

    this.setInputChanged.emit(true);
  }

  onDeleteImage($event) {
    this.itemForm.get($event).setValue(null);
    delete this.uploadedImageIds[$event];
  }

  onImportImage($event) {
    const { name, height, width } = $event;

    this.onFocusImageElement = name;
    this.onFocusImageWidth = width;
    this.onFocusImageHeight = height;

    const dialogRef = this.dialog.open(
      ImportMarketingPortalEditorFileDialogComponent,
      {
        width: "800px",
        panelClass: "no-spacing",
        height: "auto",
        maxHeight: "100vh",
      }
    );

    dialogRef.afterClosed().subscribe(async (res) => {
      if (!res) {
        return;
      }
      if (res.type === "CUSTOM_IMAGE") {
        this.fileInput.nativeElement.click();
      } else if (res.type === "UNSPLASH") {
        const unplashImageSearchDialogRef = this.dialog.open(
          UnsplashImageSearchDialogComponent,
          {
            width: "960px",
            panelClass: "no-spacing",
            height: "95vh",
            maxHeight: "95vh",
            disableClose: true,
          }
        );

        unplashImageSearchDialogRef.afterClosed().subscribe(async (res) => {
          if (res) {
            this.handleUploadedFile(res.file);
          }
        });
      } else if (res.type === "MEDIAASSET") {
        const mediaAssetImageSearchDialogRef = this.dialog.open(
          MediaAssetSearchDialogComponent,
          {
            width: "960px",
            panelClass: "no-spacing",

            maxHeight: "95vh",
            disableClose: true,
            data: {
              customerId: this.customerId,
              customerLogo: this.customerLogo,
              width: this.onFocusImageWidth,
              height: this.onFocusImageHeight,
              channelId: null,
              googleDisplayMediaFormatId: null,
              hideAdMaterials: true,
            },
          }
        );

        mediaAssetImageSearchDialogRef.afterClosed().subscribe(async (res) => {
          if (res) {
            this.handleUploadedFile(res.file);
          }
        });
      }
    });
  }

  emitFiles($event) {
    const file = $event.target.files[0];
    $event.target.value = "";

    if (!this.hasValidFileSize(file)) {
      return;
    }

    this.handleUploadedFile(file);
  }

  handleUploadedFile(file) {
    const dialogRef = this.dialog.open(UploadImageDialogComponent, {
      width: "1000px",
      maxHeight: "95vh",
      panelClass: "no-spacing",
      disableClose: true,
      data: {
        file: file,
        useBackgroundRemover: true,
        useImageCropper: true,
        isEditor: true,
        width: this.onFocusImageWidth,
        height: this.onFocusImageHeight,
        forceAspectRatio: true,
      },
    });

    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        const croppedFile = res.res;

        this.appLoader.open();

        await this.uploadMediaFile(croppedFile).catch((e) => {
          this.snackbarService.show(e.message, "danger");
        });

        this.appLoader.close();
      }
    });
  }

  uploadMediaFile(croppedFile) {
    const imageObservables = [
      this.crudService.uploadCampaignImageAssets(croppedFile),
    ];

    return new Promise((resolve, reject) => {
      forkJoin(imageObservables).subscribe(
        (x) => {
          for (var i = 0; i < x.length; i++) {
            if (x[i]["body"] != null) {
              let imageId = x[i]["body"]["uploadInformation"][0]["Id"];

              this.itemForm.get(this.onFocusImageElement).setValue(imageId);
              this.addImageId(this.onFocusImageElement, imageId);
            }
          }
          resolve(x);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  addImageId(key, id) {
    this.uploadedImageIds[key] = id;
  }

  hasValidFileSize(file) {
    const fileSize = Number((file.size / 1024 / 1024).toFixed(4));
    const maxUploadSize = this.MAX_UPLOAD_IMAGE_SIZE;
    if (fileSize > maxUploadSize) {
      this.snackbarService.show(
        this.translate.instant("ImageUploadMaxSizeError", {
          maxUploadSize: maxUploadSize,
        }),
        "danger"
      );
      return false;
    }

    return true;
  }

  generateTemplateConfigValues(values) {
    const updatedTemplateConfig = [];

    this.templateConfigModel.modifications.forEach((modification) => {
      const updatedModification = modification;

      if (modification.hasOwnProperty("children") && modification.children) {
        modification.children.forEach((child, j) => {
          child.elements.forEach((element, i) => {
            updatedModification.children[j].elements[i].value =
              values[child.name + "_" + element.type];
          });
        });
      } else {
        if (modification.hasOwnProperty("elements")) {
          modification.elements.forEach((element, i) => {
            updatedModification.elements[i].value =
              values[modification.name + "_" + element.type];
          });
        }
      }

      updatedTemplateConfig.push(updatedModification);
    });

    return updatedTemplateConfig;
  }

  updateTemplateCongfig() {
    const values = this.itemForm.getRawValue();
    this.setUpdatedTemplateConfig.emit(
      this.generateTemplateConfigValues(values)
    );
  }

  submit() {
    this.generateImage.emit();
  }
}
