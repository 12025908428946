import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  NgForm,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { ValidationService } from "app/shared/services/validation.service";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "create-customer-location-form",
  templateUrl: "./create-customer-location-form.component.html",
  styleUrls: ["./create-customer-location-form.component.scss"],
})
export class CreateCustomerLocationFormComponent implements OnInit {
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  @Input() readonly: boolean = false;
  @Input() data: any = null;
  @Input() customerId: number;
  @ViewChild("form") form: NgForm;
  @ViewChild("pacInput") input: ElementRef<HTMLInputElement>;
  public formGroup: FormGroup;
  public errors = {};
  public radiusOptions: any;
  public coords = {
    latitude: null,
    longitude: null,
  };
  public showMap: boolean = false;
  public isLoading: boolean;
  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private crudService: CrudService,
    private router: Router,
    private appLoader: AppLoaderService
  ) {}

  async ngOnInit() {
    this.buildFormGroup(this.data);

    setTimeout(() => this.appLoader.open());

    this.radiusOptions = await this.crudService.getMPRadius().toPromise();

    setTimeout(() => this.appLoader.close());

    if (!this.formGroup.get("radius").value) {
      this.formGroup.get("radius").setValue(this.radiusOptions[1].radiusValue);
    }
  }

  ngAfterViewInit() {
    this.initSearch();
  }

  buildFormGroup(items) {
    this.formGroup = this.fb.group({
      id: [items ? items.customerLocationId : null],
      name: [items ? items.locationName : "", Validators.required],
      description: items ? items.locationDescription : "",
      address: this.fb.group({
        zip_code:
          items && items.locationAddress
            ? items.locationAddress.zip_code
            : null,
        address:
          items && items.locationAddress ? items.locationAddress.address : null,
        city:
          items && items.locationAddress ? items.locationAddress.city : null,
        country:
          items && items.locationAddress ? items.locationAddress.country : null,
      }),
      radius: items ? items.locationRadius : null,
    });

    if (this.readonly) {
      this.formGroup.disable();
    }

    if (
      items &&
      items.locationAddress &&
      items.locationAddress.latitude &&
      items.locationAddress.longitude
    ) {
      this.coords.latitude = items.locationAddress.latitude;
      this.coords.longitude = items.locationAddress.longitude;

      this.showMap = true;
    }

    this.formGroup.get("address")["controls"]["zip_code"].disable();
    this.formGroup.get("address")["controls"]["city"].disable();
    this.formGroup.get("address")["controls"]["address"].disable();
    this.formGroup.get("address")["controls"]["country"].disable();
  }

  initSearch() {
    const input = this.input.nativeElement as HTMLInputElement;
    const options = {
      componentRestrictions: { country: "de" },
      fields: [
        "adr_address",
        "address_component",
        "formatted_address",
        "geometry",
        "name",
      ],
      strictBounds: false,
      types: ["address"],
    };

    const autocomplete = new google.maps.places.Autocomplete(input, options);

    autocomplete.addListener("place_changed", () => {
      this.selectGooglePlace(autocomplete.getPlace());
      this.input.nativeElement.value = "";
    });
  }

  selectGooglePlace(place) {
    delete this.errors["address"];

    const zipcode = place.address_components.find((addressComponent) =>
      addressComponent.types.includes("postal_code")
    );

    if (zipcode) {
      this.formGroup
        .get("address")
        ["controls"]["zip_code"].setValue(zipcode.short_name);
    }

    const country = place.address_components.find((addressComponent) =>
      addressComponent.types.includes("country")
    );

    if (country) {
      this.formGroup
        .get("address")
        ["controls"]["country"].setValue(country.long_name);
    }

    const city = place.address_components.find((addressComponent) =>
      addressComponent.types.includes("locality")
    );

    if (city) {
      this.formGroup
        .get("address")
        ["controls"]["city"].setValue(city.long_name);
    }

    const address = place.address_components.find((addressComponent) =>
      addressComponent.types.includes("route")
    );

    if (address) {
      this.formGroup
        .get("address")
        ["controls"]["address"].setValue(address.long_name);
    }

    this.coords.latitude = place.geometry.location.lat();
    this.coords.longitude = place.geometry.location.lng();

    this.showMap = true;
  }

  removeSelectedPlace() {
    this.formGroup.get("address")["controls"]["zip_code"].setValue(null);
    this.formGroup.get("address")["controls"]["city"].setValue(null);
    this.formGroup.get("address")["controls"]["address"].setValue(null);
    this.formGroup.get("address")["controls"]["country"].setValue(null);

    this.showMap = false;
  }

  onRadiusChange(radius) {
    if (this.readonly) {
      return;
    }

    this.formGroup.get("radius").setValue(radius);
  }

  markFormControls(controls) {
    for (const key of Object.keys(controls)) {
      controls[key].markAsTouched();
      if (controls[key].hasOwnProperty("controls")) {
        this.markFormControls(controls[key].controls);
      }
    }
  }

  getValidationErrors(): void {
    this.errors = {};
    this.getValidationErrorsByControls(this.formGroup.controls);
  }

  getValidationErrorsByControls(controls) {
    const addressControls = this.formGroup.get("address")["controls"];

    if (
      !addressControls["zip_code"].value &&
      !addressControls["city"].value &&
      !addressControls["address"].value &&
      !addressControls["country"].value
    ) {
      this.errors["address"] = "Eine Adresse ist erforderlich";
    }

    Object.keys(controls).forEach((key) => {
      const control = controls[key];
      const controlErrors: ValidationErrors = control.errors;
      if (controlErrors != null) {
        const error = Object.keys(control.errors)[0];

        this.errors[key] = ValidationService.getValidatorErrorMessage(
          error,
          this.translate,
          control.errors.validatorValue || {}
        );
      }

      if (controls[key].hasOwnProperty("controls")) {
        this.getValidationErrorsByControls(controls[key].controls);
      }
    });
  }

  submit() {
    this.form.onSubmit(undefined);
  }

  async onSubmit() {
    this.markFormControls(this.formGroup.controls);
    this.getValidationErrors();

    if (this.formGroup.invalid || Object.keys(this.errors).length) {
      this.snackbarService.show(
        "Bitte fülle alle erforderlichen Felder aus",
        "danger"
      );

      setTimeout(() => {
        let errorMessages = document.querySelector(".error-message");
        if (errorMessages) {
          errorMessages.scrollIntoView();
        }
      });

      return;
    }

    // Process data
    const value = this.formGroup.getRawValue();

    const newLocation = {
      customerLocationId: value.id,
      locationName: value.name,
      locationDescription: value.description,
      locationAddress: {
        zip_code: value.address.zip_code,
        city: value.address.city,
        address: value.address.address,
        country: value.address.country,
      },
      locationLogoId: null,
      locationRadius: value.radius,
    };

    const request =
      this.data && this.data.customerLocationId
        ? this.crudService.updateCustomerLocation(
            newLocation,
            this.data.customerLocationId
          )
        : this.crudService.addNewCustomerLocation(newLocation, this.customerId);

    this.appLoader.open();

    await request
      .toPromise()
      .then((res) => {
        if (res) {
          this.snackbarService.show(res.toString());
          this.router.navigateByUrl(
            "/mapAds/customers/show/" + this.customerId
          );
        }
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
        this.appLoader.close();
      });

    this.appLoader.close();
  }
}
