import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ModuleService {
  public modules: string[] = null;

  constructor() {
    this.modules = this.getModules();
  }

  hasAccess(items: string[]): Boolean {
    let hasAccess: boolean = true;

    items.forEach((item) => {
      if (!this.modules.includes(item)) {
        hasAccess = false;
      }
    });

    return hasAccess;
  }

  restoreModules(): string[] {
    return window.localStorage["modules"].split(",");
  }

  getModules() {
    if (!this.modules) {
      this.modules = this.restoreModules();
    }

    return this.modules;
  }
}
