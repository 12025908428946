import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  NgForm,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { AppDateAdapter } from "app/shared/adapters/app-date-adapter";
import { CUSTOM_DATE_FORMATS } from "../../../../../../constants";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { SocialMediaSettings } from "app/model/account/SocialMediaSettings";
import { environment } from "environments/environment";
import { ValidationService } from "app/shared/services/validation.service";
import { CurrencyService } from "app/shared/services/currency.service";
import { MPMediaAsset } from "app/model/marketing-portal/MPMediaAsset";
import { MPMediaAssetUploader } from "app/model/marketing-portal/MPMediaAssetUploader";
import { MPMediaUploadFormatConfiguration } from "app/model/marketing-portal/MPMediaUploadFormatConfiguration";
import { MPMediaUploadFormatHandler } from "app/model/marketing-portal/MPMediaUploadFormatHandler";

@Component({
  selector: "create-instagram-facebook-boost-post-form",
  templateUrl: "./create-instagram-facebook-boost-post-form.component.html",
  styleUrls: ["./create-instagram-facebook-boost-post-form.component.scss"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "de-De" },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class CreateInstagramFacebookBoostPostFormComponent implements OnInit {
  @ViewChild("form") form: NgForm;
  @Input() duplicate: number = null;
  @Input() data?: any;
  @Input() readonly?: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() campaignChannelId?: number = null;
  @Input() customer?: any;
  public isLoading: boolean = false;
  public campaignForm: FormGroup;
  public mediaPackages: any;
  public mediaPackageType: number;
  public selectedMediaPackage: any;
  public channelPackages: any;
  public accountType: number;
  public maxTextLength: number = 256;
  public maxLinkLength: number = 256;
  public errors = {};
  public regions: any = [];
  public ageGroups: any;
  public genders: any;
  public targetGroups: any;
  public socialMediaSettings: SocialMediaSettings;
  public MIN_AGE_DIFFERENCE: number = 6;
  public readonly environment = environment;
  public previewMediaFiles: any = [];
  public START_DATE_DAYS_IN_FUTURE: number = 3;
  public MAX_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS = 30;
  public MIN_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS = 7;
  public MIN_AGE = 18;
  public MAX_AGE = 65;
  public showTargetGroup: boolean = false;
  public locations: any[] = [];
  public categories: any = [];
  public manualBudgetImpressions: number = 0;
  public manualBudgetGoal: any;
  public manualBudgeRaw: number = 0;
  public manualBudgetImpressionsSub: any;
  public MAX_MANUAL_BUDGET: number = 1000000;
  public selectedSocialMediaCampaignCategory = null;
  public selectedFacebookPageName: string;
  public selectedFacebookImageUrl: string;
  readonly SOCIAL_MEDIA_CATEGORY_ID_JOBS: number = 2;
  readonly SOCIAL_MEDIA_CATEGORY_ID_NONE: number = 1;
  public showCustomerApproval: boolean = false;
  public showFBBrandedContentSelection: boolean = false;
  public mediaAssets: MPMediaAsset[] = [];
  public videoUploadConfiguration: MPMediaUploadFormatConfiguration;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private adapter: DateAdapter<any>,
    private crudService: CrudService,
    private appLoader: AppLoaderService,
    private snackbarService: SnackbarService,
    private domSanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    this.adapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.adapter.setLocale(event.lang);
    });

    this.videoUploadConfiguration =
      new MPMediaUploadFormatHandler().getFBVideoConfig();

    this.buildForm(this.data);

    if (!this.data || this.duplicate) {
      let initCampaignName = "[Kopie] " + this.data.name;

      this.campaignForm.get("campaignName").setValue(initCampaignName);
    }

    if (!this.data) {
      if (this.customer && this.customer.customerLocations) {
        this.locations = this.customer.customerLocations;
      } else {
        const userLocations = await this.crudService
          .getUserLocations()
          .toPromise();

        this.locations = userLocations;
      }
    } else {
      this.mediaAssets = this.getMediaAssetObjs(this.data.mediaAssets);
      this.updatePreviewMediaFiles();

      this.showFBBrandedContentSelection = !!this.data.sponsoredFacebookPage;

      this.selectedFacebookImageUrl = this.data.facebookPageImageUrl;
      this.selectedFacebookPageName = this.data.facebookPageName;
    }

    this.socialMediaSettings = await this.crudService
      .getSocialMediaSettings()
      .toPromise();

    this.mediaPackages = await this.crudService
      .getSocialMediaPackages()
      .toPromise();

    this.selectedMediaPackage = this.getInitMediaPackage(this.data);

    if (this.data) {
      this.initTargetGroup();
      this.locations = this.convertLocations(this.data.locations);
    }

    if (this.socialMediaSettings.UsePackages) {
      this.mediaPackageType = this.selectedMediaPackage.packageGoal.goalId;
      this.campaignForm.get("campaignGoalId").setValue(this.mediaPackageType);
      this.channelPackages = this.selectedMediaPackage.channelPackages;

      if (!this.isEdit) {
        this.campaignForm
          .get("campaignPackageId")
          .setValue(this.channelPackages[0].packageId);
      }
    } else {
      this.manualBudgetGoal = this.selectedMediaPackage.packageGoal;
      this.mediaPackageType = this.manualBudgetGoal.goalId;

      this.campaignForm.get("campaignGoalId").setValue(this.mediaPackageType);

      if (!this.isEdit) {
        this.campaignForm
          .get("manualBudget")
          .setValue(this.manualBudgetGoal.goalMinimumBudget);
      }

      this.campaignForm.get("manualBudget").setValidators([
        ValidationService.priceNumber({
          min: this.manualBudgetGoal.goalMinimumBudget,
        }),
      ]);

      this.campaignForm.get("campaignPackageId").setValue(null);
      this.campaignForm.get("campaignPackageId").setValidators(null);

      this.campaignForm.get("manualBudget").updateValueAndValidity();
      this.campaignForm.get("campaignPackageId").updateValueAndValidity();

      this.getManualBudgetImpressions(this.manualBudgetGoal.goalMinimumBudget);
    }

    this.ageGroups = await this.crudService
      .getSocialMediaAgeGroups()
      .toPromise();

    if (this.ageGroups && this.ageGroups.length && !this.data) {
      this.campaignForm
        .get("AgeGroupId")
        .setValue(this.ageGroups[0].ageGroupId);

      this.setFromAndToAge(this.ageGroups[0]);
    }

    this.genders = await this.crudService.getSocialMediaGenders().toPromise();

    if (this.genders && this.genders.length && !this.data) {
      this.campaignForm
        .get("campaignGenderId")
        .setValue(this.genders[0].genderId);
    }

    this.targetGroups = await this.crudService
      .getSocialMediaTargets()
      .toPromise();
    this.categories = await this.crudService
      .getSocialMediaCategories()
      .toPromise();

    if (this.categories && this.categories.length) {
      this.selectedSocialMediaCampaignCategory = this.categories.find(
        (category) =>
          category.id ==
          this.campaignForm.get("socialMediaCampaignCategoryId").value
      );
    }
  }

  getCampaignOwnLink(items) {
    let campaignOwnLink = "";

    if (this.data && this.data.socialMediaCampaignLink) {
      campaignOwnLink = this.data.socialMediaCampaignLink.replace(
        /^https?:\/\//,
        ""
      );
    }

    return campaignOwnLink;
  }

  getInitProtocol(items) {
    let protocol = "https://";

    if (
      this.data &&
      this.data.socialMediaCampaignLink &&
      this.data.socialMediaCampaignLink.includes("http://")
    ) {
      protocol = "http://";
    }

    return protocol;
  }

  buildForm(items) {
    const initDate = this.getInitDate(items);
    const initImageIdx = this.getInitImageIdx(items);
    const initProtocol = this.getInitProtocol(items);
    const initCampaignOwnLink = this.getCampaignOwnLink(items);

    this.campaignForm = this.formBuilder.group(
      {
        mpCampaignId: items ? items.mpCampaignId : null,
        campaignName: [
          items ? items.name : "Meine Kampagne",
          Validators.required,
        ],
        socialMediaCampaignTypeId: [
          items ? items.socialMediaCampaignTypes[0].campaignTypeId : 1,
          Validators.required,
        ],
        campaignPackageId: [
          items && items.package ? items.package.packageId : 0,
          Validators.required,
        ],
        campaignGoalId: [
          items ? items.goalAndPerformance.goalId : 0,
          Validators.required,
        ],

        manualBudget: items ? items.displayBudget : 0,
        imageIdx: [initImageIdx],
        templateImageId: null,
        campaignImageIds: [[]],
        campaignVideoIds: [[]],
        channelId: this.campaignChannelId,
        campaignDisplayText: [
          items && items.socialMediaCampaignDisplayText
            ? items.socialMediaCampaignDisplayText
            : "",
        ],
        protocol: [initProtocol],
        campaignOwnLink: [initCampaignOwnLink],
        campaignStart: [
          initDate.startDate,
          Validators.compose([
            ValidationService.dateGreaterThan({
              source: "campaignEnd",
              reference: "campaignStart",
              minDays: this.MIN_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS,
              maxDays: this.MAX_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS,
            }),
            ValidationService.daysInFuture(this.START_DATE_DAYS_IN_FUTURE),
          ]),
        ],
        campaignStartTime: initDate.startTime,
        campaignEnd: [
          initDate.endDate,
          [
            ValidationService.dateGreaterThan({
              source: "campaignEnd",
              reference: "campaignStart",
              minDays: this.MIN_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS,
              maxDays: this.MAX_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS,
            }),
          ],
        ],
        campaignEndTime: initDate.endTime,
        sponsoredFacebookPage: [
          items && items.sponsoredFacebookPage
            ? items.sponsoredFacebookPage
            : null,
        ],
        campaignFacebookPageId: [
          items ? items.facebookPageId : null,
          Validators.required,
        ],
        fromAge: [
          items ? items.fromAge : this.MIN_AGE,
          [ValidationService.number({ min: this.MIN_AGE, max: this.MAX_AGE })],
        ],
        toAge: [
          items ? items.toAge : this.MAX_AGE,
          [ValidationService.number({ min: this.MIN_AGE, max: this.MAX_AGE })],
        ],
        AgeGroupId: [0, Validators.required],
        socialMediaCampaignCategoryId: [
          items ? items.category.id : 1,
          Validators.required,
        ],
        campaignGenderId: [
          items ? items.gender.genderId : 0,
          Validators.required,
        ],
        campaignTargetIds: [items ? items.targets : []],
        mediaImportType: [],
        adMaterialType: [1],
        useOwnAccount: [0],
        campaignLocations: [[], Validators.required],
        approvalCustomerEmail: [
          this.customer && this.customer.customerMail
            ? this.customer.customerMail
            : "",
        ],
        approvalMessage: "",
      },
      {
        validator: [
          ValidationService.startDateGreaterThanEndDate(
            "campaignStart",
            "campaignEnd"
          ),
          ValidationService.greaterByAmount(
            "toAge",
            "fromAge",
            this.MIN_AGE_DIFFERENCE
          ),
        ],
      }
    );

    if (this.readonly) {
      this.campaignForm.disable();
    }

    this.campaignForm.get("campaignDisplayText").disable();

    if (this.data.linkToPreview) {
      this.campaignForm.get("campaignOwnLink").disable();
    }
  }

  initTargetGroup() {
    const targetIds = [];
    this.data.targets.forEach((targetGroup) => {
      targetIds.push(targetGroup.targetId);
    });

    this.campaignForm.get("campaignTargetIds").setValue(targetIds);
  }

  getMediaAssetObjs(mediaAssets) {
    const objs = [];

    mediaAssets.forEach((mediaAsset) => {
      const newObj = new MPMediaAsset(
        mediaAsset.mediaAssetId,
        mediaAsset.mediaAssetName,
        mediaAsset.width,
        mediaAsset.height,
        mediaAsset.sizeInByte,
        mediaAsset.mediaAssetDataType,
        mediaAsset.mediaAssetIsTransparent,
        mediaAsset.isImage,
        mediaAsset.isVideo,
        mediaAsset.isReady,
        mediaAsset.mediaAssetUpdateTime,
        mediaAsset.mediaAssetData,
        mediaAsset.mediaAssetUrl,
        mediaAsset.mediaAssetThumbnailUrl,
        mediaAsset.isPartOfSet,
        mediaAsset.templateId
      );

      objs.push(newObj);
    });

    return objs;
  }

  onChangeSocialMediaCampaign($event) {
    const value = $event.value;
    this.selectedSocialMediaCampaignCategory = this.categories.find(
      (category) => category.id == value
    );
  }

  changeShowCustomerApproval($event) {
    this.showCustomerApproval = $event.checked;

    if (!this.showCustomerApproval) {
      this.campaignForm.get("approvalCustomerEmail").clearValidators();
    } else {
      this.campaignForm
        .get("approvalCustomerEmail")
        .setValidators([ValidationService.emailValidator, Validators.required]);
    }

    this.campaignForm.get("approvalCustomerEmail").updateValueAndValidity();
  }

  onToggleTargetGroup() {
    this.showTargetGroup = !this.showTargetGroup;
  }

  focusPrice($event) {
    $event.target.value = CurrencyService.removeCurrencySymbol(
      $event.target.value
    );
  }

  blurPrice($event) {
    $event.target.value = CurrencyService.addCurrencySymbol(
      $event.target.value
    );
  }

  convertPrice($event) {
    let rawValue = CurrencyService.formatPriceToNumber($event.target.value);

    if (rawValue > this.MAX_MANUAL_BUDGET) {
      rawValue = this.MAX_MANUAL_BUDGET;
    } else if (isNaN(rawValue)) {
      rawValue = 0;
    }

    $event.target.value = CurrencyService.formatInputPrice(rawValue.toString());
    this.getManualBudgetImpressions(rawValue);
    this.setControlValidationError("manualBudget");
  }

  validateAgeInput($event, name) {
    let value = $event.target.value;
    let isNumber = /^\d+$/.test(value);

    if (!isNumber) {
      let numberValue = value.replace(/\D/g, "");
      this.campaignForm.get(name).setValue(numberValue);
      return;
    }
  }

  onKeyUpFromAge($event) {
    this.validateAgeInput($event, "fromAge");
    this.updateAgeErrors();
  }

  onKeyUpToAge($event) {
    this.validateAgeInput($event, "toAge");
    this.updateAgeErrors();
  }

  updateAgeErrors() {
    this.markAgeControls();
    this.validateErrors();
  }

  onClickBudgetPackage(packageNumber) {
    this.campaignForm.get("campaignPackageId").setValue(packageNumber);
  }

  onChangeUrl($event) {
    let value = $event.target.value;

    if (!value) {
      return;
    }

    value = value.replace(/^https?:\/\//, "");

    this.campaignForm.get("campaignOwnLink").setValue(value);
  }

  onChangeTime($event, controlName) {
    const valueAsDate = $event.target.valueAsDate;
    const dateTimeControl = this.campaignForm.get(
      controlName.replace("Time", "")
    );

    if (valueAsDate) {
      this.updateTimeOfDateTimeControl(dateTimeControl, $event.target.value);

      this.campaignForm.controls["campaignStart"].updateValueAndValidity();
      this.campaignForm.controls["campaignEnd"].updateValueAndValidity();
      this.validateErrors();
    }
  }

  onChangeDate($event, controlName) {
    const dateTimeControl = this.campaignForm.get(controlName);
    const newTime = this.campaignForm.get(controlName + "Time").value;

    this.updateTimeOfDateTimeControl(dateTimeControl, newTime);

    this.campaignForm.controls["campaignStart"].updateValueAndValidity();
    this.campaignForm.controls["campaignEnd"].updateValueAndValidity();

    this.validateErrors();
  }

  validateErrors() {
    this.errors = this.getValidationErrors();
  }

  setControlValidationError(key) {
    this.campaignForm.controls[key].markAsTouched();
    const control = this.campaignForm.get(key);
    const controlErrors: ValidationErrors = control.errors;

    if (controlErrors != null) {
      let error = Object.keys(control.errors)[0];

      // Control has multiple errors - We want to show only one
      if (Object.keys(control.errors).length > 2) {
        // Remove first error
        const firstKey = Object.keys(control.errors)[0];
        delete control.errors[firstKey];

        // Select error
        for (const key of Object.keys(control.errors)) {
          if (key != "validatorValue") {
            error = key;
          }
        }
      }

      this.errors[key] = ValidationService.getValidatorErrorMessage(
        error,
        this.translate,
        control.errors.validatorValue || {}
      );
    } else {
      delete this.errors[key];
    }
  }

  getValidationErrors(): Object {
    const errors = {};

    if (this.regions.length === 0) {
      errors["regions"] = "Bitte füge mindestens eine Region hinzu";
    }

    if (this.campaignForm.hasError("startDateLessThanEndDate")) {
      this.campaignForm.controls["campaignEnd"].setErrors({
        startDateLessThanEndDate: true,
      });
    }

    if (this.campaignForm.hasError("greaterByAmount")) {
      this.campaignForm.controls["fromAge"].setErrors({
        greaterByAmount: true,
      });

      this.campaignForm.controls["toAge"].setErrors({
        greaterByAmount: true,
      });
    } else {
      this.campaignForm.controls["fromAge"].setErrors(null);
      this.campaignForm.controls["toAge"].setErrors(null);
    }

    // FormGroup validation errors
    Object.keys(this.campaignForm.controls).forEach((key) => {
      const control = this.campaignForm.get(key);
      const controlErrors: ValidationErrors = control.errors;

      if (controlErrors != null) {
        let error = Object.keys(control.errors)[0];

        // Control has multiple errors - We want to show only one
        if (Object.keys(control.errors).length > 2) {
          // Remove first error
          const firstKey = Object.keys(control.errors)[0];
          delete control.errors[firstKey];

          // Select error
          for (const key of Object.keys(control.errors)) {
            if (key != "validatorValue") {
              error = key;
            }
          }
        }

        errors[key] = ValidationService.getValidatorErrorMessage(
          error,
          this.translate,
          control.errors.validatorValue || {}
        );
      }
    });

    return errors;
  }

  markAgeControls() {
    this.campaignForm.controls["toAge"].markAsTouched();
    this.campaignForm.controls["fromAge"].markAsTouched();
  }

  onClickMediaPackageType(goalId: number) {
    const mediaPackage = this.mediaPackages.find(
      (item) => item.packageGoal.goalId === goalId
    );

    this.mediaPackageType = goalId;
    this.campaignForm.get("campaignGoalId").setValue(goalId);
    this.channelPackages = mediaPackage.channelPackages;
    this.selectedMediaPackage = mediaPackage;

    this.campaignForm
      .get("campaignPackageId")
      .setValue(this.channelPackages[0].packageId);
  }

  onClickManualGoalType(goalId: number) {
    const goal = this.mediaPackages.find(
      (mediaPackage) => mediaPackage.packageGoal.goalId === goalId
    );

    this.mediaPackageType = goalId;
    this.manualBudgetGoal = goal.packageGoal;

    this.campaignForm.get("campaignGoalId").setValue(goalId);
    this.campaignForm.get("manualBudget").setValidators(null);
    this.campaignForm.get("manualBudget").updateValueAndValidity();
    this.campaignForm.get("manualBudget").setValidators([
      ValidationService.priceNumber({
        min: this.manualBudgetGoal.goalMinimumBudget,
      }),
    ]);
    this.campaignForm.get("manualBudget").updateValueAndValidity();
    this.setControlValidationError("manualBudget");
    this.getManualBudgetImpressions(this.manualBudgeRaw);
  }

  onClickAgeGroup(ageGroup: any) {
    if (this.isDisabled()) {
      return;
    }

    this.campaignForm.get("AgeGroupId").setValue(ageGroup.ageGroupId);
    this.setFromAndToAge(ageGroup);
    this.updateAgeErrors();
  }

  isDisabled() {
    return this.readonly;
  }

  onClickTargetGroup(targetId: any) {
    if (this.isDisabled()) {
      return;
    }

    const targetIds = this.campaignForm.get("campaignTargetIds").value;

    if (targetIds.includes(targetId)) {
      let index = targetIds.findIndex((item) => item === targetId);
      targetIds.splice(index, 1);
    } else {
      targetIds.push(targetId);
    }

    this.campaignForm.get("campaignTargetIds").setValue(targetIds);
  }

  setFromAndToAge(ageGroup) {
    this.campaignForm.get("fromAge").setValue(ageGroup.fromAge);
    this.campaignForm.get("toAge").setValue(ageGroup.toAge);
  }

  onClickGender(genderId: number) {
    this.campaignForm.get("campaignGenderId").setValue(genderId);
  }

  setLocations(data) {
    this.locations = [];
    if (!this.data || this.data.locations.length === 0) {
      return;
    }

    let items = [];
    this.data.locations.forEach((location) => {
      items.push({
        radius: location.locationRadius,
        locationAddress: {
          latitude: location.locationLatitude,
          longitude: location.locationLongitude,
          address: location.locationStreet,
          city: location.locationCity,
          zip_code: location.locationZipCode,
          country: location.locationCountry,
          state: location.locationState,
        },
      });
    });

    this.locations = items;
  }

  getInitMediaPackage(items) {
    if (!items) return this.mediaPackages[0];

    return this.mediaPackages.find(
      (mediaPackage) =>
        mediaPackage.packageGoal.goalId === items.goalAndPerformance.goalId
    );
  }

  convertLocations(locations) {
    let items = [];
    locations.forEach((location) => {
      items.push({
        radius: location.locationRadius,
        locationAddress: {
          latitude: location.locationLatitude,
          longitude: location.locationLongitude,
          address: location.locationStreet,
          city: location.locationCity,
          zip_code: location.locationZipCode,
          country: location.locationCountry,
          state: location.locationState,
        },
      });
    });

    return items;
  }

  getManualBudgetImpressions(budget) {
    this.manualBudgeRaw = budget;

    if (this.manualBudgetGoal.goalMinimumBudget > budget) {
      this.manualBudgetImpressions = 0;
      return;
    }

    if (this.manualBudgetImpressionsSub) {
      this.manualBudgetImpressionsSub.unsubscribe();
    }

    this.manualBudgetImpressionsSub = this.crudService
      .getSocialMediaImpressionsByBudget(this.mediaPackageType, budget)
      .subscribe(
        (res) => {
          this.manualBudgetImpressions = res;
        },
        (err) => {
          this.snackbarService.show(err.error, "danger");
          this.manualBudgetImpressions = 0;
        }
      );
  }

  updatePreviewMediaFiles() {
    this.previewMediaFiles = [];

    this.mediaAssets.forEach((mediaAsset: MPMediaAsset) => {
      this.previewMediaFiles.push({
        safeData: mediaAsset.mediaAssetData
          ? this.domSanitizer.bypassSecurityTrustUrl(
              mediaAsset.mediaAssetData.toString()
            )
          : this.domSanitizer.bypassSecurityTrustUrl(
              mediaAsset.mediaAssetThumbnailUrl
            ),
        isVideo: mediaAsset.isVideo,
        width: null,
      });
    });
  }

  getInitImageIdx(items) {
    if (!items) return [];

    const ids = [];

    items.mediaAssets.forEach((mediaAsset) => {
      ids.push(mediaAsset.mediaAssetId);
    });

    return ids;
  }

  getInitDate(items) {
    const initDate = {
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
    };

    if (!this.isEdit || this.duplicate) {
      let startDate = new Date();
      startDate.setDate(
        startDate.getDate() + this.START_DATE_DAYS_IN_FUTURE + 1
      );
      startDate.setHours(12);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      startDate.setMilliseconds(0);
      initDate.startDate = startDate;
      initDate.startTime = this.getTimeByDate(startDate);

      let endDate = new Date();
      endDate.setDate(
        endDate.getDate() +
          this.START_DATE_DAYS_IN_FUTURE +
          this.MAX_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS
      );
      endDate.setHours(12);
      endDate.setMinutes(0);
      endDate.setSeconds(0);
      endDate.setMilliseconds(0);
      initDate.endDate = endDate;
      initDate.endTime = initDate.startTime;
    } else {
      const startDate = new Date(items.start);
      let offsetStart = (startDate.getTimezoneOffset() * -1) / 60;

      const endDate = new Date(items.end);
      let offsetEnd = (endDate.getTimezoneOffset() * -1) / 60;

      initDate.endDate = new Date(items.end);
      initDate.endDate.setHours(initDate.endDate.getHours() + offsetEnd);
      initDate.endTime = this.getTimeByDate(initDate.endDate);

      initDate.startDate = new Date(items.start);
      initDate.startDate.setHours(initDate.startDate.getHours() + offsetStart);
      initDate.startTime = this.getTimeByDate(initDate.startDate);
    }

    return initDate;
  }

  getTimeByDate(date) {
    const hours =
      date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    const minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    return `${hours}:${minutes}`;
  }

  updateTimeOfDateTimeControl(control, time) {
    const hours = parseInt(time.split(":")[0]);
    const minutes = parseInt(time.split(":")[1]);

    const date = control.value;
    date.setHours(hours);
    date.setMinutes(minutes);
    control.setValue(date);
  }

  setCampaignLocations() {
    let campaignLocations = [];

    this.regions.forEach((region, i) => {
      campaignLocations.push({
        campaignLocationRadiusId: region.radius.radiusId,
        locationLatitude: region.address.latitude,
        locationLongitude: region.address.longitude,
        locationStreet: region.address.address,
        locationStreetNumber: null,
        locationCity: region.address.city,
        locationZipCode: region.address.zip_code,
        locationCountry: region.address.country,
        locationState: region.address.state,
      });
    });

    this.campaignForm.get("campaignLocations").setValue(campaignLocations);
  }

  setMediaFilesIdx() {
    if (this.mediaAssets.length) {
      let type = this.mediaAssets[0].isImage
        ? "campaignImageIds"
        : "campaignVideoIds";
      this.campaignForm
        .get(type)
        .setValue(this.campaignForm.get("imageIdx").value);
    }
  }

  onChangeMediaAsset($event) {
    this.mediaAssets = $event;
    this.updatePreviewMediaFiles();
  }

  setImageIdx() {
    let ids = [];

    this.mediaAssets.forEach((mediaAsset) => {
      ids.push(mediaAsset.mediaAssetId);
    });

    this.campaignForm.get("imageIdx").setValue(ids);
  }

  onCampaignLinkInput($event) {
    let value = $event.target.value;

    this.applyValidatorsForNonEmptyField("campaignOwnLink", [
      ValidationService.urlValidator,
    ]);

    $event.target.value = value;
  }

  applyValidatorsForNonEmptyField(name, validators) {
    if (this.campaignForm.get(name).value === "") {
      this.campaignForm.get(name).clearValidators();
    } else {
      this.campaignForm.get(name).setValidators(validators);
    }
    this.campaignForm.get(name).updateValueAndValidity();
  }

  isValidPostMaterialCheck() {
    return (
      this.campaignForm.get("imageIdx").value.length != 0 ||
      this.campaignForm.get("campaignDisplayText").value != "" ||
      this.campaignForm.get("campaignOwnLink").value != ""
    );
  }

  async onSubmit() {
    this.setCampaignLocations();
    this.setImageIdx();
    this.errors = this.getValidationErrors();

    if (!this.isValidPostMaterialCheck()) {
      this.errors["invalidPostMaterial"] = "INVALID";
    }

    if (this.campaignForm.invalid || Object.keys(this.errors).length) {
      this.snackbarService.show(
        "Bitte fülle alle erforderlichen Felder aus",
        "danger"
      );

      setTimeout(() => {
        let errorMessages = document.querySelector(".error-message");
        if (errorMessages) {
          errorMessages.scrollIntoView();
        }
      });

      return;
    }

    const mpMediaAssetUploader: MPMediaAssetUploader = new MPMediaAssetUploader(
      this.mediaAssets,
      this.crudService
    );

    let uploadError = false;

    await mpMediaAssetUploader
      .upload()
      .then((mediaAssets: MPMediaAsset[]) => {
        this.mediaAssets = mediaAssets;
        this.setImageIdx();
      })
      .catch((e) => {
        uploadError = true;
        this.snackbarService.show(e.error, "danger");
      });

    if (uploadError) {
      document.querySelector(".main-content-wrap").scrollTo(0, 0);
      this.appLoader.close();
      return;
    }

    this.setMediaFilesIdx();

    let value = this.campaignForm.getRawValue();

    const startDate = new Date(value.campaignStart);
    let offset = (startDate.getTimezoneOffset() * -1) / 60;

    const endDate = new Date(value.campaignEnd);
    let offset_enddate = (endDate.getTimezoneOffset() * -1) / 60;

    if (this.duplicate) {
      const postBody = {
        customerId: this.customer ? this.customer.customerId : null,
        socialMediaCampaignTypeId: value.socialMediaCampaignTypeId,
        campaignStart: value.campaignStart,
        campaignEnd: null,
        campaignName: value.campaignName,
        campaignPackageId: null,
        manualBudget: null,
        needsCustomerApproval: false,
        channelId: value.channelId,
        campaignGoalId: 1,
        campaignDisplayText: value.campaignDisplayText,
        campaignOwnLink:
          value.campaignOwnLink != ""
            ? value.protocol + value.campaignOwnLink
            : "",
        campaignImageIds: value.campaignImageIds,
        campaignVideoIds: value.campaignVideoIds,
        campaignGenderId: 1,
        campaignFacebookPageId: value.campaignFacebookPageId,
        fromAge: 18,
        toAge: 24,
        campaignTargetIds: null,
        campaignLocations: null,
        timezone: offset,
      };

      this.appLoader.open();

      await this.crudService
        .createSocialMediaPost(postBody)
        .toPromise()
        .then(async (res) => {
          if (res) {
            const boostedPostBody = {
              campaignName: value.campaignName,
              campaignDisplayText: value.campaignDisplayText,
              campaignOwnLink:
                value.campaignOwnLink != ""
                  ? value.protocol + value.campaignOwnLink
                  : "",
              campaignStart: value.campaignStart,
              campaignEnd: value.campaignEnd,
              goalId: value.campaignGoalId,
              packageId: value.campaignPackageId,
              socialMediaCampaignTypeId: value.socialMediaCampaignTypeId,
              campaignPackageId: value.campaignPackageId,
              manualBudget: value.manualBudget,
              customerId: this.customer ? this.customer.customerId : null,
              channelId: this.campaignChannelId,
              campaignGoalId: value.campaignGoalId,
              campaignImageIds: value.campaignImageIds,
              campaignVideoIds: value.campaignVideoIds,
              campaignGenderId: value.campaignGenderId,
              campaignFacebookPageId: value.campaignFacebookPageId,
              fromAge: value.fromAge,
              toAge: value.toAge,
              campaignTargetIds: value.campaignTargetIds,
              campaignLocations: value.campaignLocations,
              needsCustomerApproval: false,
              socialMediaCampaignCategoryId:
                value.socialMediaCampaignCategoryId,
              timezone: offset,
              timezone_enddate: offset_enddate,
            };

            const smCampaignId = Number(res);

            await this.crudService
              .boostSocialMediaPost(boostedPostBody, smCampaignId)
              .toPromise()
              .then((res) => {
                if (res) {
                  this.snackbarService.show(res.toString(), "success");

                  let redirectUrl =
                    "/mapAds/marketing-portal/checkout/campaign/" +
                    this.campaignChannelId +
                    "/" +
                    smCampaignId +
                    "?checkoutNeeded=" +
                    this.socialMediaSettings.CheckoutNeeded;

                  this.appLoader.close();
                  this.router.navigateByUrl(redirectUrl);
                }
              })
              .catch((e) => {
                this.snackbarService.show(e.error, "danger");
                this.appLoader.close();
              });
          }
        })
        .catch((e) => {
          this.snackbarService.show(e.error, "danger");
          this.appLoader.close();
        });

      return;
    }

    if (this.isEdit) {
      const body = {
        campaignName: value.campaignName,
        campaignDisplayText: value.campaignDisplayText,
        campaignOwnLink:
          value.campaignOwnLink != ""
            ? value.protocol + value.campaignOwnLink
            : "",
        campaignStart: value.campaignStart,
        campaignEnd: value.campaignEnd,
        goalId: value.campaignGoalId,
        packageId: value.campaignPackageId,
        socialMediaCampaignTypeId: value.socialMediaCampaignTypeId,
        campaignPackageId: value.campaignPackageId,
        manualBudget: value.manualBudget,
        customerId: this.customer ? this.customer.customerId : null,
        channelId: this.campaignChannelId,
        campaignGoalId: value.campaignGoalId,
        campaignImageIds: value.campaignImageIds,
        campaignVideoIds: value.campaignVideoIds,
        campaignGenderId: value.campaignGenderId,
        campaignFacebookPageId: value.campaignFacebookPageId,
        fromAge: value.fromAge,
        toAge: value.toAge,
        campaignTargetIds: value.campaignTargetIds,
        campaignLocations: value.campaignLocations,
        needsCustomerApproval: false,
        socialMediaCampaignCategoryId: value.socialMediaCampaignCategoryId,
        timezone: offset,
        timezone_enddate: offset_enddate,
      };

      this.appLoader.open();

      await this.crudService
        .editSocialMediaCampaign(body, this.data.socialMediaCampaignId)
        .toPromise()
        .then((res) => {
          if (res) {
            this.snackbarService.show(
              "Deine Marketing-Kampagne wurde erfolgreich bearbeitet"
            );
            this.appLoader.close();
            this.router.navigateByUrl("/mapAds/marketing-portal/campaigns");
          }
        })
        .catch((e) => {
          this.snackbarService.show(e.error, "danger");
          this.appLoader.close();
        });
    } else {
      const body = {
        campaignName: value.campaignName,
        campaignDisplayText: value.campaignDisplayText,
        campaignOwnLink:
          value.campaignOwnLink != ""
            ? value.protocol + value.campaignOwnLink
            : "",
        campaignStart: value.campaignStart,
        campaignEnd: value.campaignEnd,
        goalId: value.campaignGoalId,
        packageId: value.campaignPackageId,
        socialMediaCampaignTypeId: value.socialMediaCampaignTypeId,
        campaignPackageId: value.campaignPackageId,
        manualBudget: value.manualBudget,
        customerId: this.customer ? this.customer.customerId : null,
        channelId: this.campaignChannelId,
        campaignGoalId: value.campaignGoalId,
        campaignImageIds: value.campaignImageIds,
        campaignVideoIds: value.campaignVideoIds,
        campaignGenderId: value.campaignGenderId,
        campaignFacebookPageId: value.campaignFacebookPageId,
        fromAge: value.fromAge,
        toAge: value.toAge,
        campaignTargetIds: value.campaignTargetIds,
        campaignLocations: value.campaignLocations,
        needsCustomerApproval: false,
        socialMediaCampaignCategoryId: value.socialMediaCampaignCategoryId,
        timezone: offset,
        timezone_enddate: offset_enddate,
      };

      this.appLoader.open();

      await this.crudService
        .boostSocialMediaPost(body, this.data.socialMediaCampaignId)
        .toPromise()
        .then(async (res) => {
          if (res) {
            this.snackbarService.show(res.toString(), "success");

            if (this.showCustomerApproval) {
              let socialMediaCampaign = null;

              await this.crudService
                .getSocialMediaCampaign(this.data.socialMediaCampaignId)
                .toPromise()
                .then((res) => {
                  socialMediaCampaign = res;
                })
                .catch((e) => {
                  this.snackbarService.show(e.error, "danger");
                });

              const body = {
                userMessage: value.approvalMessage,
                optionalMail: value.approvalCustomerEmail,
              };

              await this.crudService
                .requestCustomerApproval(socialMediaCampaign.mpCampaignId, body)
                .toPromise()
                .then((res) => {
                  this.snackbarService.show(
                    "Deine Freigabeanfrage wurde erfolgreich gesendet",
                    "success"
                  );
                })
                .catch((e) => {
                  this.snackbarService.show(e.error, "danger");
                });
            }

            let redirectUrl =
              "/mapAds/marketing-portal/checkout/campaign/" +
              this.campaignChannelId +
              "/" +
              this.data.socialMediaCampaignId +
              "?checkoutNeeded=" +
              this.socialMediaSettings.CheckoutNeeded;

            this.appLoader.close();
            this.router.navigateByUrl(redirectUrl);
          }
        })
        .catch((e) => {
          this.snackbarService.show(e.error, "danger");
          this.appLoader.close();
        });
    }
  }
}
