import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "video-editor-template-slide-card",
  templateUrl: "./video-editor-template-slide-card.component.html",
  styleUrls: ["./video-editor-template-slide-card.component.scss"],
})
export class VideoEditorTemplateSlideCardComponent implements OnInit {
  @Input() slide: any;
  @Input() selectedSlide: any;
  @Input() index: number;
  @Input() editedSlideIds: number[];
  @Input() updatedSlideIds: number[];

  constructor() {}

  ngOnInit() {}
}
