import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { SendCustomerApprovalRequestFormComponent } from "../../forms/campaign/send-customer-approval-request-form/send-customer-approval-request-form.component";

@Component({
  selector: "app-request-customer-approval-dialog",
  templateUrl: "./request-customer-approval-dialog.component.html",
  styleUrls: ["./request-customer-approval-dialog.component.scss"],
})
export class RequestCustomerApprovalDialogComponent implements OnInit {
  @ViewChild(SendCustomerApprovalRequestFormComponent)
  sendCustomerApprovalRequestFormComponent: SendCustomerApprovalRequestFormComponent;
  public isLoading: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<RequestCustomerApprovalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {}

  async submitted(value) {
    this.isLoading = true;

    const { message, email } = value;
    const body = {
      userMessage: message,
      optionalMail: email,
    };

    await this.crudService
      .requestCustomerApproval(this.data.mpCampaignId, body)
      .toPromise()
      .then((res) => {
        this.onClose();
        this.snackbarService.show(
          "Deine Freigabeanfrage wurde erfolgreich gesendet",
          "success"
        );
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.isLoading = false;
  }

  onClose() {
    this.dialogRef.close();
  }

  onApply() {
    this.sendCustomerApprovalRequestFormComponent.onSubmit();
  }
}
