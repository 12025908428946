import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MPMediaAsset } from "app/model/marketing-portal/MPMediaAsset";

@Component({
  selector: "app-marketing-portal-video-editor-dialog",
  templateUrl: "./marketing-portal-video-editor-dialog.component.html",
  styleUrls: ["./marketing-portal-video-editor-dialog.component.scss"],
})
export class MarketingPortalVideoEditorDialogComponent implements OnInit {
  public customerId: any;
  public customerLogo: string;
  public channelId: number;
  public mediaAsset: MPMediaAsset;
  constructor(
    public dialogRef: MatDialogRef<MarketingPortalVideoEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data) {
      this.customerId = this.data.customerId;
      this.customerLogo = this.data.customerLogo;
      this.channelId = this.data.channelId;
      this.mediaAsset = this.data.hasOwnProperty("mediaAsset")
        ? this.data.mediaAsset
        : null;
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  onApply($event) {
    this.dialogRef.close({ templateMediaAsset: $event });
  }
}
