import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MPCampaignStatus } from "app/model/marketing-portal/MPCampaignStatus";
import { MPGDNCampaign } from "app/model/marketing-portal/MPGDNCampaign";
import { environment } from "environments/environment";

@Component({
  selector: "marketing-portal-gdncampaign-overview",
  templateUrl: "./marketing-portal-gdncampaign-overview.component.html",
  styleUrls: ["./marketing-portal-gdncampaign-overview.component.scss"],
})
export class MarketingPortalGDNcampaignOverviewComponent implements OnInit {
  @Input() googleDisplayCampaign: MPGDNCampaign;
  @Input() isFree;
  public readonly environment = environment;
  public readonly MP_CAMPAIGN_STATUS_ID_DRAFT =
    MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_DRAFT;
  constructor(private router: Router) {}

  ngOnInit() {}

  goToPayment() {
    this.router.navigateByUrl(
      "/mapAds/marketing-portal/checkout/campaign/" +
        this.googleDisplayCampaign.channelId +
        "/" +
        this.googleDisplayCampaign.googleDisplayCampaignId +
        "?checkoutNeeded=true"
    );
  }
}
