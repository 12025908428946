import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "campaign-region-item",
  templateUrl: "./campaign-region-item.component.html",
  styleUrls: ["./campaign-region-item.component.scss"],
})
export class CampaignRegionItemComponent implements OnInit {
  @Input() region;
  @Input() radiusOptions: any[];
  @Input() isOpen: boolean = true;
  @Input() isLocked?: boolean = false;
  @Output() removeRegion: EventEmitter<any> = new EventEmitter();
  public selectedRadius: any;
  public selectedRadiusId: number;

  constructor() { }

  ngOnInit() {
    this.selectedRadius = this.region.radius;
    this.selectedRadiusId = this.selectedRadius.radiusId;
  }

  setRadius($event) {
    const value = $event.value;
    const radiusOption = this.radiusOptions.find(
      (item) => item.radiusId === value
    );

    this.selectedRadius = radiusOption;
    this.selectedRadiusId = value;
    this.region.radius = radiusOption;
  }

  remove(id: number) {
    this.removeRegion.emit(id);
  }

  onOpen() {
    this.isOpen = !this.isOpen;
  }
}
