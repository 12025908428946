export class MPCampaignStatus {
  static readonly MP_CAMPAIGN_STATUS_ID_ERROR = -1;
  static readonly MP_CAMPAIGN_STATUS_ID_DRAFT = 5;
  static readonly MP_CAMPAIGN_STATUS_ID_CUSTOMER_CHECK = 10;
  static readonly MP_CAMPAIGN_STATUS_ID_CUSTOMER_DECLINED = 11;
  static readonly MP_CAMPAIGN_STATUS_ID_SCHEDULED = 30;
  static readonly MP_CAMPAIGN_STATUS_ID_PUBLISHING = 40;
  static readonly MP_CAMPAIGN_STATUS_ID_DECLINED = 50;
  static readonly MP_CAMPAIGN_STATUS_ID_ACTIVE = 60;
  static readonly MP_CAMPAIGN_STATUS_ID_EXPIRED = 70;
  static readonly MP_CAMPAIGN_STATUS_ID_FINISHED = 100;

  public statusId: number;
  public statusDisplayText: string;

  constructor(statusId: number = null, statusDisplayText: string = null) {
    this.statusId = statusId;
    this.statusDisplayText = statusDisplayText;
  }

  deserialize(serializedObj: object): MPCampaignStatus {
    if (!serializedObj) {
      return null;
    }

    this.statusId = serializedObj["statusId"];
    this.statusDisplayText = serializedObj["statusDisplayText"];

    return this;
  }
}
