import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { SponsoredFacebookPage } from "app/model/marketing-portal/SponsoredFacebookPage";
import { CrudService } from "app/views/mapAds/crud.service";

@Component({
  selector: "facebook-page-autocomplete-selector",
  templateUrl: "./facebook-page-autocomplete-selector.component.html",
})
export class FacebookPageAutocompleteSelectorComponent implements OnInit {
  @Input() control: FormControl;
  @Input() readonly?: boolean = false;
  public search: string;
  public facebookPages: any = [];
  public selectedFacebookPage: any;
  public sub: any;

  constructor(private crudService: CrudService) {}

  ngOnInit() {
    if (this.control.value) {
      this.selectedFacebookPage = this.control.value;
    }
  }

  onChangeSearch($event) {
    this.search = $event.target.value;
    this.fetchFBPages();
  }

  fetchFBPages() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    this.sub = this.crudService
      .searchForFacebookPage(this.search)
      .subscribe((res) => {
        this.facebookPages = res;
      });
  }

  onSelectionChanged($event) {
    const { pageId, pageName, pageImageUrl } = $event.option.value;

    const sponsoredFacebookPage: SponsoredFacebookPage =
      new SponsoredFacebookPage(pageName, pageId, pageImageUrl);

    this.selectedFacebookPage = sponsoredFacebookPage;
    this.control.setValue(sponsoredFacebookPage);
  }

  removeSelection() {
    this.selectedFacebookPage = null;
    this.search = "";
    this.control.setValue(null);

    console.log("remove this.control", this.control);
  }
}
