import { blobToBase64 } from "app/shared/utils/blob.utils";

export class Base64 {
  public static getBase64FromImageUrl(url, width, height) {
    return new Promise((resolve, reject) => {
      var img = new Image();

      img.setAttribute("crossOrigin", "*");

      img.onload = function () {
        var canvas = document.createElement("canvas");

        canvas.width = width;
        canvas.height = height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        canvas.remove();

        return resolve(dataURL);
      };

      img.src = url + "&datetime=" + new Date();
    });
  }

  public static getBase64FramesFromVideo(
    seconds: number[],
    url: string
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const frames: string[] = [];

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const video = document.createElement("video");
      video.src = url;

      video.addEventListener("loadedmetadata", function () {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
      });

      seconds.forEach((second) => {
        video.load();

        video.addEventListener("canplay", function () {
          this.currentTime = second;
        });

        video.addEventListener("loadeddata", async function () {
          context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

          frames.push(canvas.toDataURL());

          if (frames.length === seconds.length) {
            video.remove();
            canvas.remove();
            resolve(frames);
          }
        });
      });
    });
  }
}
