import { Component, Input, OnInit } from "@angular/core";
import { MPFontFamily } from "app/model/marketing-portal/MPFontFamily";
import { MPFontWeight } from "app/model/marketing-portal/MPFontWeight";

@Component({
  selector: "marketing-portal-editor-font-configuration-toolset",
  templateUrl:
    "./marketing-portal-editor-font-configuration-toolset.component.html",
  styleUrls: [
    "./marketing-portal-editor-font-configuration-toolset.component.scss",
  ],
})
export class MarketingPortalEditorFontConfigurationToolsetComponent
  implements OnInit
{
  @Input() templateConfigModel: any;
  @Input() fontFamily: any;
  @Input() fontWeight: any;
  @Input() fontFamilies: MPFontFamily[];
  @Input() fontWeights: MPFontWeight[];
  public isOpen: boolean = true;
  public selectedFontFamilyId: number;
  public selectedFontWeightId: number;
  constructor() {}

  ngOnInit() {
    this.selectedFontFamilyId = this.fontFamily.Id;
    this.selectedFontWeightId = this.fontWeight.Id;
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  onChangeFontFamily($event) {
    const id: number = $event.value;

    this.templateConfigModel.FontFamily = this.fontFamilies.find(
      (sound) => sound.Id === id
    );
  }

  onChangeFontWeight($event) {
    const id: number = $event.value;

    this.templateConfigModel.FontWeight = this.fontWeights.find(
      (sound) => sound.Id === id
    );
  }
}
