import { MPTopic } from "./MPTopic";

export class MPTopicList {
  public mpTopics: MPTopic[];

  constructor() {}

  public deserializeList(serializedObj: MPTopic[]): MPTopic[] {
    if (!serializedObj) {
      return null;
    }

    return serializedObj.map((x: MPTopic) => new MPTopic().deserialize(x));
  }
}
