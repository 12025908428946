import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { SelectMenu } from "app/model/SelectMenu";
import { StoreDetails } from "app/model/StoreDetails";
import { environment } from "environments/environment";

@Component({
  selector: "channel-heat-map",
  templateUrl: "./channel-heat-map.component.html",
  styleUrls: ["./channel-heat-map.component.scss"],
})
export class ChannelHeatMapComponent implements OnInit {
  @Input() locationsStatistics: any;
  @Input() stores: any = [];
  @ViewChild("map") mapElement: ElementRef;
  public map: google.maps.Map;
  public channelMenuItems: SelectMenu[];
  public polygons: google.maps.Polygon[];
  public markers: google.maps.Marker[];
  public storeMarkers: any;
  public zoomLevel: number;
  public selectedStore: StoreDetails;
  public locationStatisticsOverall: any;
  public useGoogleStatistics: boolean;
  public useYoutubeStatistics: boolean;

  constructor() {
    this.polygons = [];
    this.markers = [];
    this.storeMarkers = [];

    this.useGoogleStatistics = true;
    this.useYoutubeStatistics = true;
  }

  ngOnInit() {
    this.initFilter();
    this.initMap();

    this.setData();
    this.setStores();
    this.setPosition();
  }

  initFilter() {
    this.channelMenuItems = [];

    this.locationsStatistics.forEach((statistic) => {
      const channelName = statistic.ChannelName;
      this.channelMenuItems.push({
        label: channelName,
        value: channelName.toUpperCase(),
        icon:
          channelName.toLowerCase() === "total"
            ? ""
            : channelName.toLowerCase() + "Logo",
        isSelected: channelName.toLowerCase() === "total",
      });
    });
  }

  initMap() {
    const mapOptions: google.maps.MapOptions = {
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      scrollwheel: false,
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "administrative.neighborhood",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.arterial",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.local",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
  }

  setData() {
    this.clearMap();
    this.setPolygons();
    // this.setBounds();
  }

  setStores() {
    const self = this;
    this.stores.forEach((store) => {
      const marker = new google.maps.Marker({
        map: this.map,
        draggable: false,
        animation: google.maps.Animation.DROP,
        position: new google.maps.LatLng(store.latitude, store.longitude),
        zIndex: 9999,
      });
      if (store.storeId) {
        marker.setIcon({
          url:
            environment.imageServerUrl +
            "api/public/GetStoreMarker?id=" +
            store.storeId +
            "&v=" +
            new Date(),
          scaledSize: new google.maps.Size(35, 35),
        });
      }

      google.maps.event.addListener(marker, "click", function () {
        self.showStoreInformation(store);
      });

      this.storeMarkers.push({ marker, store });
    });
  }

  getActiveLocationstatistics() {
    if (this.useGoogleStatistics && this.useYoutubeStatistics) {
      return this.locationsStatistics.find(
        (statistic) => statistic.ChannelName === "Total"
      );
    }

    if (this.useGoogleStatistics) {
      return this.locationsStatistics.find(
        (statistic) => statistic.ChannelName === "Google"
      );
    }

    if (this.useYoutubeStatistics) {
      return this.locationsStatistics.find(
        (statistic) => statistic.ChannelName === "Youtube"
      );
    }

    return null;
  }

  showStoreInformation(store) {
    this.storeMarkers.forEach((storeMarker) => {
      if (storeMarker.store.storeId === store.storeId) {
        this.setMarkerAnimation(storeMarker.marker);
      } else {
        this.resetAnimation(storeMarker.marker);
      }
    });
    this.selectedStore = store;
  }

  onCloseSelectedStore() {
    this.selectedStore = null;
  }

  setPolygons() {
    const locationsStatistic = this.getActiveLocationstatistics();
    if (
      !locationsStatistic ||
      !locationsStatistic.hasOwnProperty("ChannelStatistics")
    ) {
      return;
    }

    this.polygons = [];

    locationsStatistic.ChannelStatistics.forEach((channelStatistic) => {
      if (channelStatistic.Map.Coordinates) {
        const coordsArray = JSON.parse(channelStatistic.Map.Coordinates);

        if (channelStatistic.Map.IsMultiPolygon) {
          coordsArray.forEach((deepCoordsArray) => {
            this.addCoords(deepCoordsArray, channelStatistic);
          });
        } else {
          this.addCoords(coordsArray, channelStatistic);
        }
      }
    });
  }

  addCoords(coordsArray, channelStatistic) {
    coordsArray.forEach((coordinates) => {
      const points = [];
      for (var i = 0; i < coordinates.length; i++) {
        points.push(
          new google.maps.LatLng(coordinates[i][1], coordinates[i][0])
        );
      }

      const polygon = new google.maps.Polygon({
        paths: points,
        strokeColor: "#7bb4f0",
        strokeOpacity: 0.6,
        strokeWeight: 2,
        fillColor: "#7bb4f0",
        fillOpacity: +channelStatistic.Heat,
      });

      const bounds = this.getBoundFromPolygon(polygon);

      const icon = {
        path: "M17.997 18h-11.995l-.002-.623c0-1.259.1-1.986 1.588-2.33 1.684-.389 3.344-.736 2.545-2.209-2.366-4.363-.674-6.838 1.866-6.838 2.491 0 4.226 2.383 1.866 6.839-.775 1.464.826 1.812 2.545 2.209 1.49.344 1.589 1.072 1.589 2.333l-.002.619zm4.811-2.214c-1.29-.298-2.49-.559-1.909-1.657 1.769-3.342.469-5.129-1.4-5.129-1.265 0-2.248.817-2.248 2.324 0 3.903 2.268 1.77 2.246 6.676h4.501l.002-.463c0-.946-.074-1.493-1.192-1.751zm-22.806 2.214h4.501c-.021-4.906 2.246-2.772 2.246-6.676 0-1.507-.983-2.324-2.248-2.324-1.869 0-3.169 1.787-1.399 5.129.581 1.099-.619 1.359-1.909 1.657-1.119.258-1.193.805-1.193 1.751l.002.463z",
        fillColor: "#7bb4f0",
        fillOpacity: 1,
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 27),
        labelOrigin: new google.maps.Point(12, 27),
        strokeWeight: 0,
        scale: 0,
      };

      const marker = new google.maps.Marker({
        position: bounds.getCenter(),
        map: this.map,
        zIndex: -1,
        icon: icon,
        label: {
          color: "#7bb4f0",
          fontWeight: "bold",
          text: channelStatistic.Statistics.Impressions.toLocaleString(),
          fontSize: "12px",
          fontFamily: "'Roboto'",
        },
      });

      this.markers.push(marker);
      this.polygons.push(polygon);
      polygon.setMap(this.map);
    });
  }

  getBoundFromPolygon(polygon) {
    const bounds = new google.maps.LatLngBounds();

    polygon.getPaths().forEach((p) => {
      p.forEach((element) => bounds.extend(element));
    });

    return bounds;
  }

  setPosition() {
    if (this.storeMarkers && this.storeMarkers.length > 0) {
      this.map.setCenter(this.storeMarkers[0].marker.getPosition());
    } else {
      //center in germany
      this.map.setCenter({
        lat: 50.875158,
        lng: 8.016272,
      });
    }

    this.map.setZoom(10);
  }

  setBounds() {
    const bounds = new google.maps.LatLngBounds();

    this.polygons.forEach((polygon) => {
      polygon.getPaths().forEach((p) => {
        p.forEach((element) => bounds.extend(element));
      });
    });

    this.map.fitBounds(bounds);

    const self = this;
    this.map.addListener("idle", function () {
      self.map.setZoom(self.map.getZoom() + 1);
      google.maps.event.clearListeners(self.map, "idle");
    });
  }

  clearMap() {
    this.polygons.forEach((polygon) => {
      polygon.setMap(null);
    });

    this.markers.forEach((marker) => {
      marker.setMap(null);
    });
  }

  selectChannelMenu($event) {
    const { channelName, checked } = $event;

    if (channelName === "Google") {
      this.useGoogleStatistics = checked;
    }

    if (channelName === "Youtube") {
      this.useYoutubeStatistics = checked;
    }

    this.setData();
  }

  onSelectStore($event) {
    const store = $event;
    this.selectedStore = null;

    this.scrollToMap();

    this.storeMarkers.forEach((storeMarker) => {
      if (storeMarker.store.storeId === store.storeId) {
        this.setMarkerAnimation(storeMarker.marker);
      } else {
        this.resetAnimation(storeMarker.marker);
      }
    });

    this.map.setZoom(10);
    this.map.setCenter(new google.maps.LatLng(store.latitude, store.longitude));
  }

  onSelectZipcode($event) {
    this.scrollToMap();
    this.setZipcodeAnimation($event);
  }

  setZipcodeAnimation(locationsStatistic) {
    let selectedPolygons: google.maps.Polygon[] = [];

    if (locationsStatistic.Map.Coordinates) {
      const coordsArray = JSON.parse(locationsStatistic.Map.Coordinates);

      if (locationsStatistic.Map.IsMultiPolygon) {
        coordsArray.forEach((deepCoordsArray) => {
          selectedPolygons = this.addToSelectedPolygons(deepCoordsArray);
          selectedPolygons.forEach((polygon) => {
            this.setPolygonAnimation(polygon, locationsStatistic.Heat);
          });
        });
      } else {
        selectedPolygons = this.addToSelectedPolygons(coordsArray);
        selectedPolygons.forEach((polygon) => {
          this.setPolygonAnimation(polygon, locationsStatistic.Heat);
        });
      }
    }
  }

  addToSelectedPolygons(coordsArray) {
    const selectedPolygons: google.maps.Polygon[] = [];
    coordsArray.forEach((coordinates) => {
      const points = [];
      for (var i = 0; i < coordinates.length; i++) {
        points.push(
          new google.maps.LatLng(coordinates[i][1], coordinates[i][0])
        );
      }

      const polygon = new google.maps.Polygon({
        paths: points,
        strokeColor: "#7bb4f0",
        strokeOpacity: 0.6,
        strokeWeight: 2,
        fillColor: "#7bb4f0",
        fillOpacity: 0,
      });

      const bounds = this.getBoundFromPolygon(polygon);

      this.map.setCenter(bounds.getCenter());

      selectedPolygons.push(polygon);
    });

    return selectedPolygons;
  }

  setPolygonAnimation(polygon, heat) {
    polygon.setMap(this.map);

    let requestId;

    (function () {
      var opacityUp = true;
      var animOpacity = 0.0;
      var animStep = 0.03;
      function animatePolygons() {
        if (opacityUp) {
          animOpacity += animStep;
          if (animOpacity >= 1) {
            animOpacity = 1;
            opacityUp = false;
          }
        } else {
          animOpacity -= animStep;
          if (animOpacity <= +heat) {
            polygon.setMap(null);
            cancelAnimationFrame(requestId);
            requestId = undefined;

            return;
          }
        }
        polygon.setOptions({
          fillOpacity: animOpacity,
          fillColor: "#7bb4f0",
          strokeColor: "#7bb4f0",
          strokeOpacity: 1,
          strokeWeight: 2,
        });
        requestId = requestAnimationFrame(animatePolygons);
      }

      animatePolygons();
    })();
  }

  resetAnimation(marker) {
    marker.setAnimation(null);
  }

  setMarkerAnimation(marker, duration = 2000) {
    marker.setAnimation(google.maps.Animation.BOUNCE);

    const self = this;
    setTimeout(function () {
      self.resetAnimation(marker);
    }, duration);
  }

  scrollToMap() {
    const mapContainer = document.getElementById("map-container");

    if (mapContainer.getBoundingClientRect().top < 0) {
      mapContainer.scrollIntoView();
    }
  }
}
