import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MPChannel } from "app/model/marketing-portal/MPChannel";
import { LayoutService } from "app/shared/services/layout.service";

@Component({
  selector: "marketing-portal-editor-templates",
  templateUrl: "./marketing-portal-editor-templates.component.html",
  styleUrls: ["./marketing-portal-editor-templates.component.scss"],
})
export class MarketingPortalEditorTemplatesComponent implements OnInit {
  @Output() onSelectTemplate: EventEmitter<any> = new EventEmitter();
  @Input() channelId: number;
  @Input() isOpenMenu: boolean;
  @Input() templates;
  @Input() isVideo?: boolean = false;
  public count: number;
  public groupedTemplates: any = [];
  public isSet: boolean;

  constructor(public layout: LayoutService) {}

  async ngOnInit() {
    this.setIsSet();
    this.count = this.templates.length;
    this.groupTemplateByCategory();
  }

  setIsSet() {
    this.isSet = this.channelId === MPChannel.CHANNEL_ID_GOOGLE_GDN;
  }

  groupTemplateByCategory() {
    this.templates.forEach((template) => {
      let categoryName = template.category.templateCategoryName;

      if (!this.categoryExists(categoryName)) {
        this.groupedTemplates.push({
          category: {
            name: categoryName,
          },
          templates: [],
        });
      }

      let index = this.getGroupedTemplateIndexByCategory(categoryName);
      this.groupedTemplates[index].templates.push(template);
    });
  }

  categoryExists(categoryName) {
    return this.getGroupedTemplateIndexByCategory(categoryName) != -1;
  }

  getGroupedTemplateIndexByCategory(categoryName) {
    return this.groupedTemplates.findIndex(
      (groupedTemplate) => groupedTemplate.category.name === categoryName
    );
  }
}
