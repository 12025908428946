import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  NgForm,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MPMediaAsset } from "app/model/marketing-portal/MPMediaAsset";
import { MPMediaAssetUploader } from "app/model/marketing-portal/MPMediaAssetUploader";
import { UploadedFile } from "app/model/UploadedFile";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { ValidationService } from "app/shared/services/validation.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { CustomFileUploader } from "app/views/mapAds/custom_file_uploader";
import { environment } from "environments/environment";

@Component({
  selector: "create-customer-form",
  templateUrl: "./create-customer-form.component.html",
  styleUrls: ["./create-customer-form.component.scss"],
})
export class CreateCustomerFormComponent implements OnInit {
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  @Input() readonly: boolean = false;
  @Input() data: any = null;
  @ViewChild("form") form: NgForm;
  public showContact: boolean = false;
  public showLogo: boolean = false;
  public formGroup: FormGroup;
  public errors: any = {};
  public customUploader: CustomFileUploader;
  public uploadedFiles: Array<UploadedFile>;
  readonly environment = environment;
  public mediaAssets: MPMediaAsset[] = [];
  public uploadMessages: object = {
    title: "Kein Kundenlogo hinzugefügt",
    body: "Für diesen Kunden hast Du kein Logo hinzugefügt",
  };

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private crudService: CrudService,
    private router: Router,
    private appLoader: AppLoaderService
  ) {
    this.uploadedFiles = [];
  }

  ngOnInit() {
    this.buildFormGroup(this.data);
    this.initCustomerLogo(this.data);
  }

  initCustomerLogo(items) {
    if (this.data && this.data.customerMediaAssetId) {
      const newMediaAsset: MPMediaAsset = new MPMediaAsset(
        this.data.customerMediaAssetId,
        "Logo",
        null,
        null,
        null,
        "image/png",
        null,
        true,
        false,
        true,
        null,
        null,
        null,
        null,
        false,
        null
      );

      newMediaAsset.mediaAssetThumbnailUrl = newMediaAsset.getMediaUrlById();
      newMediaAsset.mediaAssetUrl = newMediaAsset.getMediaUrlById();
      this.mediaAssets.push(newMediaAsset);
    }
  }

  onChangeMediaAsset($event) {
    this.mediaAssets = $event;
  }

  async buildFormGroup(items) {
    const initProtocol = this.getInitProtocol();
    const initHomepage = this.getInitHomepage();

    this.formGroup = this.fb.group({
      id: [items ? items.customerId : null],
      name: [items ? items.customerName : "", Validators.required],
      description: [items ? items.customerDescription : ""],
      email: [
        items && items.customerMail ? items.customerMail : "",
        ValidationService.emailValidator,
      ],
      phone: [items ? items.customerPhone : ""],
      homepage: [initHomepage, ValidationService.urlValidator],
      protocol: [initProtocol],
      imageId: items ? items.customerMediaAssetId : null,
      color: items ? items.customerCorporateColor : null,
      locations: items ? items.customerLocations : [],
    });

    if (this.readonly) {
      this.formGroup.disable();
    }
  }

  onChangeUrl($event) {
    let value = $event.target.value;

    if (!value) {
      return;
    }

    value = value.replace(/^https?:\/\//, "");

    this.formGroup.get("homepage").setValue(value);
  }

  getInitProtocol() {
    let protocol = "https://";

    if (
      this.data &&
      this.data.customerHomepage &&
      this.data.customerHomepage.includes("http://")
    ) {
      protocol = "http://";
    }

    return protocol;
  }

  getInitHomepage() {
    let homepage = "";

    if (this.data && this.data.customerHomepage) {
      homepage = this.data.customerHomepage.replace(/^https?:\/\//, "");
    }

    return homepage;
  }

  submit() {
    this.form.onSubmit(undefined);
  }

  fileSelect(event) {
    const newFile: File = event[0];
    this.customUploader.addRange([newFile]);

    const demo = new UploadedFile();
    const reader = new FileReader();

    reader.onload = (_event) => {
      demo.name = newFile.name;
      demo.type = newFile.type.split("/")[0];
      demo.format = newFile.type;
      demo.size = newFile.size;
      demo.data = reader.result;
      demo.megaBytes = (newFile.size / 1024 ** 2).toFixed(2);
      this.uploadedFiles.push(demo);
    };
    reader.readAsDataURL(newFile);
  }

  markFormControls(controls) {
    for (const key of Object.keys(controls)) {
      controls[key].markAsTouched();
      if (controls[key].hasOwnProperty("controls")) {
        this.markFormControls(controls[key].controls);
      }
    }
  }

  getValidationErrors(): void {
    this.errors = {};
    this.getValidationErrorsByControls(this.formGroup.controls);
  }

  getValidationErrorsByControls(controls) {
    Object.keys(controls).forEach((key) => {
      const control = controls[key];
      const controlErrors: ValidationErrors = control.errors;
      if (controlErrors != null) {
        const error = Object.keys(control.errors)[0];

        this.errors[key] = ValidationService.getValidatorErrorMessage(
          error,
          this.translate,
          control.errors.validatorValue || {}
        );
      }

      if (controls[key].hasOwnProperty("controls")) {
        this.getValidationErrorsByControls(controls[key].controls);
      }
    });
  }

  applyValidatorsForNonEmptyField(name, validators) {
    if (this.formGroup.get(name).value === "") {
      this.formGroup.get(name).clearValidators();
    } else {
      this.formGroup.get(name).setValidators(validators);
    }
    this.formGroup.get(name).updateValueAndValidity();
  }

  async onSubmit() {
    this.applyValidatorsForNonEmptyField("homepage", [
      ValidationService.urlValidator,
    ]);

    this.applyValidatorsForNonEmptyField("email", [
      ValidationService.emailValidator,
    ]);

    this.markFormControls(this.formGroup.controls);

    if (this.formGroup.invalid) {
      this.getValidationErrors();

      this.snackbarService.show(
        "Bitte fülle alle erforderlichen Felder aus",
        "danger"
      );

      setTimeout(() => {
        let errorMessages = document.querySelector(".error-message");
        if (errorMessages) {
          errorMessages.scrollIntoView();
        }
      });

      return;
    }

    this.appLoader.open();

    // Process data
    const value = this.formGroup.getRawValue();

    // Upload logo image
    const mpMediaAssetUploader: MPMediaAssetUploader = new MPMediaAssetUploader(
      this.mediaAssets,
      this.crudService
    );

    let uploadError = false;

    await mpMediaAssetUploader
      .upload()
      .then((mediaAssets: MPMediaAsset[]) => {
        this.mediaAssets = mediaAssets;
      })
      .catch((e) => {
        uploadError = true;
        this.snackbarService.show(e.error, "danger");
      });

    if (uploadError) {
      document.querySelector(".main-content-wrap").scrollTo(0, 0);
      this.appLoader.close();
      return;
    }

    value.customerMediaAssetId = this.mediaAssets.length
      ? this.mediaAssets[0].mediaAssetId
      : null;

    const newCustomer = {
      customerId: value.id,
      customerName: value.name,
      customerDescription: value.description == "" ? null : value.description,
      customerLogoId: value.customerMediaAssetId,
      customerMediaAssetId: value.customerMediaAssetId,
      customerCorporateColor: value.color,
      customerMail: value.email == "" ? null : value.email,
      customerPhone: value.phone == "" ? null : value.phone,
      customerHomepage:
        value.homepage == "" ? null : value.protocol + value.homepage,
      customerIsDeleted: false,
      customerLocations: value.locations,
    };

    const request =
      this.data && this.data.customerId
        ? this.crudService.updateCustomer(newCustomer)
        : this.crudService.addNewCustomer(newCustomer);

    await request
      .toPromise()
      .then((res) => {
        if (res) {
          this.snackbarService.show(res.toString());
          this.router.navigateByUrl("/mapAds/customers");
        }
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
        this.appLoader.close();
      });

    this.appLoader.close();
  }
}
