import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  NgForm,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { GoogleSettings } from "app/model/account/GoogleSettings";
import { MPMediaAsset } from "app/model/marketing-portal/MPMediaAsset";
import { MPMediaAssetUploader } from "app/model/marketing-portal/MPMediaAssetUploader";
import { MediaAssetPreviewImage } from "app/model/MediaAssetsPreviewImage";
import { AppDateAdapter } from "app/shared/adapters/app-date-adapter";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { CurrencyService } from "app/shared/services/currency.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { ValidationService } from "app/shared/services/validation.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { environment } from "environments/environment";
import { CUSTOM_DATE_FORMATS } from "../../../../../../constants";

@Component({
  selector: "create-gdn-form",
  templateUrl: "./create-gdn-form.component.html",
  styleUrls: ["./create-gdn-form.component.scss"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "de-De" },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class CreateGdnFormComponent implements OnInit {
  @ViewChild("form") form: NgForm;
  @Input() data?: any;
  @Input() duplicate?: number;
  @Input() readonly?: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() campaignChannelId?: number = null;
  @Input() customer?: any;
  @Input() channel: any;
  readonly GOOGLE_DISPLAY_MEDIA_FORMAT_ID_LOGO = 2;
  public mediaAssetPreviewImages: Array<MediaAssetPreviewImage> = [];
  public googleDisplayMediaFormatIds: any[] = [];
  public customerId: number = null;
  public isLoading: boolean = false;
  public campaignForm: FormGroup;
  public mediaPackages: any;
  public mediaPackageType: number;
  public selectedMediaPackage: any;
  public channelPackages: any;
  public accountType: number;
  public maxTextLength: number = 90;
  public maxHeadlineLength: number = 30;
  public maxNameLength: number = 25;
  public maxLinkLength: number = 256;
  public errors: any = {};
  public regions: any = [];
  public ageGroups: any;
  public genders: any;
  public devices: any;
  public mediaFormats: any;
  public googleSettings: GoogleSettings;
  public MIN_AGE_DIFFERENCE: number = 6;
  public previewMediaFiles: any = [];
  public readonly environment = environment;
  public START_DATE_DAYS_IN_FUTURE: number = 3;
  public MAX_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS = 30;
  public MIN_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS = 7;
  public MIN_AGE = 18;
  public MAX_AGE = 65;
  public showTargetGroup: boolean = false;
  public imageInfo: any[] = [];
  public locations: any[] = [];
  public manualBudgetImpressions: number = 0;
  public manualBudgetGoal: any;
  public manualBudgeRaw: number = 0;
  public manualBudgetImpressionsSub: any;
  public MAX_MANUAL_BUDGET: number = 1000000;
  public ctas: any = [];
  public topics: any;
  public showCustomerApproval: boolean = false;
  public googleFormatMediaAssets: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private adapter: DateAdapter<any>,
    private crudService: CrudService,
    private appLoader: AppLoaderService,
    private snackbarService: SnackbarService
  ) {}

  async ngOnInit() {
    this.adapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.adapter.setLocale(event.lang);
    });

    this.campaignChannelId = +this.route.snapshot.paramMap.get("id") || null;
    this.customerId = +this.route.snapshot.queryParams.customer || null;

    if (!this.campaignChannelId) {
      this.snackbarService.show("Die Verknüpfung ist ungültig", "danger");
      this.router.navigateByUrl("/mapAds/marketing-portal/campaigns");
    }

    this.buildForm(this.data);

    let initCampaignName = this.getInitCampaignName();
    this.campaignForm.get("campaignName").setValue(initCampaignName);

    if (this.customerId) {
      setTimeout(() => this.appLoader.open());

      await this.crudService
        .getSingleCustomer(this.customerId)
        .toPromise()
        .then((res) => {
          this.customer = res;
        })
        .catch((e) => {
          this.snackbarService.show(e.error, "danger");
          this.router.navigateByUrl("/mapAds/marketing-portal/campaigns");
        });

      this.locations = this.customer.customerLocations;

      if (this.customer.customerHomepage) {
        this.campaignForm
          .get("finalUrl")
          .setValue(this.customer.customerHomepage.replace(/^https?:\/\//, ""));
      }

      if (this.customer.customerName) {
        this.campaignForm
          .get("businessName")
          .setValue(this.customer.customerName);
      }

      setTimeout(() => this.appLoader.close());
    } else {
      if (!this.data) {
        //we dont have customer AND not edit --> create --> select userlocations
        var userLocations = await this.crudService
          .getUserLocations()
          .toPromise();

        this.locations = userLocations;
      }
    }

    this.ctas = await this.crudService.getGoogleDisplayCTA().toPromise();

    this.googleSettings = await this.crudService
      .getGoogleSettings()
      .toPromise();

    this.mediaPackages = await this.crudService
      .getGoogleDisplayPackages()
      .toPromise();

    this.selectedMediaPackage = this.mediaPackages[0];

    if (this.googleSettings.UsePackages) {
      this.mediaPackageType = this.selectedMediaPackage.packageGoal.goalId;
      this.campaignForm.get("campaignGoalId").setValue(this.mediaPackageType);
      this.channelPackages = this.selectedMediaPackage.channelPackages;

      if (!this.data) {
        this.campaignForm
          .get("campaignPackageId")
          .setValue(this.channelPackages[0].packageId);
      }
    } else {
      this.manualBudgetGoal = this.selectedMediaPackage.packageGoal;
      this.mediaPackageType = this.manualBudgetGoal.goalId;

      this.campaignForm.get("campaignGoalId").setValue(this.mediaPackageType);

      if (!this.data) {
        this.campaignForm
          .get("manualBudget")
          .setValue(this.manualBudgetGoal.goalMinimumBudget);
      }

      this.campaignForm.get("manualBudget").setValidators([
        ValidationService.priceNumber({
          min: this.manualBudgetGoal.goalMinimumBudget,
        }),
      ]);

      this.campaignForm.get("campaignPackageId").setValue(null);
      this.campaignForm.get("campaignPackageId").setValidators(null);

      this.campaignForm.get("manualBudget").updateValueAndValidity();
      this.campaignForm.get("campaignPackageId").updateValueAndValidity();

      this.getManualBudgetImpressions(this.manualBudgetGoal.goalMinimumBudget);
    }

    this.ageGroups = await this.crudService
      .getGoogleDisplayAgeGroups()
      .toPromise();

    if (
      this.ageGroups &&
      this.ageGroups.length &&
      !this.isEdit &&
      !this.duplicate
    ) {
      var allIds = this.ageGroups.map((s) => s.ageGroupId);
      this.campaignForm.get("campaignAgeGroupIds").setValue(allIds);
    }

    this.genders = await this.crudService.getGoogleDisplayGenders().toPromise();

    if (
      this.genders &&
      this.genders.length &&
      !this.isEdit &&
      !this.duplicate
    ) {
      this.campaignForm
        .get("campaignGenderId")
        .setValue(this.genders[0].genderId);
    }

    this.devices = await this.crudService.getGoogleDisplayDevices().toPromise();

    if (
      this.devices &&
      this.devices.length &&
      !this.isEdit &&
      !this.duplicate
    ) {
      this.campaignForm
        .get("campaignDeviceId")
        .setValue(this.devices[0].deviceId);
    }

    this.topics = await this.crudService.GetGoogleTopics().toPromise();
    if (this.isEdit || this.duplicate) {
      this.initTopics();
    }

    this.mediaFormats = await this.crudService
      .GetGoogleDisplayMediaFormats()
      .toPromise();

    this.googleFormatMediaAssets = this.getInitMediaAssets();
  }

  onChangeUrl($event) {
    let value = $event.target.value;

    if (!value) {
      return;
    }

    value = value.replace(/^https?:\/\//, "");

    this.campaignForm.get("finalUrl").setValue(value);
  }

  getInitMediaAssets() {
    const googleFormatMediaAssets = {};
    this.mediaFormats.forEach((mediaFormat) => {
      googleFormatMediaAssets[mediaFormat.googleDisplayMediaFormatId] = {
        mediaAssets: [],
      };
    });

    if (this.data) {
      this.data.mediaAssets.forEach((mediaAsset) => {
        const newMediaAsset = new MPMediaAsset(
          mediaAsset.mediaAssetId,
          mediaAsset.mediaAssetName,
          mediaAsset.width,
          mediaAsset.height,
          mediaAsset.sizeInByte,
          mediaAsset.mediaAssetDataType,
          mediaAsset.mediaAssetIsTransparent,
          mediaAsset.isImage,
          mediaAsset.isVideo,
          mediaAsset.isReady,
          mediaAsset.mediaAssetUpdateTime,
          mediaAsset.mediaAssetData,
          mediaAsset.mediaAssetUrl,
          mediaAsset.mediaAssetThumbnailUrl,
          mediaAsset.isPartOfSet,
          mediaAsset.templateId,
          mediaAsset.googleDisplayMediaFormatId
        );

        googleFormatMediaAssets[
          mediaAsset.googleDisplayMediaFormatId
        ].mediaAssets = [newMediaAsset];
      });
    } else {
      if (this.customer && this.customer.customerMediaAssetId) {
        const mediaAsset = new MPMediaAsset(
          this.customer.customerMediaAssetId,
          "Logo",
          null,
          null,
          null,
          "image/png",
          null,
          true,
          false,
          true,
          null,
          null,
          null,
          null,
          false,
          null,
          this.GOOGLE_DISPLAY_MEDIA_FORMAT_ID_LOGO
        );

        mediaAsset.mediaAssetThumbnailUrl = mediaAsset.getMediaUrlById();

        googleFormatMediaAssets[
          this.GOOGLE_DISPLAY_MEDIA_FORMAT_ID_LOGO
        ].mediaAssets.push(mediaAsset);
      }
    }

    return googleFormatMediaAssets;
  }

  getInitCampaignName() {
    let initCampaignName =
      "GDN Kampagne " +
      new Date().toLocaleDateString(window.localStorage["countryCode"]);

    if (this.customer && this.customer.customerName) {
      initCampaignName =
        this.customer.customerName +
        " GDN " +
        new Date().toLocaleDateString(window.localStorage["countryCode"]);
    }

    if (this.duplicate) {
      initCampaignName = "[Kopie] " + this.data.name;
    } else if (this.isEdit) {
      initCampaignName = this.data.name;
    }

    return initCampaignName;
  }

  initTopics() {
    const topicIds = [];
    if (this.data.topics) {
      this.data.topics.forEach((topic) => {
        topicIds.push(topic.topicId);
      });

      this.campaignForm.get("topicIds").setValue(topicIds);
    }
  }

  getFinalUrl(items) {
    let finalUrl = "";

    if (this.data && this.data.googleDisplayCampaignFinalUrl) {
      finalUrl = this.data.googleDisplayCampaignFinalUrl.replace(
        /^https?:\/\//,
        ""
      );
    }

    return finalUrl;
  }

  getInitProtocol(items) {
    let protocol = "https://";

    if (
      this.data &&
      this.data.googleDisplayCampaignFinalUrl &&
      this.data.googleDisplayCampaignFinalUrl.includes("http://")
    ) {
      protocol = "http://";
    }

    return protocol;
  }

  buildForm(items) {
    const initDate = this.getInitDate(items);
    const initImageInfo = this.getInitImageInfo(items);
    const initAgeGroupIds = this.getInitAgeGroupIds(items);
    const initLocations = this.getInitLocations(items);
    const initProtocol = this.getInitProtocol(items);
    const initFinalUrl = this.getFinalUrl(items);

    this.campaignForm = this.formBuilder.group(
      {
        mpCampaignId: items ? items.mpCampaignId : null,
        campaignName: [
          items ? items.name : "Meine Kampagne",
          Validators.required,
        ],
        businessName: [
          items && items.googleDisplayCampaignBusinessName
            ? items.googleDisplayCampaignBusinessName
            : "Mein Name",
          Validators.required,
        ],
        campaignPackageId: [
          items && items.package ? items.package.packageId : 0,
          Validators.required,
        ],
        campaignGoalId: [
          items ? items.goalAndPerformance.goalId : 0,
          Validators.required,
        ],
        manualBudget: items ? items.displayBudget : 0,
        channelId: items ? items.channelId : this.campaignChannelId,
        protocol: [initProtocol],
        finalUrl: [initFinalUrl, [ValidationService.urlValidator]],
        adMaterialType: [1],
        campaignStart: [
          initDate.startDate,
          Validators.compose([
            ValidationService.dateGreaterThan({
              source: "campaignEnd",
              reference: "campaignStart",
              minDays: this.MIN_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS,
              maxDays: this.MAX_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS,
            }),
            ValidationService.daysInFuture(this.START_DATE_DAYS_IN_FUTURE),
          ]),
        ],
        campaignEnd: [
          initDate.endDate,
          [
            ValidationService.dateGreaterThan({
              source: "campaignEnd",
              reference: "campaignStart",
              minDays: this.MIN_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS,
              maxDays: this.MAX_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS,
            }),
          ],
        ],
        campaignAgeGroupIds: [
          initAgeGroupIds,
          ValidationService.minLengthArray(1),
        ],
        campaignLocations: [initLocations, Validators.required],
        imageInfo: [initImageInfo],
        headline: [
          items ? items.googleDisplayCampaignHeadlines[0] : "",
          Validators.required,
        ],
        description: [
          items ? items.googleDisplayCampaignDescriptions[0] : "",
          Validators.required,
        ],
        longHeadline: [
          items ? items.googleDisplayCampaignLongHeadlines : "",
          Validators.required,
        ],
        callToActionId: items
          ? items.googleDisplayCampaignCallToAction.callToActionId
          : 12,
        campaignDeviceId: [
          items ? items.device.deviceId : 1,
          Validators.required,
        ],
        topicIds: [items ? items.topics : []],
        campaignGenderId: [
          items ? items.gender.genderId : 1,
          Validators.required,
        ],
        approvalCustomerEmail: [
          this.customer && this.customer.customerMail
            ? this.customer.customerMail
            : "",
        ],
        approvalMessage: "",
      },
      {
        validator: [
          ValidationService.startDateGreaterThanEndDate(
            "campaignStart",
            "campaignEnd"
          ),
        ],
      }
    );
  }

  onClickTopic(topicId: any) {
    const topicIds = this.campaignForm.get("topicIds").value;

    if (topicIds.includes(topicId)) {
      let index = topicIds.findIndex((item) => item === topicId);
      topicIds.splice(index, 1);
    } else {
      topicIds.push(topicId);
    }

    this.campaignForm.get("topicIds").setValue(topicIds);
  }

  onChangeMediaAsset($event, formatId) {
    this.googleFormatMediaAssets = Object.assign(
      {},
      this.googleFormatMediaAssets
    );

    if (
      this.GOOGLE_DISPLAY_MEDIA_FORMAT_ID_LOGO === formatId ||
      $event.length === 1
    ) {
      this.googleFormatMediaAssets[formatId].mediaAssets = $event;
    } else {
      $event.forEach((googleFormatMediaAsset) => {
        this.googleFormatMediaAssets[
          googleFormatMediaAsset.formatId
        ].mediaAssets = [googleFormatMediaAsset.mediaAsset];
      });
    }
  }

  getInitLocations(items) {
    if (!items) {
      return [];
    }

    if (!this.isEdit && this.customer && !this.duplicate) {
      this.locations = this.customer.customerLocations;
    } else {
      this.locations = this.convertLocations(items.locations);
    }

    return this.locations;
  }

  convertLocations(locations) {
    let items = [];
    locations.forEach((location) => {
      items.push({
        radius: location.locationRadius,
        locationAddress: {
          latitude: location.locationLatitude,
          longitude: location.locationLongitude,
          address: location.locationStreet,
          city: location.locationCity,
          zip_code: location.locationZipCode,
          country: location.locationCountry,
          state: location.locationState,
        },
      });
    });

    return items;
  }

  getInitAgeGroupIds(items) {
    if (!items || !items.ageGroups) {
      return [];
    }

    const ageGroupIds = [];

    items.ageGroups.forEach((ageGroup) => {
      ageGroupIds.push(ageGroup.ageGroupId);
    });

    return ageGroupIds;
  }

  getInitImageInfo(items) {
    if (!items) {
      return [];
    }

    items.mediaAssets.forEach((mediaAsset) => {
      this.mediaAssetPreviewImages.push({
        googleDisplayMediaFormatId: mediaAsset.googleDisplayMediaFormatId,
        mediaAssetId: mediaAsset.mediaAssetId,
      });
      this.googleDisplayMediaFormatIds.push(
        mediaAsset.googleDisplayMediaFormatId
      );
    });

    return this.mediaAssetPreviewImages;
  }

  getInitDate(items) {
    const initDate = {
      startDate: null,
      endDate: null,
    };

    if (!items || this.duplicate) {
      let startDate = new Date();
      startDate.setDate(
        startDate.getDate() + this.START_DATE_DAYS_IN_FUTURE + 1
      );

      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setMilliseconds(0);
      startDate.setSeconds(0);
      initDate.startDate = startDate;

      let endDate = new Date();
      endDate.setDate(
        endDate.getDate() +
          this.START_DATE_DAYS_IN_FUTURE +
          this.MAX_DIFFERENCE_BETWEEN_TIMEPERIOD_IN_DAYS
      );
      endDate.setHours(0);
      endDate.setMinutes(0);
      endDate.setMilliseconds(0);
      endDate.setSeconds(0);
      initDate.endDate = endDate;
    } else {
      const startDate = new Date(items.start);
      let offsetStart = (startDate.getTimezoneOffset() * -1) / 60;

      const endDate = new Date(items.end);
      let offsetEnd = (endDate.getTimezoneOffset() * -1) / 60;

      initDate.startDate = new Date(items.start);
      initDate.startDate.setHours(initDate.startDate.getHours() + offsetStart);
      initDate.endDate = new Date(items.end);
      initDate.endDate.setHours(initDate.endDate.getHours() + offsetEnd);
    }

    return initDate;
  }

  onClickAgeGroupId(ageGroupId: any) {
    const selectedIds = this.campaignForm.get("campaignAgeGroupIds").value;

    if (
      selectedIds.includes(ageGroupId) &&
      this.campaignForm.get("campaignAgeGroupIds").value.length > 1
    ) {
      let index = selectedIds.findIndex((item) => item === ageGroupId);
      selectedIds.splice(index, 1);
    } else {
      selectedIds.push(ageGroupId);
    }

    this.campaignForm.get("campaignAgeGroupIds").setValue(selectedIds);
  }

  onToggleTargetGroup() {
    this.showTargetGroup = !this.showTargetGroup;
  }

  getManualBudgetImpressions(budget) {
    this.manualBudgeRaw = budget;

    if (this.manualBudgetGoal.goalMinimumBudget > budget) {
      this.manualBudgetImpressions = 0;
      return;
    }

    if (this.manualBudgetImpressionsSub) {
      this.manualBudgetImpressionsSub.unsubscribe();
    }

    this.manualBudgetImpressionsSub = this.crudService
      .GetGooglePerformanceByBudget(this.mediaPackageType, budget)
      .subscribe(
        (res) => {
          this.manualBudgetImpressions = res;
        },
        (err) => {
          this.snackbarService.show(err.error, "danger");
          this.manualBudgetImpressions = 0;
        }
      );
  }

  onDateChange($event) {
    this.campaignForm.controls["campaignStart"].updateValueAndValidity();
    this.campaignForm.controls["campaignEnd"].updateValueAndValidity();

    this.validateErrors();
  }

  focusPrice($event) {
    $event.target.value = CurrencyService.removeCurrencySymbol(
      $event.target.value
    );
  }

  blurPrice($event) {
    $event.target.value = CurrencyService.addCurrencySymbol(
      $event.target.value
    );
  }

  convertPrice($event) {
    let rawValue = CurrencyService.formatPriceToNumber($event.target.value);

    if (rawValue > this.MAX_MANUAL_BUDGET) {
      rawValue = this.MAX_MANUAL_BUDGET;
    } else if (isNaN(rawValue)) {
      rawValue = 0;
    }

    $event.target.value = CurrencyService.formatInputPrice(rawValue.toString());
    this.getManualBudgetImpressions(rawValue);
    this.setControlValidationError("manualBudget");
  }

  onClickManualGoalType(goalId: number) {
    const goal = this.mediaPackages.find(
      (mediaPackage) => mediaPackage.packageGoal.goalId === goalId
    );

    this.mediaPackageType = goalId;
    this.manualBudgetGoal = goal.packageGoal;

    this.campaignForm.get("campaignGoalId").setValue(goalId);
    this.campaignForm.get("manualBudget").setValidators(null);
    this.campaignForm.get("manualBudget").updateValueAndValidity();
    this.campaignForm.get("manualBudget").setValidators([
      ValidationService.priceNumber({
        min: this.manualBudgetGoal.goalMinimumBudget,
      }),
    ]);
    this.campaignForm.get("manualBudget").updateValueAndValidity();
    this.setControlValidationError("manualBudget");
    this.getManualBudgetImpressions(this.manualBudgeRaw);
  }

  onClickMediaPackageType(goalId: number) {
    const mediaPackage = this.mediaPackages.find(
      (item) => item.packageGoal.goalId === goalId
    );

    this.mediaPackageType = goalId;
    this.campaignForm.get("campaignGoalId").setValue(goalId);
    this.channelPackages = mediaPackage.channelPackages;
    this.selectedMediaPackage = mediaPackage;

    this.campaignForm
      .get("campaignPackageId")
      .setValue(this.channelPackages[0].packageId);
  }

  onClickAgeGroup(ageGroup: any) {
    this.campaignForm.get("AgeGroupId").setValue(ageGroup.ageGroupId);
    this.setFromAndToAge(ageGroup);
    this.updateAgeErrors();
  }

  switchMaterialType(type) {
    this.campaignForm.get("adMaterialType").setValue(type);
  }

  setFromAndToAge(ageGroup) {
    this.campaignForm.get("fromAge").setValue(ageGroup.fromAge);
    this.campaignForm.get("toAge").setValue(ageGroup.toAge);
  }

  onClickGender(genderId: number) {
    this.campaignForm.get("campaignGenderId").setValue(genderId);
  }

  onClickDevice(deviceId: number) {
    this.campaignForm.get("campaignDeviceId").setValue(deviceId);
  }

  changeShowCustomerApproval($event) {
    this.showCustomerApproval = $event.checked;

    if (!this.showCustomerApproval) {
      this.campaignForm.get("approvalCustomerEmail").clearValidators();
    } else {
      this.campaignForm
        .get("approvalCustomerEmail")
        .setValidators([ValidationService.emailValidator, Validators.required]);
    }

    this.campaignForm.get("approvalCustomerEmail").updateValueAndValidity();
  }

  validateAgeInput($event, name) {
    let value = $event.target.value;
    let isNumber = /^\d+$/.test(value);

    if (!isNumber) {
      let numberValue = value.replace(/\D/g, "");
      this.campaignForm.get(name).setValue(numberValue);
      return;
    }
  }

  onKeyUpFromAge($event) {
    this.validateAgeInput($event, "fromAge");
    this.updateAgeErrors();
  }

  onKeyUpToAge($event) {
    this.validateAgeInput($event, "toAge");
    this.updateAgeErrors();
  }

  updateAgeErrors() {
    this.markAgeControls();
    this.validateErrors();
  }

  validateErrors() {
    this.errors = this.getValidationErrors();
  }

  setControlValidationError(key) {
    this.campaignForm.controls[key].markAsTouched();
    const control = this.campaignForm.get(key);
    const controlErrors: ValidationErrors = control.errors;

    if (controlErrors != null) {
      let error = Object.keys(control.errors)[0];

      // Control has multiple errors - We want to show only one
      if (Object.keys(control.errors).length > 2) {
        // Remove first error
        const firstKey = Object.keys(control.errors)[0];
        delete control.errors[firstKey];

        // Select error
        for (const key of Object.keys(control.errors)) {
          if (key != "validatorValue") {
            error = key;
          }
        }
      }

      this.errors[key] = ValidationService.getValidatorErrorMessage(
        error,
        this.translate,
        control.errors.validatorValue || {}
      );
    } else {
      delete this.errors[key];
    }
  }

  validateUploadedImages() {
    let isValid = true;

    Object.keys(this.googleFormatMediaAssets).forEach((key) => {
      if (!this.googleFormatMediaAssets[key].mediaAssets.length) {
        isValid = false;
      }
    });

    return isValid;
  }

  getValidationErrors(): Object {
    const errors = {};

    if (!this.validateUploadedImages()) {
      errors["imageInfo"] =
        "Bitte lade für alle Mediendateien mindestens ein Werbemittel hoch oder erstelle ein Template mit unserem Online-Editor  ";
    }

    if (this.regions.length === 0) {
      errors["regions"] = "Bitte füge mindestens eine Region hinzu";
    }

    if (this.campaignForm.hasError("startDateLessThanEndDate")) {
      this.campaignForm.controls["campaignEnd"].setErrors({
        startDateLessThanEndDate: true,
      });
    }

    // FormGroup validation errors
    Object.keys(this.campaignForm.controls).forEach((key) => {
      const control = this.campaignForm.get(key);
      const controlErrors: ValidationErrors = control.errors;

      if (controlErrors != null) {
        let error = Object.keys(control.errors)[0];

        // Control has multiple errors - We want to show only one
        if (Object.keys(control.errors).length > 2) {
          // Remove first error
          const firstKey = Object.keys(control.errors)[0];
          delete control.errors[firstKey];

          // Select error
          for (const key of Object.keys(control.errors)) {
            if (key != "validatorValue") {
              error = key;
            }
          }
        }

        errors[key] = ValidationService.getValidatorErrorMessage(
          error,
          this.translate,
          control.errors.validatorValue || {}
        );
      }
    });

    return errors;
  }

  markAgeControls() {
    this.campaignForm.controls["toAge"].markAsTouched();
    this.campaignForm.controls["fromAge"].markAsTouched();
  }

  onClickBudgetPackage(packageNumber) {
    this.campaignForm.get("campaignPackageId").setValue(packageNumber);
  }

  setCampaignLocations() {
    let campaignLocations = [];

    this.regions.forEach((region, i) => {
      campaignLocations.push({
        campaignLocationRadiusId: region.radius.radiusId,
        locationLatitude: region.address.latitude,
        locationLongitude: region.address.longitude,
        locationStreet: region.address.address,
        locationStreetNumber: null,
        locationCity: region.address.city,
        locationZipCode: region.address.zip_code,
        locationCountry: region.address.country,
        locationState: region.address.state,
      });
    });

    this.campaignForm.get("campaignLocations").setValue(campaignLocations);
  }

  genImageInfo(mediaAssets: MPMediaAsset[]) {
    const imageInfo: any[] = [];

    mediaAssets.forEach((mediaAsset: MPMediaAsset) => {
      imageInfo.push({
        googleDisplayMediaFormatId: mediaAsset.googleDisplayMediaFormatId,
        mediaAssetId: mediaAsset.mediaAssetId,
      });
    });

    return imageInfo;
  }

  mergeMediaAssets(): MPMediaAsset[] {
    let mediaAssets: MPMediaAsset[] = [];

    Object.keys(this.googleFormatMediaAssets).forEach((key) => {
      mediaAssets = mediaAssets.concat(
        this.googleFormatMediaAssets[key].mediaAssets
      );
    });

    return mediaAssets;
  }

  async onSubmit() {
    this.setCampaignLocations();

    this.errors = this.getValidationErrors();

    if (this.campaignForm.invalid || Object.keys(this.errors).length) {
      this.snackbarService.show(
        "Bitte fülle alle erforderlichen Felder aus",
        "danger"
      );

      setTimeout(() => {
        let errorMessages = document.querySelector(".error-message");
        if (errorMessages) {
          errorMessages.scrollIntoView();
        }
      });

      return;
    }

    this.appLoader.open();

    let value = this.campaignForm.getRawValue();

    const mediaAssets: MPMediaAsset[] = this.mergeMediaAssets();

    const mpMediaAssetUploader: MPMediaAssetUploader = new MPMediaAssetUploader(
      mediaAssets,
      this.crudService
    );

    let uploadError: boolean = false;

    await mpMediaAssetUploader
      .upload()
      .then((mediaAssets: MPMediaAsset[]) => {
        value.imageInfo = this.genImageInfo(mediaAssets);
      })
      .catch((e) => {
        uploadError = true;
        this.snackbarService.show(e.error, "danger");
      });

    if (uploadError) {
      document.querySelector(".main-content-wrap").scrollTo(0, 0);
      this.appLoader.close();
      return;
    }

    value.imageInfo = this.genImageInfo(mediaAssets);

    const startDate = new Date(value.campaignStart);
    let offset = (startDate.getTimezoneOffset() * -1) / 60;

    const endDate = new Date(value.campaignEnd);
    let offset_enddate = (endDate.getTimezoneOffset() * -1) / 60;

    const body = {
      campaignName: value.campaignName,
      campaignStart: value.campaignStart,
      campaignEnd: value.campaignEnd,
      topicIds: value.topicIds,
      channelId: this.campaignChannelId,
      campaignPackageId: value.campaignPackageId,
      manualBudget: value.manualBudget,
      campaignGoalId: value.campaignGoalId,
      campaignLocations: value.campaignLocations,
      imageInfo: value.imageInfo,
      customerId: this.customerId,
      headlines: [value.headline],
      longHeadline: value.longHeadline,
      descriptions: [value.description],
      promoText: value.promoText,
      pricePrefix: value.pricePrefix,
      callToActionId: value.callToActionId,
      businessName: value.businessName,
      finalUrl: value.protocol + value.finalUrl,
      campaignAgeGroupIds: value.campaignAgeGroupIds,
      campaignDeviceId: value.campaignDeviceId,
      campaignGenderId: value.campaignGenderId,
      timezone: offset,
      timezone_enddate: offset_enddate,
    };

    if (this.isEdit) {
      await this.crudService
        .editGoogleDisplayCampaign(body, this.data.googleDisplayCampaignId)
        .toPromise()
        .then((res) => {
          if (res) {
            this.snackbarService.show(
              "Deine Marketing-Kampagne wurde erfolgreich bearbeitet"
            );

            this.router.navigateByUrl("/mapAds/marketing-portal/campaigns");
          }
        })
        .catch((e) => {
          this.snackbarService.show(e.error, "danger");
          this.appLoader.close();
        });

      this.appLoader.close();
    } else {
      await this.crudService
        .createGoogleDisplayCampaign(body)
        .toPromise()
        .then(async (res) => {
          if (res) {
            this.snackbarService.show(
              "Deine Marketing-Kampagne wurde erfolgreich erstellt"
            );

            if (this.showCustomerApproval) {
              const googleCampaignId = Number(res);

              let googleDisplayCampaign = null;

              await this.crudService
                .getGoogleDisplayCampaign(googleCampaignId)
                .toPromise()
                .then((res) => {
                  googleDisplayCampaign = res;
                })
                .catch((e) => {
                  this.snackbarService.show(e.error, "danger");
                });

              const body = {
                userMessage: value.approvalMessage,
                optionalMail: value.approvalCustomerEmail,
              };

              await this.crudService
                .requestCustomerApproval(
                  googleDisplayCampaign.mpCampaignId,
                  body
                )
                .toPromise()
                .then((res) => {
                  this.snackbarService.show(
                    "Deine Freigabeanfrage wurde erfolgreich gesendet",
                    "success"
                  );
                })
                .catch((e) => {
                  this.snackbarService.show(e.error, "danger");
                });
            }

            let redirectUrl =
              "/mapAds/marketing-portal/checkout/campaign/" +
              this.campaignChannelId +
              "/" +
              res +
              "?checkoutNeeded=" +
              this.googleSettings.CheckoutNeeded;
            this.router.navigateByUrl(redirectUrl);
          }
        })
        .catch((e) => {
          this.snackbarService.show(e.error, "danger");
          this.appLoader.close();
        });

      this.appLoader.close();
    }
  }
}
