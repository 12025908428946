import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-import-marketing-portal-editor-file-dialog",
  templateUrl: "./import-marketing-portal-editor-file-dialog.component.html",
  styleUrls: ["./import-marketing-portal-editor-file-dialog.component.scss"],
})
export class ImportMarketingPortalEditorFileDialogComponent implements OnInit {
  public columns: number = 3;
  constructor(
    public dialogRef: MatDialogRef<ImportMarketingPortalEditorFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data && this.data.hasOwnProperty("hideOptions")) {
      this.columns -= this.data.hideOptions.length;
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  onApply(type) {
    this.dialogRef.close({ type });
  }
}
