import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  NgForm,
  ValidationErrors,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { ValidationService } from "app/shared/services/validation.service";

@Component({
  selector: "send-customer-approval-request-form",
  templateUrl: "./send-customer-approval-request-form.component.html",
  styleUrls: ["./send-customer-approval-request-form.component.scss"],
})
export class SendCustomerApprovalRequestFormComponent implements OnInit {
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  @Input() customerEmail: string;
  @ViewChild("form") form: NgForm;
  public formGroup: FormGroup;
  public errors = {};
  constructor(
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      email: [
        this.customerEmail ? this.customerEmail : "",
        ValidationService.emailValidator,
      ],
      message: "",
    });
  }

  getValidationErrors(): Object {
    const errors = {};

    // FormGroup validation errors
    Object.keys(this.formGroup.controls).forEach((key) => {
      const control = this.formGroup.get(key);
      const controlErrors: ValidationErrors = control.errors;

      if (controlErrors != null) {
        let error = Object.keys(control.errors)[0];

        // Control has multiple errors - We want to show only one
        if (Object.keys(control.errors).length > 2) {
          // Remove first error
          const firstKey = Object.keys(control.errors)[0];
          delete control.errors[firstKey];

          // Select error
          for (const key of Object.keys(control.errors)) {
            if (key != "validatorValue") {
              error = key;
            }
          }
        }

        errors[key] = ValidationService.getValidatorErrorMessage(
          error,
          this.translate,
          control.errors.validatorValue || {}
        );
      }
    });

    return errors;
  }

  markFormControls(controls) {
    for (const key of Object.keys(controls)) {
      controls[key].markAsTouched();
      if (controls[key].hasOwnProperty("controls")) {
        this.markFormControls(controls[key].controls);
      }
    }
  }

  onSubmit() {
    this.markFormControls(this.formGroup.controls);
    this.errors = this.getValidationErrors();

    if (this.formGroup.invalid || Object.keys(this.errors).length) {
      this.snackbarService.show(
        "Bitte fülle alle erforderlichen Felder aus",
        "danger"
      );

      return;
    }

    const value = this.formGroup.getRawValue();

    this.submitted.emit(value);
  }
}
