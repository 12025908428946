import { MPCustomerLocation } from "./MPCustomerLocation";
import { MPCustomerLocationList } from "./MPCustomerLocationList";

export class MPCustomer {
  public customerId: number;
  public customerName: string;
  public customerDescription: string;
  public customerLogoUrl: string;
  public customerLogoThumbnailUrl: string;
  public customerCorporateColor: any;
  public customerMail: string;
  public customerPhone: string;
  public customerHomepage: string;
  public customerIsDeleted: string;
  public customerCreatedOn: Date;
  public customerLocations: MPCustomerLocation[];

  constructor(
    customerId: number = null,
    customerName: string = null,
    customerDescription: string = null,
    customerLogoUrl: string = null,
    customerLogoThumbnailUrl: string = null,
    customerCorporateColor: any = null,
    customerMail: string = null,
    customerPhone: string = null,
    customerHomepage: string = null,
    customerIsDeleted: string = null,
    customerCreatedOn: Date = null,
    customerLocations: MPCustomerLocation[] = null
  ) {
    this.customerId = customerId;
    this.customerName = customerName;
    this.customerDescription = customerDescription;
    this.customerLogoUrl = customerLogoUrl;
    this.customerLogoThumbnailUrl = customerLogoThumbnailUrl;
    this.customerCorporateColor = customerCorporateColor;
    this.customerMail = customerMail;
    this.customerPhone = customerPhone;
    this.customerHomepage = customerHomepage;
    this.customerIsDeleted = customerIsDeleted;
    this.customerCreatedOn = customerCreatedOn;
    this.customerLocations = customerLocations;
  }

  deserialize(serializedObj: object): MPCustomer {
    if (!serializedObj) {
      return null;
    }

    this.customerId = serializedObj["customerId"];
    this.customerName = serializedObj["customerName"];
    this.customerDescription = serializedObj["customerDescription"];
    this.customerLogoUrl = serializedObj["customerLogoUrl"];
    this.customerLogoThumbnailUrl = serializedObj["customerLogoThumbnailUrl"];
    this.customerCorporateColor = serializedObj["customerCorporateColor"];
    this.customerMail = serializedObj["customerMail"];
    this.customerPhone = serializedObj["customerPhone"];
    this.customerHomepage = serializedObj["customerHomepage"];
    this.customerIsDeleted = serializedObj["customerIsDeleted"];
    this.customerCreatedOn = serializedObj["customerCreatedOn"];
    this.customerLocations = new MPCustomerLocationList().deserializeList(
      serializedObj["customerLocations"]
    );

    return this;
  }
}
