import { Component, Input, OnInit } from "@angular/core";
import { YTCampaign } from "app/model/marketing-portal/YTCampaign";

@Component({
  selector: "marketing-portal-ytcampaign-overview",
  templateUrl: "./marketing-portal-ytcampaign-overview.component.html",
  styleUrls: ["./marketing-portal-ytcampaign-overview.component.scss"],
})
export class MarketingPortalYtcampaignOverviewComponent implements OnInit {
  @Input() youtubeCampaign: YTCampaign;
  constructor() {}

  ngOnInit() {}
}
