import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MPCampaignStatus } from "app/model/marketing-portal/MPCampaignStatus";
import { MPChannel } from "app/model/marketing-portal/MPChannel";

@Component({
  selector: "campaign-overview-status-bar",
  templateUrl: "./campaign-overview-status-bar.component.html",
  styleUrls: ["./campaign-overview-status-bar.component.scss"],
})
export class CampaignOverviewStatusBarComponent implements OnInit {
  @Input() status: any;
  @Input() customerApprovalOpen: boolean;
  @Input() paid: boolean;
  @Input() channelId: number;
  @Input() budget: number;
  @Input() package: any;
  @Input() mpCampaignId: number;
  @Input() childCampaignId: number;
  public isPayable: boolean;
  public canBeBoosted: boolean;
  public isBoosted: boolean;
  public isPost: boolean;
  constructor(private router: Router) {}

  ngOnInit() {
    this.setIsPost();
    this.setIsPayable();
    this.setCanBeBoosted();
    this.setIsBoosted();
  }

  setIsPayable() {
    this.isPayable =
      this.status.statusId === MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_DRAFT ||
      this.status.statusId ===
        MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_CUSTOMER_CHECK ||
      this.status.statusId ===
        MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_CUSTOMER_DECLINED ||
      this.status.statusId ===
        MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_CUSTOMER_DECLINED;
  }

  setIsPost() {
    this.isPost =
      this.channelId === MPChannel.CHANNEL_ID_FACEBOOK_INSTAGRAM_POST;
  }

  hasBudget() {
    return this.budget || this.package;
  }

  setCanBeBoosted() {
    this.canBeBoosted =
      this.status.statusId ===
        MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_SCHEDULED ||
      this.status.statusId === MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_ACTIVE ||
      this.status.statusId ===
        MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_PUBLISHING ||
      this.status.statusId ===
        MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_CUSTOMER_CHECK ||
      this.status.statusId ===
        MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_CUSTOMER_DECLINED ||
      this.status.statusId ===
        MPCampaignStatus.MP_CAMPAIGN_STATUS_ID_CUSTOMER_DECLINED;
  }

  setIsBoosted() {
    this.isBoosted = this.isPost && this.hasBudget();
  }

  goToPayment() {
    this.router.navigateByUrl(
      "/mapAds/marketing-portal/checkout/campaign/" +
        this.channelId +
        "/" +
        this.childCampaignId +
        "?checkoutNeeded=true"
    );
  }
}
