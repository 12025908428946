import { CrudService } from "app/views/mapAds/crud.service";
import { MPMediaAsset } from "./MPMediaAsset";

export class MPMediaAssetUploader {
  public mediaAssets: MPMediaAsset[];
  constructor(mediaAssets: MPMediaAsset[], private crudService: CrudService) {
    this.mediaAssets = mediaAssets;
  }

  uploadNeeded() {
    let uploadNeeded = false;

    this.mediaAssets.forEach((mediaAsset) => {
      if (!mediaAsset.mediaAssetId) {
        uploadNeeded = true;
      }
    });
    return uploadNeeded;
  }

  upload() {
    return new Promise((resolve, reject) => {
      if (!this.uploadNeeded()) {
        resolve(this.mediaAssets);
        return;
      }

      const promises: Promise<any>[] = [];
      const mapping = [];

      this.mediaAssets.forEach((mediaAsset: MPMediaAsset, index) => {
        if (!mediaAsset.mediaAssetId) {
          const file = mediaAsset.toFile();

          const promise = mediaAsset.isImage
            ? this.crudService.uploadCampaignImageAssets(file).toPromise()
            : this.crudService
                .uploadCampaignVideoAssets(
                  file,
                  mediaAsset.width,
                  mediaAsset.height
                )
                .toPromise();
          promises.push(promise);

          mapping.push({
            mediaAssetIndex: index,
            promiseIndex: promises.length - 1,
          });
        }
      });

      Promise.all(promises)
        .then((res) => {
          mapping.forEach((item) => {
            const { mediaAssetIndex, promiseIndex } = item;
            const mediaAssetId: number =
              res[promiseIndex].body.uploadInformation[0].Id;

            this.mediaAssets[mediaAssetIndex].setId(mediaAssetId);
          });

          resolve(this.mediaAssets);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
}
