export class UploadedFile {
  id: number;
  name: string;
  type: string;
  size: number;
  megaBytes: string;
  data?: any;
  imageUrl?: string;
  url?: string;
  format: string;
  width?: number;
  height?: number;
}
