import { MPAgeGroup } from "./MPAgeGroup";

export class MPAgeGroupList {
  public mpAgeGroups: MPAgeGroup[];

  constructor() {}

  public deserializeList(serializedObj: MPAgeGroup[]): MPAgeGroup[] {
    if (!serializedObj) {
      return null;
    }

    return serializedObj.map((x: MPAgeGroup) =>
      new MPAgeGroup().deserialize(x)
    );
  }
}
