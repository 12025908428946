import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Base64 } from "app/model/Base64";
import { MPLibaryMediaAsset } from "app/model/marketing-portal/MPLibaryMediaAsset";
import { dataURLtoFile } from "app/shared/utils/blob.utils";

@Component({
  selector: "app-media-asset-search-dialog",
  templateUrl: "./media-asset-search-dialog.component.html",
  styleUrls: ["./media-asset-search-dialog.component.scss"],
})
export class MediaAssetSearchDialogComponent implements OnInit {
  public selectedImage: MPLibaryMediaAsset = null;
  public customerId: number;
  public customerLogo: number;
  public isLoading: boolean;
  public channelId: number;
  public googleDisplayMediaFormatId: number;
  public hideAdMaterials: boolean;
  public storeId: number;

  constructor(
    public dialogRef: MatDialogRef<MediaAssetSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.setStoreId();

    this.customerId = this.data.customerId;
    this.customerLogo = this.data.customerLogo;
    this.channelId = this.data.channelId;
    this.googleDisplayMediaFormatId = this.data.googleDisplayMediaFormatId;
    this.hideAdMaterials = this.data.hideAdMaterials;
  }

  setStoreId(): void {
    this.storeId = window.localStorage["storeId"];
  }

  onClose() {
    this.dialogRef.close();
  }

  onApply() {
    if (this.selectedImage.TemplateId) {
      this.dialogRef.close({ mpLibaryMediaAsset: this.selectedImage });
      return;
    }

    let url =
      this.isLogo() || this.isUser()
        ? this.selectedImage.ImageThumbnailURL
        : this.selectedImage.ImageURL;

    Base64.getBase64FromImageUrl(
      url,
      this.selectedImage.ImageWidth,
      this.selectedImage.ImageHeight
    ).then((result) => {
      const file = dataURLtoFile(result, this.selectedImage.ImageId + ".jpg");
      this.isLoading = false;
      this.dialogRef.close({ file });
    });
  }

  isLogo(): boolean {
    return this.selectedImage.ImageId === this.customerLogo;
  }

  isUser(): boolean {
    return this.selectedImage.ImageId === this.storeId;
  }

  selectImage(image) {
    if (!this.selectedImage) {
      this.selectedImage = image;
      return;
    }

    this.selectedImage =
      this.selectedImage.ImageId != image.ImageId ? image : null;
  }
}
