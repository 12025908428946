import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material";
import { VideoEditorTemplateDetailsDialogComponent } from "../../dialogs/video-editor-template-details-dialog/video-editor-template-details-dialog.component";

@Component({
  selector: "marketing-portal-templates-card",
  templateUrl: "./marketing-portal-templates-card.component.html",
  styleUrls: ["./marketing-portal-templates-card.component.scss"],
})
export class MarketingPortalTemplatesCardComponent implements OnInit {
  @ViewChild("videoPlayer") videoPlayer: ElementRef;
  @Output() onSelectTemplate: EventEmitter<any> = new EventEmitter();
  @Input() marketingPortalTemplate;
  @Input() showCategory: boolean = true;
  @Input() isVideo?: boolean = false;
  @Input() width?: number = 240;
  public isPlayingVideo: boolean = false;
  public previewImageUrl: string;
  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    this.setPreviewImageUrl();
  }

  setPreviewImageUrl() {
    this.previewImageUrl = this.marketingPortalTemplate.hasOwnProperty(
      "singleTemplates"
    )
      ? this.marketingPortalTemplate.singleTemplates[0].imageUrlThumbnail
      : this.marketingPortalTemplate.templateImageUrlThumbnail;
  }

  openDetailsWindow($event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.dialog.open(VideoEditorTemplateDetailsDialogComponent, {
      maxWidth: "min-content",
      width: "auto",
      maxHeight: "95vh",
      panelClass: "no-spacing",
      disableClose: true,
      data: {
        template: this.marketingPortalTemplate,
      },
    });
  }

  enter() {
    if (!this.marketingPortalTemplate.hasOwnProperty("templateVideoUrl")) {
      return;
    }
    this.isPlayingVideo = true;
    this.videoPlayer.nativeElement.muted = true;
    this.videoPlayer.nativeElement.play();
  }

  leave() {
    if (!this.marketingPortalTemplate.hasOwnProperty("templateVideoUrl")) {
      return;
    }
    this.isPlayingVideo = false;
    this.videoPlayer.nativeElement.pause();
    this.videoPlayer.nativeElement.currentTime = 0;
  }
}
