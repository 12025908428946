import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { LayoutService } from "app/shared/services/layout.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { environment } from "environments/environment";

@Component({
  selector: "marketing-portal-editor-template-configurator",
  templateUrl: "./marketing-portal-editor-template-configurator.component.html",
  styleUrls: ["./marketing-portal-editor-template-configurator.component.scss"],
})
export class MarketingPortalEditorTemplateConfiguratorComponent
  implements OnInit
{
  @Output() onApply: EventEmitter<any> = new EventEmitter();
  @Input() template;
  @Input() templates;
  @Input() customerId;
  @Input() customerLogo;
  @Input() channelId;
  @Input() isOpenMenu: boolean;
  @Input() formatMediaAssets: any;
  public isSet: boolean;
  public previewImageUrl: string;
  public updatedTemplateConfig: any;
  public templateMediaAssets: any[] = [];
  public previewFormatId: number;
  public previewImageId: number;
  public inputChanged: boolean = false;
  readonly environment = environment;

  constructor(
    private appLoader: AppLoaderService,
    private crudService: CrudService,
    private snackbarService: SnackbarService,
    public layout: LayoutService
  ) {}

  ngOnInit() {
    this.setIsSet();
    this.setSetTemplateProperties();
    this.setInitMediaAssets();
  }

  setPreviewMediaIds() {
    this.previewFormatId = this.templateMediaAssets[0].formatId;
    this.previewImageId = this.templateMediaAssets[0].imageId;
  }

  onChangePreviewFormat(formatId: number) {
    this.previewFormatId = formatId;
    this.previewImageId = this.templateMediaAssets.find(
      (asset) => asset.formatId === formatId
    ).imageId;
  }

  setSetTemplateProperties() {
    if (!this.isSet || this.template.hasOwnProperty("singleTemplates")) {
      return;
    }

    const setTemplate = this.getDefaultSetTemplate(this.template.templateSetId);

    this.template.singleTemplates = setTemplate.singleTemplates;
    this.template.templateSetId = setTemplate.templateSetId;

    this.setSetTemplateInitImages();
  }

  setSetTemplateInitImages() {
    this.template.singleTemplates.forEach((singleTemplate, index) => {
      if (this.formatMediaAssets[singleTemplate.formatId].mediaAssets.length) {
        this.template.singleTemplates[index].imageId =
          this.formatMediaAssets[
            singleTemplate.formatId
          ].mediaAssets[0].mediaAssetId;
      }
    });
  }

  isDefaultSetTemplate(imageId: number): boolean {
    let found: boolean = false;

    this.templates.forEach((template) => {
      template.singleTemplates.forEach((singleTemplate) => {
        if (singleTemplate.imageId === imageId) {
          found = true;
        }
      });
    });

    return found;
  }

  getDefaultSetTemplate(templateSetId: number) {
    let setTemplate = null;

    this.templates.forEach((template) => {
      if (template.templateSetId === templateSetId) {
        setTemplate = template;
      }
    });

    return setTemplate;
  }

  setInitMediaAssets() {
    if (this.isSet) {
      this.template.singleTemplates.forEach((singleTemplate) => {
        this.templateMediaAssets.push({
          imageId: singleTemplate.imageId,
          formatDisplayName: singleTemplate.formatDisplayName,
          formatId: singleTemplate.formatId,
        });
      });
    } else {
      this.templateMediaAssets.push({
        imageId: this.template.templateImageId,
      });
    }

    this.setPreviewMediaIds();
  }

  setIsSet() {
    this.isSet =
      this.template.hasOwnProperty("templateSetId") &&
      this.template.templateSetId;
  }

  setUpdatedTemplateConfig(templateConfig) {
    this.updatedTemplateConfig = templateConfig;
  }

  setInputChanged(inputChanged) {
    this.inputChanged = inputChanged;
  }

  async generateImage(apply = false) {
    this.appLoader.open();
    this.setInputChanged(false);

    if (this.isSet) {
      const data = {
        templateSetId: this.template.templateSetId,
        template: this.template.templateExternalId,
        hasGlobalColor: false,
        globalColorCode: this.template.templateConfigModel.globalColorCode,
        modifications: this.updatedTemplateConfig,
        singleTemplates: this.template.singleTemplates,
      };

      await this.crudService
        .createTemplateBasedImageSet(this.channelId, data)
        .toPromise()
        .then((res: any) => {
          if (!res) {
            this.snackbarService.show(
              "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut oder nimm Kontakt mit uns auf!",
              "danger"
            );
            return;
          }

          this.templateMediaAssets = res;
          this.setPreviewMediaIds();

          if (apply) {
            this.onApply.emit({
              templateMediaAssets: this.templateMediaAssets,
              data,
            });
          }
        })
        .catch((e) => {
          this.snackbarService.show(e.error, "danger");
        });
    } else {
      const data = {
        templateId: this.template.templateId,
        template: this.template.templateExternalId,
        hasGlobalColor: false,
        globalColorCode: this.template.templateConfigModel.globalColorCode,
        modifications: this.updatedTemplateConfig,
      };

      await this.crudService
        .createTemplateBasedImage(this.channelId, data)
        .toPromise()
        .then((res: any) => {
          if (!res) {
            this.snackbarService.show(
              "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut oder nimm Kontakt mit uns auf!",
              "danger"
            );
            return;
          }
          this.templateMediaAssets = res;
          this.setPreviewMediaIds();

          if (apply) {
            this.onApply.emit({
              templateMediaAssets: this.templateMediaAssets,
              data,
            });
          }
        })
        .catch((e) => {
          this.snackbarService.show(e.error, "danger");
        });
    }

    this.appLoader.close();
  }

  isInitialPreviewImage() {
    // TODO:
    return false;
    // return this.imageId == this.template.templateImageId;
  }

  onApplyTemplateImage() {
    if (this.inputChanged || this.isInitialPreviewImage()) {
      this.generateImage(true);
    } else {
      const data = {
        template: this.template.templateExternalId,
        hasGlobalColor: false,
        globalColorCode: this.template.templateConfigModel.globalColorCode,
        modifications: this.template.templateConfigModel.modifications,
      };

      if (this.isSet) {
        data["templateSetId"] = this.template.templateSetId;
      } else {
        data["templateId"] = this.template.templateId;
      }

      this.onApply.emit({
        templateMediaAssets: this.templateMediaAssets,
        data,
      });
    }
  }
}
