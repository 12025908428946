import {
  Component,
  Input,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "campaign-youtube-preview",
  templateUrl: "./campaign-youtube-preview.component.html",
  styleUrls: ["./campaign-youtube-preview.component.scss"],
})
export class CampaignYoutubePreviewComponent implements OnInit {
  @Input() mediaAsset: any;
  @Input() headline: string = "[Überschrift]";
  @Input() url: string = "[Homepage]";
  @Input() callToActionText: string = "[Call-To-Action]";
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {}
}
