import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MPFontFamily } from "app/model/marketing-portal/MPFontFamily";
import { MPFontWeight } from "app/model/marketing-portal/MPFontWeight";
import { MPSound } from "app/model/marketing-portal/MPSound";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { environment } from "environments/environment";

@Component({
  selector: "marketing-portal-video-editor-template-configurator-mobile",
  templateUrl:
    "./marketing-portal-video-editor-template-configurator-mobile.component.html",
  styleUrls: [
    "./marketing-portal-video-editor-template-configurator-mobile.component.scss",
  ],
})
export class MarketingPortalVideoEditorTemplateConfiguratorMobileComponent
  implements OnInit
{
  @Output() onApply: EventEmitter<any> = new EventEmitter();
  @Input() template: any;
  @Input() customerId: number;
  @Input() customerLogo: string;
  @Input() channelId: number;
  @Input() isOpenMenu: boolean;
  @Input() fontFamilies: MPFontFamily[];
  @Input() fontWeights: MPFontWeight[];
  @Input() sounds: MPSound[];
  public selectedSlide: any;
  public editedSlideIds: number[] = [];
  public updatedSlideIds: number[] = [];
  public currentSlideIndex: number;
  public inputChanged: boolean = false;
  public type: number = 0;
  readonly environment = environment;
  constructor(
    private appLoader: AppLoaderService,
    private crudService: CrudService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.setInitSelectedSlide();
  }

  updateSlideModification($event) {}

  updateGlobalModification($event) {}

  markSlideAsEdited(slideId: number = null) {
    if (!slideId) {
      slideId = this.currentSlideIndex;
    }

    if (!this.editedSlideIds.includes(slideId)) {
      this.editedSlideIds.push(slideId);
    }
  }

  setGlobalModificationValues(key: string) {
    const globalModification = this.getGlobalModificationByFormKey(key);
    const globalModificationElement = globalModification.elements[0];

    this.template.templateConfigModel.slides.forEach((slide, slideIndex) => {
      slide.modifications.forEach((modification, modificationIndex) => {
        if (
          this.hasElements(modification) &&
          this.hasGlobalName(globalModification.name, modification)
        ) {
          modification.elements.forEach((element, elementIndex) => {
            if (
              element.type.toLowerCase() ===
              globalModificationElement.type.toLowerCase()
            ) {
              this.template.templateConfigModel.slides[
                slideIndex
              ].modifications[modificationIndex].elements[elementIndex].value =
                globalModificationElement.value;

              this.markSlideAsEdited(slideIndex);

              this.template.templateConfigModel = Object.assign(
                {},
                this.template.templateConfigModel
              );
            }
          });
        } else if (this.hasChildren(modification)) {
          modification.children.forEach((child, childIndex) => {
            if (this.hasGlobalName(globalModification.name, child)) {
              child.elements.forEach((element, elementIndex) => {
                if (
                  element.type.toLowerCase() ===
                  globalModificationElement.type.toLowerCase()
                ) {
                  this.template.templateConfigModel.slides[
                    slideIndex
                  ].modifications[modificationIndex].children[
                    childIndex
                  ].elements[elementIndex].value =
                    globalModificationElement.value;

                  this.markSlideAsEdited(slideIndex);

                  this.template.templateConfigModel = Object.assign(
                    {},
                    this.template.templateConfigModel
                  );
                }
              });
            }
          });
        }
      });
    });
  }

  hasGlobalName(name: string, modification: any): boolean {
    if (!this.hasGlobalNamesProperty(modification)) {
      return false;
    }

    if (modification) return modification.globalNames.includes(name);
  }

  getGlobalModificationByFormKey(key: string) {
    let globalModification = null;

    this.template.templateConfigModel.globalModifications.forEach(
      (modification) => {
        if (this.hasElements(modification)) {
          modification.elements.forEach((element) => {
            let label = modification.name + "_" + element.type;
            if (key === label) {
              globalModification = modification;
            }
          });
        } else if (this.hasChildren(modification)) {
          modification.children.forEach((child) => {
            child.elements.forEach((element) => {
              let label = child.name + "_" + element.type;
              if (key === label) {
                globalModification = child;
              }
            });
          });
        }
      }
    );

    return globalModification;
  }

  hasGlobalNamesProperty(modification): boolean {
    return (
      modification.hasOwnProperty("globalNames") && modification.globalNames
    );
  }

  hasElements(modification: string): boolean {
    return modification.hasOwnProperty("elements") && modification["elements"];
  }

  hasChildren(modification: string): boolean {
    return modification.hasOwnProperty("children") && modification["children"];
  }

  async generateImage() {
    this.appLoader.open();

    const slides = this.template.templateConfigModel.slides.filter(
      (slide, index) => this.editedSlideIds.includes(index)
    );

    let template = JSON.parse(JSON.stringify(this.template));
    template.templateConfigModel.slides = slides;

    const data = {
      templateConfigModel: template.templateConfigModel,
      templateId: template.templateId,
    };

    await this.crudService
      .createPreviewImageForSlides(data)
      .toPromise()
      .then((res: any[]) => {
        this.updatePreviewImages(res);
        this.updatedSlideIds = this.editedSlideIds;
        this.editedSlideIds = [];
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
        this.setInputChanged(true);
      });

    this.appLoader.close();
  }

  async onApplyTemplateVideo() {
    this.appLoader.open();

    const data = {
      templateId: this.template.templateId,
      templateConfigModel: this.template.templateConfigModel,
    };

    await this.crudService
      .createTemplateBasedVideo(data)
      .toPromise()
      .then((res) => {
        this.onApply.emit({
          mediaAssetId: res,
          templateId: this.template.templateId,
          templateName: this.template.templateName,
        });
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.appLoader.close();
  }

  extractSlideIds(slides: any[]) {
    const ids = [];
    slides.forEach((slide) => {
      ids.push(slide.slideId);
    });
    return ids;
  }

  updatePreviewImages(previewImages: any[]) {
    previewImages.forEach((previewImage) => {
      const index = this.template.templateConfigModel.slides.findIndex(
        (slide) => slide.slideId === previewImage.slideId
      );

      const mediaAssetId = previewImage.mediaAssetId;

      this.template.templateConfigModel.slides[index].imageId = mediaAssetId;
      this.template.templateConfigModel.slides[index].imageUrl =
        environment.imageServerUrl +
        "api/Public/GetMediaAsset?id=" +
        mediaAssetId;
      this.template.templateConfigModel.slides[index].imageUrlThumbnail =
        environment.imageServerUrl +
        "api/Public/GetMediaAsset?id=" +
        mediaAssetId +
        "&width=300&height=300";
    });
  }

  setInputChanged(inputChanged) {
    this.inputChanged = inputChanged;
  }

  setInitSelectedSlide() {
    this.setSelectedSlide(this.template.templateConfigModel.slides[0].slideId);
  }

  setSelectedSlide(slideId: number) {
    const index = this.getSlideIndex(slideId);

    this.selectedSlide = this.template.templateConfigModel.slides[index];
    this.currentSlideIndex = index;
    this.removeUpdatedSlideId(index);
  }

  removeUpdatedSlideId(indexValue) {
    const index = this.updatedSlideIds.indexOf(indexValue);

    if (index != -1) {
      this.updatedSlideIds.splice(index, 1);
    }
  }

  getCurrentSlideIndex() {
    return this.getSlideIndex(this.selectedSlide.slideId);
  }

  getSlideIndex(slideId: number) {
    return this.template.templateConfigModel.slides.findIndex(
      (slide) => slide.slideId === slideId
    );
  }

  onPrevSlide() {
    const slides = this.template.templateConfigModel.slides;

    if (this.currentSlideIndex === 0) {
      return;
    }

    const prevSlide = slides[this.currentSlideIndex - 1];
    this.setSelectedSlide(prevSlide.slideId);
  }

  onNextSlide() {
    const slides = this.template.templateConfigModel.slides;

    if (this.currentSlideIndex === slides.length - 1) {
      return;
    }

    const prevSlide = slides[this.currentSlideIndex + 1];
    this.setSelectedSlide(prevSlide.slideId);
  }
}
