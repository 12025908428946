import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { environment } from "environments/environment";

@Component({
  selector: "marketing-portal-editor-configurator-toolset-item",
  templateUrl:
    "./marketing-portal-editor-configurator-toolset-item.component.html",
  styleUrls: [
    "./marketing-portal-editor-configurator-toolset-item.component.scss",
  ],
})
export class MarketingPortalEditorConfiguratorToolsetItemComponent
  implements OnInit
{
  @Output() onImportImage: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteImage: EventEmitter<any> = new EventEmitter();
  @Input() itemForm: FormGroup;
  @Input() modification: any;
  @Input() errors: any;
  @Input() uploadedImageIds: any;
  public isOpen: boolean = true;
  public readonly environment = environment;
  public hexColorInput: string;
  constructor() {}

  ngOnInit() {}

  onImportImageAction(modification, type) {
    const name = modification.name + "_" + type;
    const { width, height } = modification;

    this.onImportImage.emit({ name, width, height });
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  isValidHexCode(value) {
    const reg = /^#([0-9a-f]{3}){1,2}$/i;
    return reg.test(value);
  }

  onChangeHexColor($event, name) {
    let value = $event.target.value;

    if (value.charAt(0) != "#") {
      value = "#" + value;
    }

    let isValid = this.isValidHexCode(value);

    if (isValid) {
      this.itemForm.get(name).setValue(value);
    }
  }
}
