export class CurrencyService {
  public static config = {
    EUR: {
      name: "Euro",
      iso: "EUR",
      symbol: "€",
      symbolPosition: "right",
      delimiters: {
        thousand: ".",
        decimal: ",",
      },
      numberCode: "de-DE",
    },
    EURAT: {
      name: "Euro",
      iso: "EUR",
      symbol: "€",
      symbolPosition: "right",
      delimiters: {
        thousand: ".",
        decimal: ",",
      },
      numberCode: "de-AT",
    },
    CHF: {
      name: "Swiss Franc",
      iso: "CHF",
      symbol: "CHF",
      symbolPosition: "right",
      delimiters: {
        thousand: "’",
        decimal: ".",
      },
      numberCode: "de-CH",
    },
    USD: {
      name: "United States dollar",
      iso: "USD",
      symbol: "$",
      symbolPosition: "left",
      delimiters: {
        thousand: ",",
        decimal: ".",
      },
      numberCode: "en-US",
    },
    GBP: {
      name: "Pound sterling",
      iso: "GBP",
      symbol: "£",
      symbolPosition: "left",
      delimiters: {
        thousand: ",",
        decimal: ".",
      },
      numberCode: "en-GB",
    },
  };

  public static getCurrencyByCountryCode(countryCode) {
    if (!countryCode) {
      countryCode = "de-DE"; //fallback
    }

    let currencyCode = "";
    Object.keys(CurrencyService.config).forEach(function (key) {
      if (CurrencyService.config[key].numberCode === countryCode) {
        const iso = CurrencyService.config[key].iso;
        currencyCode = iso;
      }
    });

    return currencyCode;
  }

  public static formatInputPrice(value: string): string {
    value = this.cleanPriceInput(value);
    if (value === "") {
      return "";
    }

    value = this.formatString2Price(value);
    return value;
  }

  public static cleanPriceInput(value: string): string {
    const { delimiters } =
      CurrencyService.config[
        CurrencyService.getCurrencyByCountryCode(
          window.localStorage["countryCode"]
        )
      ];

    const valueArr = value.split(delimiters.decimal);

    // Check if more than one decimal symbol
    if (delimiters.decimal == "," && (value.match(/\,/g) || []).length > 1) {
      value = value.slice(0, -1);
    }

    if (delimiters.decimal == "." && (value.match(/\./g) || []).length > 1) {
      value = value.slice(0, -1);
    }

    // Check if more than two decimals
    if (valueArr.length > 1 && valueArr[1].length > 2) {
      value = value.slice(0, -1);
    }

    // Check if added char is not a number or a decimal symbol
    if (
      !/\d/g.test(value.slice(-1)) &&
      value.slice(-1) !== delimiters.decimal
    ) {
      value = value.slice(0, -1);
    }

    return value;
  }

  public static formatString2Price(value: string): string {
    value = this.toString(value);
    const { delimiters, iso, numberCode } =
      CurrencyService.config[
        CurrencyService.getCurrencyByCountryCode(
          window.localStorage["countryCode"]
        )
      ];

    // If input is decimal delimiter
    if (value.slice(-1) === delimiters.decimal) {
      value = value.replace(delimiters.decimal, "");
      value = value + delimiters.decimal;
      return value;
    }

    value = value.replace("-", "");

    if (delimiters.thousand == ".") {
      value = value.replace(/\./g, " ");
    } else if (delimiters.thousand == ",") {
      value = value.replace(/\,/g, " ");
    } else if (delimiters.thousand == "’") {
      value = value.replace(/\’/g, " ");
    }

    value = value.replace(/ /g, "");

    // Switch delimiters if decimal is comma
    if (delimiters.decimal == ",") {
      value = value.replace(delimiters.decimal, delimiters.thousand);
    }

    let minimumFractionDigits = 0;

    // if input is zero
    if (delimiters.thousand == ".") {
      if (
        value.slice(-1) === "0" &&
        (value.match(/\./g) || []).length > 0 &&
        value.split(".")[1].length < 2
      ) {
        minimumFractionDigits = 1;
      }
    } else if (delimiters.thousand == ",") {
      if (
        value.slice(-1) === "0" &&
        (value.match(/\,/g) || []).length > 0 &&
        value.split(",")[1].length < 2
      ) {
        minimumFractionDigits = 1;
      }
    } else if (delimiters.thousand == "'") {
      if (
        value.slice(-1) === "0" &&
        (value.match(/\’/g) || []).length > 0 &&
        value.split("’")[1].length < 2
      ) {
        minimumFractionDigits = 1;
      }
    }

    const currencyFractionDigits = new Intl.NumberFormat(numberCode, {
      style: "currency",
      currency: iso,
    }).resolvedOptions().maximumFractionDigits;

    const formattedValue = Number(value).toLocaleString(numberCode, {
      maximumFractionDigits: currencyFractionDigits,
      minimumFractionDigits: minimumFractionDigits,
    });

    return formattedValue;
  }

  public static formatNumber2Price(
    value: any,
    outputFormat: boolean = false,
    showSymbol: boolean = false
  ): string {
    if (value === null || value === "") {
      return "";
    }

    const { iso, numberCode, symbol, symbolPosition } =
      CurrencyService.config[
        CurrencyService.getCurrencyByCountryCode(
          window.localStorage["countryCode"]
        )
      ];

    const currencyFractionDigits = new Intl.NumberFormat(numberCode, {
      style: "currency",
      currency: iso,
    }).resolvedOptions().maximumFractionDigits;

    if (outputFormat) {
      const minDigits = 2; //fixed number
      const maxDigits =
        currencyFractionDigits < minDigits ? minDigits : currencyFractionDigits;

      const formattedValue = Number(value).toLocaleString(numberCode, {
        maximumFractionDigits: maxDigits,
        minimumFractionDigits: minDigits,
      });

      return showSymbol
        ? `${symbolPosition === "left" ? symbol : ""}${formattedValue}${
            symbolPosition === "right" ? symbol : ""
          }`
        : `${formattedValue} ${iso}`;
    } else {
      const formattedValue = Number(value).toLocaleString(numberCode, {
        maximumFractionDigits: currencyFractionDigits,
      });

      return `${formattedValue}${showSymbol ? symbol : " " + iso}`;
    }
  }

  public static formatPriceToNumber(value: string): number {
    const { delimiters } =
      CurrencyService.config[
        CurrencyService.getCurrencyByCountryCode(
          window.localStorage["countryCode"]
        )
      ];
    value = this.toString(value);

    if (delimiters.thousand == ".") {
      value = value.replace(/\./g, " ");
    } else if (delimiters.thousand == ",") {
      value = value.replace(/\,/g, " ");
    } else if (delimiters.thousand == "’") {
      value = value.replace(/\’/g, " ");
    }
    value = value.replace(/ /g, "");

    // Switch delimiters if decimal is comma
    if (delimiters.decimal == ",") {
      value = value.replace(delimiters.decimal, delimiters.thousand);
    }

    value = value.replace(
      CurrencyService.getCurrencyByCountryCode(
        window.localStorage["countryCode"]
      ),
      ""
    );
    value = value.trim();

    return parseFloat(value);
  }

  public static removeCurrencySymbol(value: string): string {
    return value
      .replace(
        CurrencyService.getCurrencyByCountryCode(
          window.localStorage["countryCode"]
        ),
        ""
      )
      .trim();
  }

  public static addCurrencySymbol(value: string): string {
    if (value === "") {
      return "";
    }
    return `${this.removeCurrencySymbol(
      value
    )} ${CurrencyService.getCurrencyByCountryCode(
      window.localStorage["countryCode"]
    )}`;
  }

  public static toString(value: any): string {
    if (typeof value !== "string") {
      value = "" + value;
    }
    return value;
  }
}
