import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "marketing-portal-video-editor-main",
  templateUrl: "./marketing-portal-video-editor-main.component.html",
  styleUrls: ["./marketing-portal-video-editor-main.component.scss"],
})
export class MarketingPortalVideoEditorMainComponent implements OnInit {
  @Output() onSelectSlide: EventEmitter<any> = new EventEmitter();
  @Input() slides: any;
  @Input() selectedSlide: any;
  @Input() duration: number;
  @Input() currentSlideIndex: number;
  constructor() {}

  ngOnInit() {}

  onPrevSlide() {
    if (this.currentSlideIndex === 0) {
      return;
    }

    const prevSlide = this.slides[this.currentSlideIndex - 1];
    this.onSelectSlide.emit(prevSlide.slideId);
  }

  onNextSlide() {
    if (this.currentSlideIndex === this.slides.length - 1) {
      return;
    }

    const prevSlide = this.slides[this.currentSlideIndex + 1];
    this.onSelectSlide.emit(prevSlide.slideId);
  }
}
