import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { MPMediaAsset } from "app/model/marketing-portal/MPMediaAsset";
import { MPMediaUploadFormatConfiguration } from "app/model/marketing-portal/MPMediaUploadFormatConfiguration";
import { MPMediaUploadFormatValidator } from "app/model/marketing-portal/MPMediaUploadFormatValidator";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { ImportMarketingPortalEditorFileDialogComponent } from "../dialogs/import-marketing-portal-editor-file-dialog/import-marketing-portal-editor-file-dialog.component";
import { MediaAssetSearchDialogComponent } from "../dialogs/media-asset-search-dialog/media-asset-search-dialog.component";
import { UnsplashImageSearchDialogComponent } from "../dialogs/unsplash-image-search-dialog/unsplash-image-search-dialog.component";
import { UploadImageDialogComponent } from "../dialogs/upload-image-dialog/upload-image-dialog.component";

@Component({
  selector: "app-drag-drop-upload",
  templateUrl: "./drag-drop-upload.component.html",
  styleUrls: ["./drag-drop-upload.component.scss"],
})
export class DragDropUploadComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private crudService: CrudService,
    private appLoader: AppLoaderService
  ) {}
  @ViewChild("fileInput") fileInput: ElementRef;
  files: string[] = [];
  @Output() addLibaryTemplateFile?: EventEmitter<any> = new EventEmitter();
  @Output() addVideoMediaAsset?: EventEmitter<any> = new EventEmitter();

  @Input() customerId: number = null;
  @Input() customerLogo?: string = null;
  @Input() width?: number = 600;
  @Input() height?: number = 600;
  @Input() minHeight?: number = 300;
  @Input() minWidth?: number = 300;
  @Input() channelId?: number;
  @Input() googleDisplayMediaFormatId: number;
  @Input() removeBackground?: boolean = false;
  @Input() forceAspectRatio: boolean = false;
  @Input() useImageCropper?: boolean = true;
  @Input() uploadType?: string = "image";
  @Input() showMediaAssetOptions?: boolean = false;
  @Input() hideMediaAssetOptions?: any[] = null;
  @Input() hideAdMaterials?: boolean = false;
  @Input() videoUploadConfiguration?: MPMediaUploadFormatConfiguration;
  @Input() imageUploadConfiguration?: MPMediaUploadFormatConfiguration;
  @Input() recommendationText: string = this.translate.instant(
    "ProductImageUploadSizeRecommendation"
  );
  @Output() filesDropped = new EventEmitter<string[]>();
  @Output() filesDroppedEvent = new EventEmitter<any>();
  public acceptTypes: string;
  public UPLOAD_TYPE_IMAGE = "image";
  public UPLOAD_TYPE_VIDEO = "video";

  showDeleteButtons: boolean = false;
  showFilesUploaded: boolean = false;

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty("uploadType")) {
      this.setAcceptTypes();
    }
  }

  setAcceptTypes() {
    this.acceptTypes = this.isImageFile()
      ? this.imageUploadConfiguration.acceptTypes
      : this.videoUploadConfiguration.acceptTypes;
  }

  async emitFiles(event, isDragEvent) {
    const files = isDragEvent ? event : event.target.files;

    const config = this.isImageFile()
      ? this.imageUploadConfiguration
      : this.videoUploadConfiguration;

    const mpMediaUploadFormatValidator: MPMediaUploadFormatValidator =
      new MPMediaUploadFormatValidator(
        config,
        files[0],
        this.translate,
        this.isImageFile()
      );

    await mpMediaUploadFormatValidator.validate();

    if (mpMediaUploadFormatValidator.hasErrors()) {
      this.snackbarService.show(
        mpMediaUploadFormatValidator.getError(),
        "danger"
      );

      return;
    }

    if (mpMediaUploadFormatValidator.hasWarnings()) {
      this.snackbarService.show(
        mpMediaUploadFormatValidator.getWarning(),
        "warning"
      );
    }

    if (this.isImageFile()) {
      this.handleImageFiles(event, files, isDragEvent);
      return;
    }

    const { width, height } = await mpMediaUploadFormatValidator.getMediaSize();
    const duration =
      await mpMediaUploadFormatValidator.videoService.getDuration();

    this.handleVideoFile(event, files[0], isDragEvent, width, height, duration);
  }

  async handleVideoFile(event, file, isDragEvent, width, height, duration) {
    if (!isDragEvent) {
      event.target.value = "";
    }

    this.appLoader.open();

    await this.crudService
      .uploadCampaignVideoAssets(file, width, height, duration)
      .toPromise()
      .then((res: any) => {
        const id = res.body.successfullVideoIds[0];

        const newMediaAsset = new MPMediaAsset(
          id,
          file.name,
          null,
          null,
          file.size,
          file.type,
          false,
          false,
          true,
          true,
          null,
          null,
          null,
          null,
          false,
          null,
          null
        );

        newMediaAsset.setURL();
        newMediaAsset.setThumbnailURL();

        this.addVideoMediaAsset.emit({ newMediaAsset });
      })
      .catch((e) => {
        this.snackbarService.show(e.error, "danger");
      });

    this.appLoader.close();
  }

  handleImageFiles(event, files, isDragEvent) {
    for (let index = 0; index < files.length; index++) {
      const element = files[index];

      const dialogRef = this.dialog.open(UploadImageDialogComponent, {
        width: "1000px",
        maxHeight: "95vh",
        panelClass: "no-spacing",
        disableClose: true,
        data: {
          file: element,
          useBackgroundRemover: this.removeBackground,
          useImageCropper: this.useImageCropper,
          forceAspectRatio: this.forceAspectRatio,
          width: this.width,
          height: this.height,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.files.push(res.res.name);
          this.filesDropped.emit(this.files);
          this.filesDroppedEvent.emit([res.res]);
        }
      });
    }

    if (!isDragEvent && event) {
      event.target.value = "";
    }
  }

  handleMediaAssetFile() {
    const data = {};

    if (this.hideMediaAssetOptions) {
      data["hideOptions"] = this.hideMediaAssetOptions;
    }

    const dialogRef = this.dialog.open(
      ImportMarketingPortalEditorFileDialogComponent,
      {
        width: "800px",
        panelClass: "no-spacing",
        height: "auto",
        maxHeight: "100vh",
        data: data,
      }
    );

    dialogRef.afterClosed().subscribe(async (res) => {
      if (!res) {
        return;
      }

      if (res.type === "CUSTOM_IMAGE") {
        this.showMediaAssetOptions = false;
        this.onClickFileInput();
        setTimeout(() => {
          this.showMediaAssetOptions = true;
        }, 500);
      } else if (res.type === "UNSPLASH") {
        const unplashImageSearchDialogRef = this.dialog.open(
          UnsplashImageSearchDialogComponent,
          {
            width: "960px",
            panelClass: "no-spacing",
            height: "95vh",
            maxHeight: "95vh",
            disableClose: true,
          }
        );

        unplashImageSearchDialogRef
          .afterClosed()
          .subscribe(async (response) => {
            if (response) {
              this.handleImageFiles(null, [response.file], false);
            }
          });
      } else if (res.type === "MEDIAASSET") {
        const mediaAssetImageSearchDialogRef = this.dialog.open(
          MediaAssetSearchDialogComponent,
          {
            width: "960px",
            panelClass: "no-spacing",
            maxHeight: "95vh",
            disableClose: true,
            data: {
              customerId: this.customerId,
              customerLogo: this.customerLogo,
              width: this.width,
              height: this.height,
              channelId: this.channelId,
              googleDisplayMediaFormatId: this.googleDisplayMediaFormatId,
              hideAdMaterials: this.hideAdMaterials,
            },
          }
        );

        mediaAssetImageSearchDialogRef
          .afterClosed()
          .subscribe(async (response) => {
            if (response) {
              // Check if selected image is template
              if (response.hasOwnProperty("mpLibaryMediaAsset")) {
                this.addLibaryTemplateFile.emit({
                  mpLibaryMediaAsset: response.mpLibaryMediaAsset,
                });
                return;
              }

              this.handleImageFiles(null, [response.file], false);
            }
          });
      }
    });
  }

  onClickFileInput() {
    if (this.showMediaAssetOptions) {
      this.handleMediaAssetFile();
    } else {
      this.fileInput.nativeElement.click();
    }
  }

  isImageFile() {
    return this.uploadType === this.UPLOAD_TYPE_IMAGE;
  }

  isVideoFile() {
    return this.uploadType === this.UPLOAD_TYPE_VIDEO;
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
  }
}
