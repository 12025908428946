import { MPCallToAction } from "./MPCallToAction";
import { MPCampaignStatistics } from "./MPCampaignStatistics";
import { MPCampaignStatus } from "./MPCampaignStatus";
import { MPCampaignPackage } from "./MPCampaingPackage";
import { MPCustomer } from "./MPCustomer";
import { MPCustomerApprovalStatus } from "./MPCustomerApprovalStatus";
import { MPDevice } from "./MPDevice";
import { MPGDNMediaAsset } from "./MPGDNMediaAsset";
import { MPGender } from "./MPGender";
import { MPGoalAndPerformance } from "./MPGoalAndPerformance";
import { MPLocation } from "./MPLocation";

export class MPGDNCampaign {
  static readonly CHANNEL_ID = 10;

  public mpCampaignId: number;
  public googleDisplayCampaignId: number;
  public dateTimeDisplayName: string;
  public start: Date;
  public end: Date;
  public status: MPCampaignStatus;
  public name: string;
  public displayBudget: number;
  public package: MPCampaignPackage;
  public goalAndPerformance: MPGoalAndPerformance;
  public googleDisplayCampaignBusinessName: string;
  public googleDisplayCampaignFinalUrl: string;
  public googleDisplayCampaignLongHeadlines: string;
  public googleDisplayCampaignHeadlines: string[];
  public googleDisplayCampaignDescriptions: string[];
  public mediaAssets: MPGDNMediaAsset[];
  public gender: MPGender;
  public device: MPDevice;
  public googleDisplayCampaignCallToAction: MPCallToAction;
  public fromAge: number;
  public toAge: number;
  public targets: any[];
  public locations: MPLocation[];
  public linkToPreview: string;
  public createdOn: Date;
  public campaignStatistics: MPCampaignStatistics;
  public customer: MPCustomer;
  public showApproalButton: boolean;
  public channelId: number;
  public channelLogo: string;
  public channelName: string;
  public isPayed: boolean;
  public customerApprovalOpen: boolean;
  public customerApprovalStatus: MPCustomerApprovalStatus;

  constructor() {}
  hasBudget() {
    return this.displayBudget || this.package;
  }
}
