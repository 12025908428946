import { MPAgeGroup } from "./MPAgeGroup";
import { MPAgeGroupList } from "./MPAgeGroupList";
import { MPCallToAction } from "./MPCallToAction";
import { MPCampaign } from "./MPCampaign";
import { MPCampaignStatistics } from "./MPCampaignStatistics";
import { MPCampaignStatus } from "./MPCampaignStatus";
import { MPCampaignPackage } from "./MPCampaingPackage";
import { MPCategory } from "./MPCategory";
import { MPCustomer } from "./MPCustomer";
import { MPCustomerApprovalStatus } from "./MPCustomerApprovalStatus";
import { MPDevice } from "./MPDevice";
import { MPGender } from "./MPGender";
import { MPGoalAndPerformance } from "./MPGoalAndPerformance";
import { MPLocation } from "./MPLocation";
import { MPLocationList } from "./MPLocationList";
import { MPMediaAsset } from "./MPMediaAsset";
import { MPMediaAssetList } from "./MPMediaAssetList";
import { MPTopicList } from "./MPTopciList";
import { MPTopic } from "./MPTopic";

export class YTCampaign extends MPCampaign {
  public youtubeCampaignId: number;
  public youtubeCampaignUrl: string;
  public youtubeCampaignShorttext: string;
  public youtubeCampaignHeadline: string;
  public youtubeCampaignCallToAction: MPCallToAction;
  public package: MPCampaignPackage;
  public goalAndPerformance: MPGoalAndPerformance;
  public gender: MPGender;
  public ageGroups: MPAgeGroup[];
  public targets: any[];
  public category: MPCategory;
  public device: MPDevice;
  public topics: MPTopic[];

  constructor(
    mpCampaignId: number = null,
    youtubeCampaignId: number = null,
    youtubeCampaignUrl: string = null,
    youtubeCampaignShorttext: string = null,
    youtubeCampaignHeadline: string = null,
    youtubeCampaignCallToAction: MPCallToAction = null,
    dateTimeDisplayName: string = null,
    start: Date = null,
    end: Date = null,
    status: MPCampaignStatus = null,
    name: string = null,
    displayBudget: number = null,
    campaignPackage: MPCampaignPackage = null,
    goalAndPerformance: MPGoalAndPerformance = null,
    mediaAssets: MPMediaAsset[] = null,
    gender: MPGender = null,
    device: MPDevice = null,
    ageGroups: MPAgeGroup[] = null,
    targets: any[] = null,
    topics: MPTopic[] = null,
    locations: MPLocation[] = null,
    category: MPCategory = null,
    createdOn: Date = null,
    campaignStatistics: MPCampaignStatistics = null,
    customer: MPCustomer = null,
    showApproalButton: boolean = null,
    channelId: number = null,
    channelLogo: number = null,
    channelName: string = null,
    isPayed: boolean = null,
    customerApprovalOpen: boolean = null,
    customerApprovalStatus: MPCustomerApprovalStatus = null
  ) {
    super(
      mpCampaignId,
      dateTimeDisplayName,
      start,
      end,
      status,
      name,
      displayBudget,
      mediaAssets,
      locations,
      createdOn,
      customer,
      showApproalButton,
      channelId,
      channelLogo,
      channelName,
      isPayed,
      customerApprovalOpen,
      customerApprovalStatus,
      campaignStatistics
    );

    this.youtubeCampaignId = youtubeCampaignId;
    this.youtubeCampaignUrl = youtubeCampaignUrl;
    this.youtubeCampaignShorttext = youtubeCampaignShorttext;
    this.youtubeCampaignHeadline = youtubeCampaignHeadline;
    this.youtubeCampaignCallToAction = youtubeCampaignCallToAction;
    this.package = campaignPackage;
    this.goalAndPerformance = goalAndPerformance;
    this.gender = gender;
    this.device = device;
    this.ageGroups = ageGroups;
    this.targets = targets;
    this.category = category;
    this.topics = topics;
  }

  deserialize(serializedObj: YTCampaign): YTCampaign {
    if (!serializedObj) {
      return null;
    }

    this.mpCampaignId = serializedObj["mpCampaignId"];
    this.youtubeCampaignId = serializedObj["youtubeCampaignId"];
    this.youtubeCampaignUrl = serializedObj["youtubeCampaignUrl"];
    this.youtubeCampaignShorttext = serializedObj["youtubeCampaignShorttext"];
    this.youtubeCampaignHeadline = serializedObj["youtubeCampaignHeadline"];
    this.youtubeCampaignCallToAction = new MPCallToAction().deserialize(
      serializedObj["youtubeCampaignCallToAction"]
    );
    this.dateTimeDisplayName = serializedObj["dateTimeDisplayName"];
    this.start = serializedObj["start"];
    this.end = serializedObj["end"];
    this.status = new MPCampaignStatus().deserialize(serializedObj["status"]);
    this.name = serializedObj["name"];
    this.displayBudget = serializedObj["displayBudget"];
    this.package = new MPCampaignPackage().deserialize(
      serializedObj["package"]
    );
    this.goalAndPerformance = new MPGoalAndPerformance().deserialize(
      serializedObj["goalAndPerformance"]
    );
    this.mediaAssets = new MPMediaAssetList().deserializeList(
      serializedObj["mediaAssets"]
    );
    this.gender = new MPGender().deserialize(serializedObj["gender"]);
    this.topics = new MPTopicList().deserializeList(serializedObj["topics"]);
    this.device = new MPDevice().deserialize(serializedObj["device"]);
    this.ageGroups = new MPAgeGroupList().deserializeList(
      serializedObj["ageGroups"]
    );
    this.locations = new MPLocationList().deserializeList(
      serializedObj["locations"]
    );
    this.category = new MPCategory().deserialize(serializedObj["category"]);

    this.createdOn = serializedObj["createdOn"];
    this.campaignStatistics = new MPCampaignStatistics().deserialize(
      serializedObj["campaignStatistics"]
    );
    this.customer = new MPCustomer().deserialize(serializedObj["customer"]);
    this.showApproalButton = serializedObj["showApproalButton"];
    this.channelId = serializedObj["channelId"];
    this.channelLogo = serializedObj["channelLogo"];
    this.channelName = serializedObj["channelName"];
    this.isPayed = serializedObj["isPayed"];
    this.customerApprovalOpen = serializedObj["customerApprovalOpen"];
    this.customerApprovalStatus = new MPCustomerApprovalStatus().deserialize(
      serializedObj["customerApprovalStatus"]
    );

    return this;
  }
}
