import { dataURLtoFile } from "app/shared/utils/blob.utils";
import { environment } from "environments/environment";
import { Base64 } from "../Base64";
import { MPGoogleDisplayMediaFormat } from "./MPGoogleDisplayMediaFormat";
import { MPGoogleDisplayMediaFormatList } from "./MPGoogleDisplayMediaFormatList";

export class MPMediaAsset {
  public mediaAssetId: number;
  public mediaAssetName: string;
  public width: number;
  public height: number;
  public sizeInByte: number;
  public mediaAssetDataType: string;
  public mediaAssetIsTransparent: boolean;
  public isImage: boolean;
  public isVideo: boolean;
  public isReady: boolean;
  public mediaAssetUpdateTime: Date;
  public mediaAssetData: string | ArrayBuffer;
  public mediaAssetUrl: string;
  public mediaAssetThumbnailUrl: string;
  public isPartOfSet: boolean;
  public templateId: number;
  public googleDisplayMediaFormatId?: number;
  public googleDisplayMediaFormat: MPGoogleDisplayMediaFormat[];

  constructor(
    mediaAssetId: number = null,
    mediaAssetName: string = null,
    width: number = null,
    height: number = null,
    sizeInByte: number = null,
    mediaAssetDataType: string = null,
    mediaAssetIsTransparent: boolean = null,
    isImage: boolean = null,
    isVideo: boolean = null,
    isReady: boolean = null,
    mediaAssetUpdateTime: Date = null,
    mediaAssetData: string | ArrayBuffer = null,
    mediaAssetUrl: string = null,
    mediaAssetThumbnailUrl: string = null,
    isPartOfSet: boolean = null,
    templateId: number = null,
    googleDisplayMediaFormatId: number = null,
    googleDisplayMediaFormat: MPGoogleDisplayMediaFormat[] = null
  ) {
    this.mediaAssetId = mediaAssetId;
    this.mediaAssetName = mediaAssetName;
    this.width = width;
    this.height = height;
    this.sizeInByte = sizeInByte;
    this.mediaAssetDataType = mediaAssetDataType;
    this.mediaAssetIsTransparent = mediaAssetIsTransparent;
    this.isImage = isImage;
    this.isVideo = isVideo;
    this.isReady = isReady;
    this.mediaAssetUpdateTime = mediaAssetUpdateTime;
    this.mediaAssetData = mediaAssetData;
    this.mediaAssetUrl = mediaAssetUrl;
    this.mediaAssetThumbnailUrl = mediaAssetThumbnailUrl;
    this.isPartOfSet = isPartOfSet;
    this.templateId = templateId;
    this.googleDisplayMediaFormatId = googleDisplayMediaFormatId;
    this.googleDisplayMediaFormat = googleDisplayMediaFormat;
  }

  setId(mediaAssetId: number) {
    this.mediaAssetId = mediaAssetId;
  }

  getMediaUrlById(): string {
    return (
      environment.imageServerUrl +
      "api/Public/GetMediaAsset?id=" +
      this.mediaAssetId
    );
  }

  getSizeInMB(): string {
    return (this.sizeInByte / 1024 ** 2).toFixed(2);
  }

  toFile() {
    return dataURLtoFile(this.mediaAssetData, this.mediaAssetName);
  }

  setURL(): void {
    this.mediaAssetUrl = this.genURL();
  }

  setThumbnailURL(): void {
    this.mediaAssetThumbnailUrl = this.genURL() + "&width=300&height=300";
  }

  genURL(): string {
    return (
      environment.imageServerUrl +
      "api/Public/GetMediaAsset?id=" +
      this.mediaAssetId
    );
  }

  getFramesFromVideo(seconds: number[]): Promise<string[]> {
    return Base64.getBase64FramesFromVideo(seconds, this.mediaAssetUrl);
  }

  deserialize(serializedObj: object): MPMediaAsset {
    if (!serializedObj) {
      return null;
    }

    this.mediaAssetId = serializedObj["mediaAssetId"];
    this.mediaAssetName = serializedObj["mediaAssetName"];
    this.width = serializedObj["width"];
    this.height = serializedObj["height"];
    this.sizeInByte = serializedObj["sizeInByte"];
    this.mediaAssetDataType = serializedObj["mediaAssetDataType"];
    this.mediaAssetIsTransparent = serializedObj["mediaAssetIsTransparent"];
    this.isImage = serializedObj["isImage"];
    this.isVideo = serializedObj["isVideo"];
    this.isReady = serializedObj["isReady"];
    this.mediaAssetUpdateTime = serializedObj["mediaAssetUpdateTime"];
    this.mediaAssetData = serializedObj["mediaAssetData"];
    this.mediaAssetUrl = serializedObj["mediaAssetUrl"];
    this.mediaAssetThumbnailUrl = serializedObj["mediaAssetThumbnailUrl"];
    this.isPartOfSet = serializedObj["isPartOfSet"];
    this.templateId = serializedObj["templateId"];
    this.googleDisplayMediaFormatId =
      serializedObj["googleDisplayMediaFormatId"];
    this.googleDisplayMediaFormat =
      new MPGoogleDisplayMediaFormatList().deserializeList(
        serializedObj["googleDisplayMediaFormat"]
      );

    return this;
  }
}
