import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MPLibaryMediaAsset } from "app/model/marketing-portal/MPLibaryMediaAsset";

@Component({
  selector: "media-assets-libary-customer-logo",
  templateUrl: "./media-assets-libary-customer-logo.component.html",
  styleUrls: ["./media-assets-libary-customer-logo.component.scss"],
})
export class MediaAssetsLibaryCustomerLogoComponent implements OnInit {
  @Output() selectImage: EventEmitter<any> = new EventEmitter();
  @Input() customerLogo: number;
  @Input() selectedImage: MPLibaryMediaAsset;
  public mpLibaryMediaAsset: MPLibaryMediaAsset;
  constructor() {}

  ngOnInit() {
    this.setMPLibaryMediaAsset();
  }

  setMPLibaryMediaAsset() {
    this.mpLibaryMediaAsset = new MPLibaryMediaAsset(
      this.customerLogo,
      "Logo",
      null,
      null,
      300,
      300
    );
    this.mpLibaryMediaAsset.setImageURL();
    this.mpLibaryMediaAsset.setImageThumbnailURL();
  }
}
