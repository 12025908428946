export class MPLocation {
  public locationId: number;
  public locationLatitude: number;
  public locationLongitude: number;
  public locationStreet: string;
  public locationStreetNumber: string;
  public locationCity: string;
  public locationZipCode: string;
  public locationCountry: string;
  public locationState: string;
  public locationRadius: number;
  public budgetPercentage: number;

  constructor(
    locationId: number = null,
    locationLatitude: number = null,
    locationLongitude: number = null,
    locationStreet: string = null,
    locationStreetNumber: string = null,
    locationCity: string = null,
    locationZipCode: string = null,
    locationCountry: string = null,
    locationState: string = null,
    locationRadius: number = null,
    budgetPercentage: number = null
  ) {
    this.locationId = locationId;
    this.locationLatitude = locationLatitude;
    this.locationLongitude = locationLongitude;
    this.locationStreet = locationStreet;
    this.locationStreetNumber = locationStreetNumber;
    this.locationCity = locationCity;
    this.locationZipCode = locationZipCode;
    this.locationCountry = locationCountry;
    this.locationState = locationState;
    this.locationRadius = locationRadius;
    this.budgetPercentage = budgetPercentage;
  }

  public deserialize(serializedObj: object): MPLocation {
    if (!serializedObj) {
      return null;
    }

    this.locationId = serializedObj["locationId"];
    this.locationLatitude = serializedObj["locationLatitude"];
    this.locationLongitude = serializedObj["locationLongitude"];
    this.locationStreet = serializedObj["locationStreet"];
    this.locationStreetNumber = serializedObj["locationStreetNumber"];
    this.locationCity = serializedObj["locationCity"];
    this.locationZipCode = serializedObj["locationZipCode"];
    this.locationCountry = serializedObj["locationCountry"];
    this.locationState = serializedObj["locationState"];
    this.locationRadius = serializedObj["locationRadius"];
    this.budgetPercentage = serializedObj["budgetPercentage"];

    return this;
  }
}
