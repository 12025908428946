export class MPCallToAction {
  public callToActionId: number;
  public callToActionText: string;

  constructor(callToActionId: number = null, callToActionText: string = null) {
    this.callToActionId = callToActionId;
    this.callToActionText = callToActionText;
  }

  deserialize(serializedObj: object): MPCallToAction {
    if (!serializedObj) {
      return null;
    }

    this.callToActionId = serializedObj["callToActionId"];
    this.callToActionText = serializedObj["callToActionText"];

    return this;
  }
}
