import { SERVICE_ID_MARKEING_PORTAL } from "../../src/constants";

export const environment = {
  serviceId: SERVICE_ID_MARKEING_PORTAL,
  partnerId: 1,
  production: false,
  appProvider: "Wir-machen-Digital.de",
  backendUrl: "https://x.mapads.net/internal/api/",
  facebookUrl: "https://facebook.mapads.net/api/",
  googleUrl: "https://google.mapads.net/api/",
  externalSystemsUrl: "https://systems.mapads.net/api/",
  backendTokenUrl: "https://x.mapads.net/internal/",
  imageServerUrl: "https://image.mapads.net/",
  ownPartnerAppId: "d1121abe-6eb8-43b0-8a00-a31345166d23",
};
