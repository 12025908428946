import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  NgForm,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { CUSTOM_DATE_FORMATS } from "../../../../../../constants";
import {
  MatDialog,
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { fbAccount } from "app/model/account/fbAccount";
import { SocialMediaSettings } from "app/model/account/SocialMediaSettings";
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { SnackbarService } from "app/shared/services/snackbar.service";
import { CrudService } from "app/views/mapAds/crud.service";
import { environment } from "environments/environment";
import { AppDateAdapter } from "app/shared/adapters/app-date-adapter";
import { ValidationService } from "app/shared/services/validation.service";
import { MarketingPortalEditorDialogComponent } from "app/shared/components/dialogs/marketing-portal/marketing-portal-editor-dialog/marketing-portal-editor-dialog.component";
import { MPMediaAsset } from "app/model/marketing-portal/MPMediaAsset";
import { MPMediaAssetUploader } from "app/model/marketing-portal/MPMediaAssetUploader";
import { MPMediaUploadFormatConfiguration } from "app/model/marketing-portal/MPMediaUploadFormatConfiguration";
import { MPMediaUploadFormatHandler } from "app/model/marketing-portal/MPMediaUploadFormatHandler";

@Component({
  selector: "create-instagram-facebook-post-form",
  templateUrl: "./create-instagram-facebook-post-form.component.html",
  styleUrls: ["./create-instagram-facebook-post-form.component.scss"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "de-De" },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class CreateInstagramFacebookPostFormComponent implements OnInit {
  @ViewChild("form") form: NgForm;
  @Input() data?: any;
  @Input() duplicate: number = null;
  @Input() readonly?: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() campaignChannelId?: number = null;
  @Input() customer?: any;
  public isLoading: boolean = false;
  public campaignForm: FormGroup;
  public mediaPackages: any;
  public mediaPackageType: number;
  public selectedMediaPackage: any;
  public accountType: number;
  public maxTextLength: number = 128;
  public maxLinkLength: number = 256;
  public errors = {};
  public mediaImportType: number;
  public facebookAccount: fbAccount;
  public pages: any;
  public isLoadingFacebookPageRequest: boolean = false;
  public facebookPageRequestSent: boolean = false;
  public facebookPageRequestLink: string;
  public socialMediaSettings: SocialMediaSettings;
  public previewMediaFiles: any = [];
  public readonly environment = environment;
  public showFBBrandedContentSelection: boolean = false;
  public selectedFacebookPageName: string;
  public selectedFacebookImageUrl: string;
  public mediaAssets: MPMediaAsset[] = [];
  public videoUploadConfiguration: MPMediaUploadFormatConfiguration;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private adapter: DateAdapter<any>,
    private crudService: CrudService,
    private appLoader: AppLoaderService,
    private snackbarService: SnackbarService,
    private domSanitizer: DomSanitizer,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.adapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.adapter.setLocale(event.lang);
    });

    this.videoUploadConfiguration =
      new MPMediaUploadFormatHandler().getFBVideoConfig();

    this.buildForm(this.data);

    if (!this.data || this.duplicate) {
      let initCampaignName = this.getInitCampaignName();

      this.campaignForm.get("campaignName").setValue(initCampaignName);
    }

    this.socialMediaSettings = await this.crudService
      .getSocialMediaSettings()
      .toPromise();

    if (!this.data) {
      this.setFacebookPage(true);

      if (this.customer && this.customer.customerId) {
        if (this.customer.customerHomepage) {
          this.campaignForm
            .get("campaignOwnLink")
            .setValue(
              this.customer.customerHomepage.replace(/^https?:\/\//, "")
            );
        }
      }
    } else {
      this.mediaAssets = this.getMediaAssetObjs(this.data.mediaAssets);
      this.updatePreviewMediaFiles();

      this.showFBBrandedContentSelection = !!this.data.sponsoredFacebookPage;

      await this.initFacebookPages();

      this.campaignForm
        .get("campaignFacebookPageId")
        .setValue(this.data.facebookPageId);
      this.selectedFacebookPageName = this.data.facebookPageName;
      this.selectedFacebookImageUrl = this.data.facebookPageImageUrl;

      if (
        this.data.facebookPageId !=
        this.socialMediaSettings.DefaultFBPage.FBPageId
      ) {
        this.campaignForm.get("useOwnAccount").setValue(1);
      }
    }
  }

  getMediaAssetObjs(mediaAssets) {
    const objs = [];

    mediaAssets.forEach((mediaAsset) => {
      const newObj = new MPMediaAsset(
        mediaAsset.mediaAssetId,
        mediaAsset.mediaAssetName,
        mediaAsset.width,
        mediaAsset.height,
        mediaAsset.sizeInByte,
        mediaAsset.mediaAssetDataType,
        mediaAsset.mediaAssetIsTransparent,
        mediaAsset.isImage,
        mediaAsset.isVideo,
        mediaAsset.isReady,
        mediaAsset.mediaAssetUpdateTime,
        mediaAsset.mediaAssetData,
        mediaAsset.mediaAssetUrl,
        mediaAsset.mediaAssetThumbnailUrl,
        mediaAsset.isPartOfSet,
        mediaAsset.templateId
      );

      objs.push(newObj);
    });

    return objs;
  }

  getInitCampaignName() {
    let initCampaignName =
      "Post Kampagne " +
      new Date().toLocaleDateString(window.localStorage["countryCode"]);

    if (this.customer && this.customer.customerName) {
      initCampaignName =
        this.customer.customerName +
        " Post " +
        new Date().toLocaleDateString(window.localStorage["countryCode"]);
    }

    if (this.duplicate) {
      initCampaignName = "[Kopie] " + this.data.name;
    } else if (this.isEdit) {
      initCampaignName = this.data.name;
    }

    return initCampaignName;
  }

  getCampaignOwnLink(items) {
    let campaignOwnLink = "";

    if (this.data && this.data.socialMediaCampaignLink) {
      campaignOwnLink = this.data.socialMediaCampaignLink.replace(
        /^https?:\/\//,
        ""
      );
    }

    return campaignOwnLink;
  }

  onChangeMediaAsset($event) {
    this.mediaAssets = $event;
    this.updatePreviewMediaFiles();
  }

  getInitProtocol(items) {
    let protocol = "https://";

    if (
      this.data &&
      this.data.socialMediaCampaignLink &&
      this.data.socialMediaCampaignLink.includes("http://")
    ) {
      protocol = "http://";
    }

    return protocol;
  }

  buildForm(items = null) {
    const initDate = this.getInitDate(items);
    const initImageIdx = this.getInitImageIdx(items);
    const initProtocol = this.getInitProtocol(items);
    const initCampaignOwnLink = this.getCampaignOwnLink(items);

    this.campaignForm = this.formBuilder.group({
      mpCampaignId: items ? items.mpCampaignId : null,
      campaignName: [
        items ? items.name : "Meine Kampagne",
        Validators.required,
      ],
      socialMediaCampaignTypeId: [
        items ? items.socialMediaCampaignTypes[0].campaignTypeId : 1,
        Validators.required,
      ],
      imageIdx: [initImageIdx],
      templateImageId: null,
      campaignImageIds: [[]],
      campaignVideoIds: [[]],
      channelId: items ? items.channelId : this.campaignChannelId,
      campaignDisplayText: [
        items && items.socialMediaCampaignDisplayText
          ? items.socialMediaCampaignDisplayText
          : "",
      ],
      protocol: [initProtocol],
      campaignOwnLink: [initCampaignOwnLink],
      campaignStart: [
        initDate.startDate,
        Validators.compose([ValidationService.dateInFuture]),
      ],
      campaignTime: initDate.startTime,
      sponsoredFacebookPage: [
        items && items.sponsoredFacebookPage
          ? items.sponsoredFacebookPage
          : null,
      ],
      campaignFacebookPageId: [
        items ? items.facebookPageId : null,
        Validators.required,
      ],
      mediaImportType: [],
      adMaterialType: [1],
      useOwnAccount: [0],
    });

    if (this.data && this.data.linkToPreview) {
      this.campaignForm.get("campaignDisplayText").disable();
      this.campaignForm.get("campaignOwnLink").disable();
    }
  }

  getInitDate(items) {
    const initDate = {
      startDate: null,
      startTime: null,
    };

    if (!items || this.duplicate) {
      let startDate = new Date();
      startDate.setDate(startDate.getDate() + 1);
      startDate.setHours(12);
      startDate.setMinutes(0);
      startDate.setMilliseconds(0);
      startDate.setSeconds(0);
      initDate.startDate = startDate;
      initDate.startTime = this.getTimeByDate(startDate);
    } else {
      const startDate = new Date(items.start);
      let offsetStart = (startDate.getTimezoneOffset() * -1) / 60;

      initDate.startDate = new Date(items.start);
      initDate.startDate.setHours(initDate.startDate.getHours() + offsetStart);
      initDate.startTime = this.getTimeByDate(initDate.startDate);
    }

    return initDate;
  }

  getTimeByDate(date) {
    const hours =
      date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    const minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    return `${hours}:${minutes}`;
  }

  updateTimeOfDateTimeControl(control, time) {
    const hours = parseInt(time.split(":")[0]);
    const minutes = parseInt(time.split(":")[1]);

    const date = control.value;
    date.setHours(hours);
    date.setMinutes(minutes);
    control.setValue(date);
  }

  onChangeUrl($event) {
    let value = $event.target.value;

    if (!value) {
      return;
    }

    value = value.replace(/^https?:\/\//, "");

    this.campaignForm.get("campaignOwnLink").setValue(value);
  }

  onChangeTime($event) {
    const valueAsDate = $event.target.valueAsDate;
    const dateTimeControl = this.campaignForm.get("campaignStart");

    if (valueAsDate) {
      this.updateTimeOfDateTimeControl(dateTimeControl, $event.target.value);
      this.campaignForm.controls["campaignStart"].updateValueAndValidity();
      this.validateErrors();
    }
  }

  onChangeDate($event) {
    const dateTimeControl = this.campaignForm.get("campaignStart");
    const newTime = this.campaignForm.get("campaignTime").value;

    this.updateTimeOfDateTimeControl(dateTimeControl, newTime);

    this.campaignForm.controls["campaignStart"].updateValueAndValidity();
    this.validateErrors();
  }

  getInitImageIdx(items) {
    if (!items) return [];

    const ids = [];

    items.mediaAssets.forEach((mediaAsset) => {
      ids.push(mediaAsset.mediaAssetId);
    });

    return ids;
  }

  brandedContentSelectionToggle($event) {
    this.showFBBrandedContentSelection = $event.checked;

    if (!$event.checked) {
      this.campaignForm.get("sponsoredFacebookPage").setValue(null);
    }
  }

  setFacebookPage(isDefault, facebookPageId = null) {
    if (isDefault) {
      this.campaignForm
        .get("campaignFacebookPageId")
        .setValue(this.socialMediaSettings.DefaultFBPage.FBPageId);
      this.selectedFacebookPageName =
        this.socialMediaSettings.DefaultFBPage.FBPageName;
      this.selectedFacebookImageUrl =
        this.socialMediaSettings.DefaultFBPage.facebookPageImageUrl;
    } else {
      this.campaignForm.get("campaignFacebookPageId").setValue(facebookPageId);

      const selectedPage = this.pages.find(
        (page) => page.id === facebookPageId
      );

      if (selectedPage) {
        this.selectedFacebookPageName = selectedPage.pageName;
        this.selectedFacebookImageUrl = selectedPage.facebookPageImageUrl;
      }
    }
  }

  openEditor() {
    const dialogRef = this.dialog.open(MarketingPortalEditorDialogComponent, {
      width: "100vw",
      height: "auto",
      maxWidth: "100vw",
      maxHeight: "auto",
      panelClass: "full-screen-dialog",
      data: {
        customerId: this.customer ? this.customer.customerId : null,
        customerLogo: this.customer ? this.customer.customerLogo : null,
        channelId: this.campaignForm.get("channelId").value,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        let id = res.templateMediaAssets[0].imageId;
        this.campaignForm.get("templateImageId").setValue(id);
      }
    });
  }

  switchMaterialType(type) {
    this.campaignForm.get("adMaterialType").setValue(type);
  }

  onClickFacebookPage(page) {
    // if (!page.adsPermissionStatus) {
    //   return;
    // }

    this.setFacebookPage(false, page.id);
  }

  async updateFacebookPages() {
    this.appLoader.open();

    await this.crudService
      .GetAllFacebookPageDetails()
      .toPromise()
      .then((res) => {
        this.pages = res;
        this.facebookPageRequestSent = false;
        this.snackbarService.show(
          "Die Ansicht wurde erfolgreich aktualisiert",
          "success"
        );
      })
      .catch((err) => {
        this.snackbarService.show(
          "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut",
          "danger"
        );
      });

    this.appLoader.close();
  }

  async sendFacebookPageRequest(page) {
    this.appLoader.open();

    await this.crudService
      .sendRequestToPage(page.id)
      .toPromise()
      .then((res) => {
        this.facebookPageRequestLink = res;
        this.facebookPageRequestSent = true;

        this.snackbarService.show(
          "Berechtigunsanfrage wurde erfolgreich gesendet",
          "success"
        );
      })
      .catch((err) => {
        this.snackbarService.show(
          "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut",
          "danger"
        );
      });

    this.appLoader.close();
  }

  accountTypeToggleChanged($event) {
    if (!$event.checked) {
      this.setFacebookPage(true);
      return;
    }

    if (!this.facebookAccount) {
      this.crudService.GetFacebookAccount().subscribe((res) => {
        this.facebookAccount = res;

        if (this.facebookAccount.Email) {
          this.crudService.GetAllFacebookPageDetails().subscribe((res) => {
            this.pages = res;

            const selectedPage = this.pages.find((page) => page);

            if (selectedPage) {
              this.setFacebookPage(false, selectedPage.id);
            }
          });
        }
      });
    }
  }

  initFacebookPages() {
    return new Promise((resolve, reject) => {
      this.crudService.GetFacebookAccount().subscribe((res) => {
        this.facebookAccount = res;

        if (this.facebookAccount.Email) {
          this.crudService.GetAllFacebookPageDetails().subscribe((res) => {
            this.pages = res;
            resolve(true);
          });
        } else {
          resolve(true);
        }
      });
    });
  }

  updatePreviewMediaFiles() {
    this.previewMediaFiles = [];

    this.mediaAssets.forEach((mediaAsset: MPMediaAsset) => {
      this.previewMediaFiles.push({
        safeData: mediaAsset.mediaAssetData
          ? this.domSanitizer.bypassSecurityTrustUrl(
              mediaAsset.mediaAssetData.toString()
            )
          : this.domSanitizer.bypassSecurityTrustUrl(
              mediaAsset.mediaAssetThumbnailUrl
            ),
        isVideo: mediaAsset.isVideo,
        width: null,
      });
    });
  }

  validateErrors() {
    this.errors = this.getValidationErrors();
  }

  setControlValidationError(key) {
    this.campaignForm.controls[key].markAsTouched();
    const control = this.campaignForm.get(key);
    const controlErrors: ValidationErrors = control.errors;

    if (controlErrors != null) {
      let error = Object.keys(control.errors)[0];

      // Control has multiple errors - We want to show only one
      if (Object.keys(control.errors).length > 2) {
        // Remove first error
        const firstKey = Object.keys(control.errors)[0];
        delete control.errors[firstKey];

        // Select error
        for (const key of Object.keys(control.errors)) {
          if (key != "validatorValue") {
            error = key;
          }
        }
      }

      this.errors[key] = ValidationService.getValidatorErrorMessage(
        error,
        this.translate,
        control.errors.validatorValue || {}
      );
    } else {
      delete this.errors[key];
    }
  }

  getValidationErrors(): Object {
    const errors = {};

    // FormGroup validation errors
    Object.keys(this.campaignForm.controls).forEach((key) => {
      const control = this.campaignForm.get(key);
      const controlErrors: ValidationErrors = control.errors;

      if (controlErrors != null) {
        let error = Object.keys(control.errors)[0];

        // Control has multiple errors - We want to show only one
        if (Object.keys(control.errors).length > 2) {
          // Remove first error
          const firstKey = Object.keys(control.errors)[0];
          delete control.errors[firstKey];

          // Select error
          for (const key of Object.keys(control.errors)) {
            if (key != "validatorValue") {
              error = key;
            }
          }
        }

        errors[key] = ValidationService.getValidatorErrorMessage(
          error,
          this.translate,
          control.errors.validatorValue || {}
        );
      }
    });

    return errors;
  }

  onClickChannel(channelNumber) {
    // Check if not instagram
    const validators = channelNumber === 1 ? null : [Validators.required];

    this.campaignForm.get("imageIdx").setValidators(validators);
    this.campaignForm.get("imageIdx").updateValueAndValidity();

    this.campaignForm.get("socialMediaCampaignTypeId").setValue(channelNumber);
  }

  setMediaFilesIdx() {
    if (this.mediaAssets.length) {
      let type = this.mediaAssets[0].isImage
        ? "campaignImageIds"
        : "campaignVideoIds";
      this.campaignForm
        .get(type)
        .setValue(this.campaignForm.get("imageIdx").value);
    }
  }

  setImageIdx() {
    let ids = [];

    this.mediaAssets.forEach((mediaAsset) => {
      ids.push(mediaAsset.mediaAssetId);
    });

    this.campaignForm.get("imageIdx").setValue(ids);
  }

  isValidPostMaterialCheck() {
    return (
      this.campaignForm.get("imageIdx").value.length != 0 ||
      this.campaignForm.get("campaignDisplayText").value != "" ||
      this.campaignForm.get("campaignOwnLink").value != ""
    );
  }

  onCampaignLinkInput($event) {
    let value = $event.target.value;

    this.applyValidatorsForNonEmptyField("campaignOwnLink", [
      ValidationService.urlValidator,
    ]);

    $event.target.value = value;
  }

  applyValidatorsForNonEmptyField(name, validators) {
    if (this.campaignForm.get(name).value === "") {
      this.campaignForm.get(name).clearValidators();
    } else {
      this.campaignForm.get(name).setValidators(validators);
    }
    this.campaignForm.get(name).updateValueAndValidity();
  }

  async onSubmit() {
    this.setImageIdx();

    this.errors = this.getValidationErrors();

    if (!this.isValidPostMaterialCheck()) {
      this.errors["invalidPostMaterial"] = "INVALID";
    }

    if (this.campaignForm.invalid || Object.keys(this.errors).length) {
      this.snackbarService.show(
        "Bitte fülle alle erforderlichen Felder aus",
        "danger"
      );

      setTimeout(() => {
        let errorMessages = document.querySelector(".error-message");
        if (errorMessages) {
          errorMessages.scrollIntoView();
        }
      });

      return;
    }

    this.appLoader.open();

    const mpMediaAssetUploader: MPMediaAssetUploader = new MPMediaAssetUploader(
      this.mediaAssets,
      this.crudService
    );
    let uploadError = false;

    await mpMediaAssetUploader
      .upload()
      .then((mediaAssets: MPMediaAsset[]) => {
        this.mediaAssets = mediaAssets;
        this.setImageIdx();
      })
      .catch((e) => {
        uploadError = true;
        this.snackbarService.show(e.error, "danger");
      });

    if (uploadError) {
      document.querySelector(".main-content-wrap").scrollTo(0, 0);
      this.appLoader.close();
      return;
    }
    this.setMediaFilesIdx();

    let value = this.campaignForm.getRawValue();

    const date = new Date();
    let offset = (date.getTimezoneOffset() * -1) / 60;

    if (this.isEdit) {
      const body = {
        customerId: this.customer ? this.customer.customerId : null,
        socialMediaCampaignTypeId: value.socialMediaCampaignTypeId,
        campaignStart: value.campaignStart,
        campaignEnd: null,
        campaignName: value.campaignName,
        campaignPackageId: null,
        manualBudget: null,
        needsCustomerApproval: false,
        channelId: value.channelId,
        campaignGoalId: 1,
        campaignDisplayText: value.campaignDisplayText,
        campaignOwnLink:
          value.campaignOwnLink != ""
            ? value.protocol + value.campaignOwnLink
            : "",
        campaignImageIds: value.imageIdx,
        campaignVideoIds: value.campaignVideoIds,
        campaignGenderId: 1,
        sponsoredFacebookPage: this.showFBBrandedContentSelection
          ? value.sponsoredFacebookPage
          : null,
        campaignFacebookPageId: value.campaignFacebookPageId,
        fromAge: 18,
        toAge: 24,
        campaignTargetIds: null,
        campaignLocations: null,
        timezone: offset,
      };

      await this.crudService
        .editSocialMediaCampaign(body, this.data.socialMediaCampaignId)
        .toPromise()
        .then((res) => {
          if (res) {
            this.snackbarService.show(
              "Deine Marketing-Kampagne wurde erfolgreich bearbeitet"
            );
            this.appLoader.close();
            this.router.navigateByUrl("/mapAds/marketing-portal/campaigns");
          }
        })
        .catch((e) => {
          this.snackbarService.show(e.error, "danger");
          this.appLoader.close();
        });
    } else {
      const body = {
        customerId: this.customer ? this.customer.customerId : null,
        socialMediaCampaignTypeId: value.socialMediaCampaignTypeId,
        campaignStart: value.campaignStart,
        campaignEnd: null,
        campaignName: value.campaignName,
        campaignPackageId: null,
        manualBudget: null,
        needsCustomerApproval: false,
        channelId: value.channelId,
        campaignGoalId: 1,
        campaignDisplayText: value.campaignDisplayText,
        campaignOwnLink:
          value.campaignOwnLink != ""
            ? value.protocol + value.campaignOwnLink
            : "",
        campaignImageIds: value.campaignImageIds,
        campaignVideoIds: value.campaignVideoIds,
        campaignGenderId: 1,
        sponsoredFacebookPage: this.showFBBrandedContentSelection
          ? value.sponsoredFacebookPage
          : null,
        campaignFacebookPageId: value.campaignFacebookPageId,
        fromAge: 18,
        toAge: 24,
        campaignTargetIds: null,
        campaignLocations: null,
        timezone: offset,
      };

      await this.crudService
        .createSocialMediaPost(body)
        .toPromise()
        .then((res) => {
          if (res) {
            this.snackbarService.show(
              "Deine Marketing-Kampagne wurde erfolreich erstellt"
            );

            let redirectUrl =
              "/mapAds/marketing-portal/checkout/campaign/" +
              this.campaignChannelId +
              "/" +
              res +
              "?checkoutNeeded=false";
            this.appLoader.close();
            this.router.navigateByUrl(redirectUrl);
          }
        })
        .catch((e) => {
          this.snackbarService.show(e.error, "danger");
          this.appLoader.close();
        });
    }
  }
}
