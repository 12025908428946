import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "marketing-portal-editor-header",
  templateUrl: "./marketing-portal-editor-header.component.html",
  styleUrls: ["./marketing-portal-editor-header.component.scss"],
})
export class MarketingPortalEditorHeaderComponent implements OnInit {
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onBack: EventEmitter<any> = new EventEmitter();
  @Output() toggleMenu: EventEmitter<any> = new EventEmitter();
  @Input() goBack: boolean;
  constructor() {}

  ngOnInit() {}
}
